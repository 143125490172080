import React, { useState, useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { Grid, makeStyles, Switch } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import WarningIcon from '@material-ui/icons/Warning'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useParams } from 'react-router-dom'

import TablePanel from 'components/Blocks/Accordions/TablePanel'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import UpdateFilterValue from 'components/Settings/FilterValues/UpdateFilterValue'
import {
  CurrentUserDocument,
  useOrganizationFilterValuesQuery,
  useOrganizationUpdateFilterValueArchivedMutation,
  SettingsUsersDocument,
  FilterTypeFragment,
  CurrentUserQuery,
  OrganizationFilterValueFragment,
} from 'generated/graphql'
import { filterValuesPage } from 'utils/constants'
import { usePublicConstants } from 'utils/customHooks'

const useStyles = makeStyles(theme => ({
  addButton: {
    marginBottom: theme.spacing(2),
  },
  noDataError: {
    marginTop: 8,
    marginRight: 5,
    color: theme.palette.error.main,
    float: 'right',
  },
  warningIcon: {
    marginTop: 3,
    marginRight: 10,
    color: theme.palette.error.main,
  },
}))

const makeColumns = (filterType: FilterTypeFragment) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
  ]
  if (filterType.isLocation) {
    columns.push({
      Header: 'City',
      accessor: 'city',
    })
    columns.push({
      Header: 'State',
      accessor: 'state',
    })
  }
  columns.push({
    Header: 'Active',
    accessor: 'archived',
  })
  columns.push({
    Header: 'Action',
    accessor: 'edit',
  })
  return columns
}

const FilterValues = () => {
  const classes = useStyles()
  const { uuid } = useParams<{ uuid: string }>()
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser as NonNullable<
    CurrentUserQuery
  >['currentUser']
  const [filterValue, setFilterValue] = useState<null | OrganizationFilterValueFragment>(null)
  const [showFilterUpdate, setShowFilterUpdate] = useState(false)
  const [showArchiveDialog, setShowArchiveDialog] = useState(false)

  const [updateFilterValueArchived] = useOrganizationUpdateFilterValueArchivedMutation()
  const { standardResidentFilterValueChoices } = usePublicConstants()

  const filterType = currentUser.filters.filter(ft => ft.filterTypeUuid === uuid)[0]
  useEffect(() => {
    setShowFilterUpdate(false)
    setFilterValue(null)
  }, [filterType])

  const response = useOrganizationFilterValuesQuery({
    variables: { dtCode: filterType.dtCode },
  })
  const columns = makeColumns(filterType)

  const organization = currentUser.organization
  const standardChoicesWithDTCode = standardResidentFilterValueChoices.find(
    fvc => fvc.dtCode === filterType.dtCode,
  )
  const hasStandardFilterValueChoices = Boolean(standardChoicesWithDTCode)

  const archiveFilterValue = async (filterValueUuid: string) => {
    await updateFilterValueArchived({
      variables: { uuid: filterValueUuid },
      refetchQueries: [{ query: SettingsUsersDocument }, { query: CurrentUserDocument }],
    })
  }

  return (
    <ResponseHandler {...response}>
      {({ filterValues }) => {
        const data = filterValues.map(fv => ({
          name: fv.name,
          city: fv.city,
          state: fv.state,
          archived: (
            <Switch
              checked={!fv.archived}
              onChange={() => {
                setShowFilterUpdate(false)
                if (!fv.archived) {
                  setFilterValue(fv)
                  setShowArchiveDialog(true)
                } else {
                  archiveFilterValue(fv.uuid)
                }
              }}
            />
          ),
          edit: (
            <div>
              <IconButton
                onClick={() => {
                  setShowFilterUpdate(true)
                  setFilterValue(fv)
                }}
                color="secondaryHover"
              >
                <EditIcon />
              </IconButton>
              {fv.hasMissingRequiredValues && (
                <span className={classes.noDataError}>*Missing data</span>
              )}
            </div>
          ),
        }))

        const usedFilterValueNames = filterValues.map(fv => fv.name)
        const unusedStandardFilterValueChoices = standardChoicesWithDTCode
          ? standardChoicesWithDTCode.standardChoices.filter(
              sc => !usedFilterValueNames.includes(sc),
            )
          : []

        const hasMissingData = Boolean(
          filterValues.filter(fv => fv.hasMissingRequiredValues).length,
        )

        return (
          <>
            <BreadcrumbsItem to={uuid ? filterValuesPage(uuid) : '#'}>
              {filterType.name}
            </BreadcrumbsItem>
            {!showFilterUpdate && (
              <>
                <Grid container justify="space-between" alignItems="flex-end">
                  <Grid item>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() => {
                        setShowFilterUpdate(true)
                        setFilterValue(null)
                      }}
                      disabled={
                        hasStandardFilterValueChoices && !unusedStandardFilterValueChoices.length
                      }
                    >
                      + Add {filterType.name}
                    </Button>
                  </Grid>
                  <Grid item>
                    {hasMissingData && (
                      <>
                        <WarningIcon className={classes.warningIcon} />
                        <p className={classes.noDataError}>*Please correct the issues below.</p>
                      </>
                    )}
                  </Grid>
                </Grid>
                <TablePanel
                  title={filterType.namePlural}
                  gutterBottom
                  interactive={false}
                  reactTableProps={{
                    columns,
                    data,
                    defaultPageSize: 50,
                  }}
                />
              </>
            )}
            {showArchiveDialog && filterValue && (
              <ActionDialog
                title={`Archive ${filterValue.name}?`}
                content={`When you deactivate this ${filterValue.name} any users with access to ${filterValue.name} will no longer be able to access it.`}
                submitButtonText="Archive"
                onClose={() => setShowArchiveDialog(false)}
                onSubmit={async () => {
                  await archiveFilterValue(filterValue.uuid)
                  setShowArchiveDialog(false)
                }}
              />
            )}
            {showFilterUpdate && (
              <UpdateFilterValue
                filterType={filterType}
                filterValue={filterValue}
                hasStandardFilterValueChoices={hasStandardFilterValueChoices}
                unusedStandardFilterValueChoices={unusedStandardFilterValueChoices}
                solutionOnlineReviewSites={organization.residentSolution?.onlineReviewSites}
                participatesInUsNews={organization.participatesInUsNews}
                collectCmsAndNcalNumbers={organization.collectCmsAndNcalNumbers}
                isGptwEnabled={organization.isGptwEnabled}
                onClose={() => setShowFilterUpdate(false)}
                setFilterValue={setFilterValue}
              />
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default FilterValues
