import React, { ReactElement } from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { useInsightsResponseRateQuery } from 'generated/graphql'
import { useTimeframeSettings } from 'utils/customHooks'

type ResponseRateProps = {
  survey: InsightsSurvey
  filterValueUuids: string[]
  showReportByClient?: boolean
  children(data: {
    responseRate: { finished: number; total: number }
    isUsingTimeFilters: boolean
  }): ReactElement
}
const ResponseRateContainer: React.FC<ResponseRateProps> = ({
  survey,
  filterValueUuids,
  children,
  showReportByClient = false,
}) => {
  const timeframeSettings = useTimeframeSettings(survey)
  const { timeTrendingEnabled, timeRanges } = useTimeTrendingTimeframeSettings(survey)
  let startDate = timeframeSettings.startDate
  let endDate = timeframeSettings.endDate
  // When using timeframe time-trending, check responses across the full time range.
  if (timeTrendingEnabled) {
    startDate = timeRanges[0].startDate
    endDate = timeRanges[timeRanges.length - 1].endDate
  }

  // We're separating the InsightsSurveyNode query from the SurveyNode query because the former
  // refers to insights related calculations and is cached by query arguments. We don't want to refetch
  // the fields of SurveyNode when query arguments change because that would be inefficient.
  const insightsResponseRate = useInsightsResponseRateQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters: filterValueUuids,
      startDate,
      endDate,
      // Use `byParticipant` since insights results are returned by participant
      // and we use this number to determine <min_showable responses.
      byClient: showReportByClient,
    },
    // Debounce query updates when filters change by adding a debounce key at the root query
    // of insights.
    context: {
      // Requests get debounced together if they share the same debounceKey.
      // Requests without a debounce key are passed to the next link unchanged.
      debounceKey: filterValueUuids.length ? 'insightsResponseRateContainer' : null,
      debounceTimeout: 3000,
    },
  })
  return (
    <ResponseHandler {...insightsResponseRate}>
      {({
        survey: {
          insightsSurvey: {
            responseRate: { finished, total },
          },
        },
      }) => {
        return children({
          responseRate: { finished, total },
          isUsingTimeFilters: Boolean(startDate || endDate),
        })
      }}
    </ResponseHandler>
  )
}

export default ResponseRateContainer
