import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import orderBy from 'lodash/orderBy'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { ReactComponent as ChartUpwardIcon } from 'assets/img/chart-upward.svg'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import EmptyState from 'components/Insights/Blocks/EmptyState'
import RankedStatementsCard from 'components/Insights/Blocks/RankedStatementsCard'
import NpsRecommendCard from 'components/Insights/CustomSurvey/Cards/NpsRecommendCard'
import NpsBreakdownCard from 'components/Insights/Discharge/NpsBreakdownCard'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps } from 'components/Insights/InsightsTypes'
import LonelinessCard from 'components/Insights/ResidentSnapshot/LonelinessCard'
import ResidentResponseRateCard from 'components/Insights/ResidentSnapshot/ResidentResponseRateCard'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import OverallIndexCard from 'components/Insights/Snapshot/OverallIndexCard'
import { InsightsModulesEnum, useInsightsResidentSnapshotOverviewQuery } from 'generated/graphql'
import { RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS, ORDER_TYPES } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    margin: spacing(4),
    width: 300,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  tooltip: {
    padding: spacing(2),
  },
}))

const LifecycleResidentSurveySnapshot: React.FC<InsightsTabProps> = props => {
  // Most browsers have a limit of 6 concurrent connections. In order to provide the optimal user experience
  // we want to quickly load data for the first set of charts so the user has something to look at while
  // we load the other charts. The remaining charts, we load in groups, so that we stay under the concurrency limit.
  const { survey, filters, benchmark, currentUser, solution } = props
  const hasAccessToExtendedSnapshot = solution.insightsSnapshots
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const result = useInsightsResidentSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
      skipNpsScore: !survey.includesNpsQuestion,
    },
  })

  return (
    <>
      <BreadcrumbsItem
        to={getInsightsPage(survey.uuid, InsightsModulesEnum.SNAPSHOTS, survey.productType)}
      >
        Snapshot
      </BreadcrumbsItem>
      <ResponseHandler {...result}>
        {({ insightsStatements, insightsOverallIndex, insightsNpsGroupScores }) => {
          const overallPositive = Math.round(insightsOverallIndex.positive)
          const benchmarkPositive = Math.round(insightsOverallIndex.benchmarkPositive || 0)
          const positiveStatements = orderBy(
            insightsStatements,
            ['positive', 'label'],
            ORDER_TYPES.DESCENDING,
          )
          const negativeStatements = orderBy(
            insightsStatements,
            ['negative', 'label'],
            ORDER_TYPES.DESCENDING,
          )

          const ResponseRateCard = (
            <ResidentResponseRateCard
              survey={survey}
              hasTimeTrending={false}
              filters={filters}
              isDefaultClientBasedResponseRateReport={survey.isDefaultClientBasedResponseRateReport}
              minShowableResults={survey.minShowableResults}
            />
          )

          if (insightsStatements.length === 0) {
            return ResponseRateCard
          }
          return (
            <>
              <div className={classes.halfRow}>
                <OverallIndexCard
                  title="Engagement Score"
                  benchmarkName={benchmark.name}
                  insightsOverallIndex={insightsOverallIndex}
                />
                {ResponseRateCard}
              </div>
              {hasAccessToExtendedSnapshot && (
                <>
                  <DetailedBreakdownCard
                    {...props}
                    hasTimeTrending={false}
                    title="Detailed Breakdown"
                    description="Scores varied across your organization - focus on the teams that most need support to increase your overall score."
                    tooltip="This chart shows customer engagement scores across your organization. The engagement score is the positive score across all core statements and services for the filter that you're looking at."
                    benchmarkPositive={benchmarkPositive}
                    overallPositiveScore={overallPositive}
                  />
                </>
              )}
              {insightsStatements.length === 1 ? (
                <EmptyState
                  title="Oh Snap!"
                  description="You only have 1 rated statement, please view scores in the Statements tab"
                  Icon={ChartUpwardIcon}
                />
              ) : (
                <>
                  <div className={classes.fullRow} id="top-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      scoreKey="positive"
                      benchmarkScoreKey="benchmarkPositive"
                      statements={positiveStatements.slice(
                        0,
                        RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS,
                      )}
                      title="Strengths"
                      description="There is a lot to celebrate! These are the statements with the highest percentage of positive responses. Make sure your team knows these statements are their greatest strengths."
                      tooltipText={
                        <div className={classes.tooltip}>
                          <Typography>Statement Scores</Typography>
                          <Typography color="textSecondary">
                            The statement questions use a 5-point scale: Positive responses (4-5),
                            Inconsistent responses (3), and Negative responses (1-2) This is an
                            aggregate score showing how many respondents answered positively on each
                            statement question.
                          </Typography>
                          <br />
                          <Typography>How is the Statement Score calculated?</Typography>
                          <Typography color="textSecondary">
                            To calculate the statement score we take the number of positive
                            responses, and divide them by the total number of responses.
                          </Typography>
                        </div>
                      }
                      snapId="top-statements-snap"
                    />
                  </div>
                  <div className={classes.fullRow} id="bottom-statements-snap">
                    <RankedStatementsCard
                      {...props}
                      statements={negativeStatements.slice(
                        0,
                        RANKED_STATEMENTS_LIMIT_LIFECYCLE_SURVEYS,
                      )}
                      scoreKey="negative"
                      benchmarkScoreKey="benchmarkNegative"
                      title="Opportunities"
                      description="These are the statements with the highest percentage of negative responses. Improving these statement scores will help to enhance the patient experience overall."
                      tooltipText={
                        <div className={classes.tooltip}>
                          <Typography>Statement Scores</Typography>
                          <Typography color="textSecondary">
                            These are your lowest scoring statements. The statements are ranked in
                            this section by highest negative responses, meaning patients that
                            answered with a negative score of 1 or 2. These statements represent
                            your greatest opportunities for growth and improvement.
                          </Typography>
                          <br />
                          <Typography>How is the Statement Score calculated?</Typography>
                          <Typography color="textSecondary">
                            To calculate the statement score we take the number of positive
                            responses, and divide them by the total number of responses.
                          </Typography>
                        </div>
                      }
                      snapId="bottom-statements-snap"
                    />
                  </div>
                </>
              )}
              {currentUser.canAccessLonelinessResults && survey.includesLonelinessQuestions && (
                <LonelinessCard
                  surveyUuid={survey.uuid}
                  minShowableResults={survey.minShowableResults}
                  filters={filters}
                  hasTimeTrending={false}
                />
              )}
              {insightsNpsGroupScores && (
                <>
                  <NpsRecommendCard
                    surveyUuid={survey.uuid}
                    productType={survey.productType}
                    filters={filters}
                    npsGroupScores={insightsNpsGroupScores}
                    useFormPanel
                    showCommentsLink={hasAccessToExtendedSnapshot}
                  />
                  {hasAccessToExtendedSnapshot && (
                    <NpsBreakdownCard
                      isSnapshotNps
                      survey={survey}
                      filterTypes={props.visibleFilterTypes}
                      filters={filters}
                    />
                  )}
                </>
              )}
            </>
          )
        }}
      </ResponseHandler>
    </>
  )
}

export default LifecycleResidentSurveySnapshot
