import React, { useState } from 'react'

import { Dialog, DialogTitle, Divider, Typography } from '@material-ui/core'

import FilterAccessSettings from 'components/Settings/Team/Users/FilterAccessSettings'
import { CurrentUserQuery, FilterTypePermissionInput, FilterTypeFragment } from 'generated/graphql'

type Props = {
  open: boolean
  onClose(): void
  userIds: string[]
  onSubmit(arg: { uuidList: string[]; filterTypes: FilterTypePermissionInput[] }): Promise<boolean>
  currentUser: CurrentUserQuery['currentUser']
}

const BulkUpdateUserPermissionsDialog: React.FC<Props> = ({
  currentUser,
  open,
  onClose,
  userIds,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filters, setFilters] = useState<{ [uuid: string]: FilterTypeFragment }>({})
  /**
   * React does not rerender if you update state to the "same" value
   * Because state uses shallow comparison, updating the filters object does not trigger a rerender.
   * As a temporary solution, trigger a manual rerender by updating a dummy state variable.
   * In the future, flatten nested objects as props.
   */
  const [didUpdate, setDidUpdate] = useState(true)

  const submit = async () => {
    setIsSubmitting(true)
    const variables = {
      uuidList: userIds,
      filterTypes: Object.values(filters).map(f => ({
        uuid: f.filterTypeUuid,
        accessToAll: Boolean(f.accessToAll),
        values: f.filterValues.map(fv => ({ uuid: fv.uuid })),
      })),
    }
    const success = await onSubmit(variables)
    setIsSubmitting(false)
    if (success) {
      return onClose()
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Data Access
        <br />
        <Typography color="textSecondary">
          Select the data access you want to give to{' '}
          {userIds.length > 0 ? 'these users' : 'this user'}
        </Typography>
      </DialogTitle>
      <Divider />
      <Divider />
      <FilterAccessSettings
        submitLabel="Save"
        selectedFilters={filters}
        currentUser={currentUser}
        updateFilters={(selectedFilters: { [uuid: string]: FilterTypeFragment }) => {
          setFilters(selectedFilters)
          setDidUpdate(!didUpdate)
        }}
        onSubmit={submit}
        onClose={onClose}
        isSubmitting={isSubmitting}
      />
    </Dialog>
  )
}

export default BulkUpdateUserPermissionsDialog
