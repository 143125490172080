import React from 'react'

import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import { useStyles } from 'components/Survey/Wizard/Steps/Notifications/common'
import IntervalNotifications from 'components/Survey/Wizard/Steps/Notifications/IntervalNotifications'
import ScheduledNotifications from 'components/Survey/Wizard/Steps/Notifications/ScheduledNotifications'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import { CurrentUserQuery, SurveysSurveyQuery } from 'generated/graphql'
import { getSolutionFromProductType } from 'utils'
import { SURVEY_TYPES_THAT_CAN_BE_OPEN_ENDED } from 'utils/constants'

interface Props {
  currentUser: CurrentUserQuery['currentUser']
  survey: SurveysSurveyQuery['survey']
  goBack?(): void
  goNext?(uuid?: string): void
}

const Notifications: React.FC<Props> = ({ currentUser, survey, goBack, goNext }) => {
  const classes = useStyles()

  const relevantSolution = getSolutionFromProductType(currentUser.organization, survey.productType)
  // Only custom surveys without an end date can get interval based notifications
  const useInterval =
    relevantSolution.hasIntervalBasedNotifications &&
    SURVEY_TYPES_THAT_CAN_BE_OPEN_ENDED.includes(survey.type) &&
    !survey.endDate

  return (
    <>
      {survey.editable && <SurveyControls survey={survey} />}
      {useInterval ? (
        // We don't need to enforce maxNotifications in Interval Notifications because
        // the user is limited to 5 interval options.
        <IntervalNotifications classes={classes} survey={survey} />
      ) : (
        <ScheduledNotifications
          classes={classes}
          survey={survey}
          maxNotifications={survey.numberOfMaxScheduledNotifications}
        />
      )}
      {/* Components contain the "Back" and "Next" buttons of the wizard, which now only need to be displayed if the survey is editable ( i.e. DRAFT) */}
      {survey.editable && <NavigationButtons goBack={goBack} goNext={goNext} />}
    </>
  )
}

export default Notifications
