import React, { ReactElement, useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import PrintIcon from '@material-ui/icons/Print'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import WarningIcon from '@material-ui/icons/Warning'
import cn from 'classnames'
import { format } from 'date-fns'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import {
  SelectValidator,
  TextValidator,
  ValidatorComponent,
  ValidatorComponentProps,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { NavLink } from 'react-router-dom'

import { ReactComponent as SuccessIcon } from 'assets/img/success-checkmark.svg'
import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import ActionDialog from 'components/Blocks/Dialogs/ActionDialog'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import Page from 'components/Blocks/Layout/Page'
import Danger from 'components/Blocks/Typography/Danger'
import CultureBriefImages, {
  CULTURE_BRIEF_IMAGE_MIN_HEIGHT,
  CULTURE_BRIEF_IMAGE_MIN_WIDTH,
  CULTURE_BRIEF_MAX_IMAGE_SIZE,
  CULTURE_BRIEF_MIN_IMAGES,
} from 'components/CertificationHub/CultureBriefImages'
import {
  CertificationCultureBriefQuery,
  CultureBriefImageType,
  CultureBriefImageTypeEnum,
  CultureBriefType,
  CurrentUserDocument,
  CurrentUserQuery,
  useAddCultureBriefImagesMutation,
  useDeleteCultureBriefImagesMutation,
  useUpdateCultureBriefImagesMutation,
} from 'generated/graphql'
import PrintableComponent from 'HOC/PrintableComponent'
import { URLS, US_STATES } from 'utils/constants'
import { usePublicConstants } from 'utils/customHooks'
import {
  CultureBriefStatusEnum,
  EmployeeBenefitsEnum,
  IndustrySubVerticalEnum,
  OrganizationTypeEnum,
} from 'utils/generatedEnums'
import { verifyHttpHttpsUrl, verifyLength } from 'utils/validations'

const useStyles = makeStyles(({ palette, spacing }) => ({
  printIcon: {
    color: palette.common.navy65,
  },
  submitMessageRoot: {
    marginLeft: '25%',
    width: '50%',
  },
  submitMessage: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    '& >div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: spacing(2),
      width: '80%',
      '& >svg': {
        marginTop: spacing(),
        marginBottom: spacing(),
      },
      '& >button': {
        marginTop: spacing(3),
        marginBottom: spacing(3),
      },
    },
  },
  section: {
    marginBottom: spacing(4),
  },
  liveProfileSection: {
    display: 'flex',
    backgroundColor: palette.common.white,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(3),
    paddingRight: spacing(8),
    '& >svg': {
      color: palette.common.warning,
      marginRight: spacing(3),
    },
  },
  header: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    '& >div': {
      display: 'flex',
    },
    '& >div:first-child': {
      cursor: 'pointer',
    },
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  headerRight: {
    '& >div:first-child': {
      marginRight: spacing(5),
    },
  },
  content: {
    padding: spacing(3),
  },
  textAndIcon: {
    display: 'flex',
    alignItems: 'center',
    '& >p:first-child': {
      marginRight: spacing(),
    },
    '& >svg': {
      marginRight: spacing(),
      color: palette.common.navy65,
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    '& >p:first-child': {
      marginRight: spacing(),
    },
    '& >svg': {
      marginRight: spacing(),
    },
  },
  fieldLabel: {
    marginBottom: spacing(),
  },
  formField: {
    marginTop: spacing(4),
    '& >p:first-child': {
      marginBottom: 10,
    },
    '@media print': {
      breakInside: 'avoid',
    },
  },
  formFieldNoPadding: {
    marginTop: 0,
  },
  labelWithPrivateIcon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  privateIcon: {
    display: 'flex',
    '& >svg': {
      color: palette.common.navy65,
      marginLeft: spacing(),
      '&:hover': {
        color: palette.common.navy,
      },
    },
  },
  largeInput: {
    width: '70%',
  },
  smallInput: {
    width: '20%',
  },
  headquarters: {
    '& >div': {
      '& div': {
        width: '100%',
      },
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  checked: {
    color: palette.common.secondary,
  },
  unchecked: {
    color: palette.common.navy65,
  },
  mainTypeOfCare: {
    width: '25%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(),
  },
}))

type FormFieldLabelProps = {
  index?: number
  label: string
  required?: boolean
  includePrivateTooltip?: boolean
  infoTooltip?: string | ReactElement
  isCompletedCultureBrief: boolean
}
const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  index,
  includePrivateTooltip = false,
  label,
  required,
  infoTooltip,
  isCompletedCultureBrief,
}) => {
  const classes = useStyles()

  const showSecondaryLabel = !index
  const labelComponent = (
    <div className={cn(classes.textAndIcon, classes.fieldLabel)}>
      <Typography color={showSecondaryLabel ? 'textSecondary' : 'textPrimary'}>
        {index && !isCompletedCultureBrief ? `${index}. ${label} ` : `${label} `}
        {required && (
          <Typography component="span" color={index ? 'secondary' : 'textSecondary'}>
            *
          </Typography>
        )}
      </Typography>
      {infoTooltip && (
        <Tooltip title={infoTooltip}>
          <InfoIcon />
        </Tooltip>
      )}
    </div>
  )
  return includePrivateTooltip ? (
    <div className={classes.labelWithPrivateIcon}>
      {labelComponent}
      <Tooltip title="This question answer will remain confidential, and will not be displayed on your public profile.">
        <div className={classes.privateIcon}>
          <Typography color="textSecondary">Private</Typography>
          <VisibilityOffIcon />
        </div>
      </Tooltip>
    </div>
  ) : (
    <>{labelComponent}</>
  )
}

type TextFieldProps = {
  id?: string
  onUpdate(arg: string): void
  value: string | number
  type?: string
  largeInput?: boolean
  rows?: number
  maxCharacters?: number
  digitsOnly?: boolean
  multiline?: boolean
  helperTexts?: string[]
  validators?: string[]
  errorMessages?: string[]
  noPadding?: boolean
} & FormFieldLabelProps
const TextField: React.FC<TextFieldProps> = React.memo(
  ({
    index,
    label,
    type = 'string',
    required = false,
    largeInput = false,
    noPadding = false,
    helperTexts,
    errorMessages,
    validators,
    maxCharacters,
    digitsOnly,
    includePrivateTooltip,
    infoTooltip,
    isCompletedCultureBrief,
    ...restProps
  }) => {
    const classes = useStyles()

    return (
      <div className={cn(classes.formField, { [classes.formFieldNoPadding]: noPadding })}>
        <FormFieldLabel
          index={index}
          required={required}
          label={label}
          includePrivateTooltip={includePrivateTooltip}
          infoTooltip={infoTooltip}
          isCompletedCultureBrief={isCompletedCultureBrief}
        />
        <DebouncedTextInput
          validateChange={(text: string) => {
            const reOnlyDigits = /^[0-9]*$/
            return (
              (!maxCharacters || text.length < maxCharacters) &&
              (!digitsOnly || (digitsOnly && reOnlyDigits.test(text)))
            )
          }}
          InputComponent={TextValidator}
          size="small"
          variant="outlined"
          color="secondary"
          validators={validators}
          errorMessages={errorMessages}
          type={type}
          name={label}
          className={largeInput ? classes.largeInput : classes.smallInput}
          {...restProps}
        />
        {helperTexts?.map(text => (
          <Typography key={text} color="textSecondary" variant="subtitle2">
            {text}
          </Typography>
        ))}
      </div>
    )
  },
  // As a performance improvement, we're memoizing this component and always returning true here because
  // we don't want to re-render the component based on the received props. The component will only re-render based on its internal state
  () => true,
)

// Custom Validator Component for Radio Buttons
class RadioButtonsValidator extends ValidatorComponent {
  state = { isValid: true }

  renderValidatorComponent() {
    const choices = this.props.choices as { label: string; value: string | boolean }[]
    const onChange = this.props.onChange as (value: string | boolean) => void

    return (
      <>
        <RadioGroup>
          {choices.map((c, index) => (
            <FormControlLabel
              key={c.label}
              control={
                <Radio
                  id={`${this.props.id}${index}`}
                  checked={this.props.selectedValue === c.value}
                  onChange={() => onChange(c.value)}
                  name={c.label}
                />
              }
              label={c.label}
            />
          ))}
        </RadioGroup>
        {!this.state.isValid && <Danger>{this.getErrorMessage()}</Danger>}
      </>
    )
  }
}

type RadioButtonsFieldProps = {
  id?: string
  choices: { label: string; value: string | boolean }[]
  selectedValue?: boolean | string | null
  onChange(value: undefined | null | string | boolean): void
} & FormFieldLabelProps &
  ValidatorComponentProps
const RadioButtonsField: React.FC<RadioButtonsFieldProps> = ({
  index,
  label,
  required,
  includePrivateTooltip,
  isCompletedCultureBrief,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <div className={classes.formField}>
      <FormFieldLabel
        index={index}
        label={label}
        required={required}
        includePrivateTooltip={includePrivateTooltip}
        isCompletedCultureBrief={isCompletedCultureBrief}
      />
      <RadioButtonsValidator {...restProps} />
    </div>
  )
}

// Custom Validator Component for Checkboxes
class CheckboxesValidator extends ValidatorComponent {
  state = { isValid: true }

  renderValidatorComponent() {
    /* eslint-disable no-unused-vars */
    const choices = this.props.choices as string[]
    const selectedValues = this.props.selectedValues as string
    const onChange = this.props.onChange as (value: string) => void
    const classes = this.props.classes

    return (
      <>
        <Grid container spacing={1}>
          {choices.map((choice, index) => {
            const selectedValuesArray = selectedValues ? selectedValues.split(',') : []
            const checked = selectedValuesArray.includes(choice)
            return (
              <Grid item sm={6} key={choice} className={classes.checkbox}>
                <Checkbox
                  id={`${this.props.id}${index}`}
                  className={checked ? classes.checked : classes.unchecked}
                  checked={checked}
                  onClick={() => {
                    const newSelectedValuesArray = checked
                      ? selectedValuesArray.filter(selectedValue => choice !== selectedValue)
                      : [...selectedValuesArray, choice]
                    onChange(newSelectedValuesArray.join(','))
                  }}
                />
                <Typography color="textSecondary">{choice}</Typography>
              </Grid>
            )
          })}
        </Grid>
        {!this.state.isValid && <Danger>{this.getErrorMessage()}</Danger>}
      </>
    )
  }
}

type CheckboxesFieldProps = {
  id?: string
  choices: string[]
  selectedValues?: null | string
  onChange(value: undefined | null | string): void
} & FormFieldLabelProps &
  ValidatorComponentProps
const CheckboxesField: React.FC<CheckboxesFieldProps> = ({
  index,
  label,
  required,
  includePrivateTooltip,
  isCompletedCultureBrief,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <div className={classes.formField}>
      <FormFieldLabel
        index={index}
        label={label}
        required={required}
        includePrivateTooltip={includePrivateTooltip}
        isCompletedCultureBrief={isCompletedCultureBrief}
      />
      <CheckboxesValidator classes={classes} {...restProps} />
    </div>
  )
}

class ImagesValidator extends ValidatorComponent {
  state = { isValid: true }

  renderValidatorComponent() {
    return (
      <>
        <CultureBriefImages
          cultureBriefUuid={this.props.cultureBriefUuid ?? ''}
          isCompletedCultureBrief={this.props.isCompletedCultureBrief}
          imageType={this.props.imageType}
          images={this.props.images ?? []}
          setImagesToAdd={this.props.setImagesToAdd}
          setImagesToUpdate={this.props.setImagesToUpdate}
          setImagesToRemove={this.props.setImagesToRemove}
          setError={this.props.setError}
        />
        {!this.state.isValid && <Danger>{this.getErrorMessage()}</Danger>}
      </>
    )
  }
}

type Props = {
  cultureBrief: CertificationCultureBriefQuery['certification']['cultureBrief']
  onUpdateCultureBrief: (
    update: Partial<CultureBriefType>,
    refetchCertification?: boolean,
  ) => Promise<boolean>
}
const CultureBrief: React.FC<Props> = ({
  cultureBrief: initialCultureBrief,
  onUpdateCultureBrief,
}) => {
  const classes = useStyles()
  const client = useApolloClient()
  const {
    cultureBriefDisabledFieldsWhenCompleted,
    cultureBriefRequiredFields,
    cultureBriefMaxImages,
  } = usePublicConstants()
  const [cultureBrief, setCultureBrief] = useState<Partial<CultureBriefType>>(initialCultureBrief)
  const percentageError = 'Percentage must be between 0 and 100'
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [messageToShow, setMessageToShow] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  // 1. We need to maintain a state for printing because a different set of fields is displayed in the print dialog than may be displayed on the page.
  // 2. We need to manipulate isPrinting in state in order to call window.print() after state has been updated, because calling window.print() blocks the current execution thread.
  const [isPrinting, setIsPrinting] = useState(false)

  useEffect(() => {
    if (isPrinting) {
      window.print()
    }
  }, [isPrinting])

  const [failsValidation, setFailsValidation] = useState(false)

  const { currentUser } = client.readQuery({ query: CurrentUserDocument }) as NonNullable<
    CurrentUserQuery
  >
  const isCompletedCultureBrief = cultureBrief.status === CultureBriefStatusEnum.COMPLETED

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidUrl', value => !value || verifyHttpHttpsUrl(value))
    ValidatorForm.addValidationRule('isValidYear', value => verifyLength(value, 4, 4))
  })

  const updateCultureBrief = (
    update: Partial<CultureBriefType>,
    args?: {
      refetchCertification?: boolean
      isSubmit: boolean
    },
  ) => {
    setCultureBrief(prevState => ({ ...prevState, ...update }))
    if (!isCompletedCultureBrief || args?.isSubmit) {
      return onUpdateCultureBrief(update, args?.refetchCertification)
    }

    return false
  }

  // Maintain images state separately so that "auto-saves" can be reflected in the UI
  const [images, setImages] = useState(initialCultureBrief.images)
  const [imagesToAdd, setImagesToAdd] = useState<Array<CultureBriefImageType>>([])
  const [imagesToUpdate, setImagesToUpdate] = useState<Array<CultureBriefImageType>>([])
  const [imagesToRemove, setImagesToRemove] = useState<Array<string>>([])
  useEffect(() => {
    setImages(initialCultureBrief.images)
    // Clean up todos on root images update.
    setImagesToAdd([])
    setImagesToUpdate([])
    setImagesToRemove([])
  }, [initialCultureBrief.images])

  const filterImages = (imageTypeEnum: CultureBriefImageTypeEnum) => {
    const addedAndRemoved = [
      // Filter to images that match the enum and that aren't set for removal.
      ...images.filter(
        image => image.imageType === imageTypeEnum && !imagesToRemove.includes(image.uuid),
      ),
      // Include images to add, by enum.
      ...imagesToAdd.filter(imageToAdd => imageToAdd.imageType === imageTypeEnum),
    ]

    // If they aren't company (profile) images then we don't need to worry about captions.
    return imageTypeEnum === CultureBriefImageTypeEnum.COMPANY
      ? addedAndRemoved.map(image => imagesToUpdate.find(img => img.uuid === image.uuid) ?? image)
      : addedAndRemoved
  }
  const logoImages = filterImages(CultureBriefImageTypeEnum.LOGO)
  const companyImages = filterImages(CultureBriefImageTypeEnum.COMPANY)
  const ceoImages = filterImages(CultureBriefImageTypeEnum.CEO)

  const [saveImages] = useAddCultureBriefImagesMutation()
  const [updateImages] = useUpdateCultureBriefImagesMutation()
  const [deleteImages] = useDeleteCultureBriefImagesMutation()

  const onSubmit = async () => {
    if (cultureBrief.uuid === undefined) {
      throw new Error(`Culture Brief should have UUID. ID: ${cultureBrief.id}`)
    }

    setIsSubmitting(true)
    // "Auto-saving" disabled for completed culture briefs
    if (isCompletedCultureBrief) {
      const cultureBriefUuid = cultureBrief.uuid
      // Save caption updates first, so if any were deleted, they won't get thrown off.
      if (imagesToUpdate.length > 0) {
        await updateImages({
          variables: {
            cultureBriefUuid,
            imagesData: imagesToUpdate,
          },
        })
      }
      // Delete pending second, to not throw-off backend validation for adding images (existing image quantities).
      if (imagesToRemove.length > 0) {
        await deleteImages({
          variables: {
            cultureBriefUuid,
            uuids: imagesToRemove,
          },
          update(cache) {
            imagesToRemove.forEach(uuid => {
              cache.evict({ id: cache.identify({ uuid, __typename: 'CultureBriefImageType' }) })
            })
            cache.gc()
          },
        })
      }
      // Save new images
      if (imagesToAdd.length > 0) {
        await saveImages({
          variables: {
            cultureBriefUuid,
            imagesData: imagesToAdd,
          },
        })
      }

      const cultureBriefToSave = cloneDeep(cultureBrief)
      delete cultureBriefToSave.uuid
      delete cultureBriefToSave.images
      delete cultureBriefToSave.updated
      delete cultureBriefToSave.__typename
      await updateCultureBrief(cultureBriefToSave, {
        refetchCertification: true,
        isSubmit: true,
      })
      setMessageToShow('updates')
    } else {
      const updateHasErrors = await updateCultureBrief(
        { status: CultureBriefStatusEnum.COMPLETED },
        { refetchCertification: true, isSubmit: true },
      )
      if (!updateHasErrors) {
        setMessageToShow('completed')
      }
    }
    setIsSubmitting(false)
  }

  const isRequiredField = (fieldName: string) => {
    return cultureBriefRequiredFields.map(field => camelCase(field)).includes(fieldName)
  }
  const getValidators = (fieldName: string, additionalValidators: string[] = []) => {
    return (isRequiredField(fieldName) ? ['required'] : []).concat(additionalValidators)
  }
  const getValidatorsMessages = (fieldName: string, additionalMessages: string[] = []) => {
    return (isRequiredField(fieldName) ? ['Required'] : []).concat(additionalMessages)
  }

  const shouldShowField = (fieldName: string) => {
    if (isPrinting) {
      return true
    }
    return !(
      // In sync with the backend, search for "CultureBriefStatusEnum.COMPLETED.value"
      (
        isCompletedCultureBrief &&
        cultureBriefDisabledFieldsWhenCompleted.map(field => camelCase(field)).includes(fieldName)
      )
    )
  }

  const commonImageValidatorProps = {
    cultureBriefUuid: cultureBrief.uuid,
    isCompletedCultureBrief,
    setImagesToAdd,
    setImagesToUpdate,
    setImagesToRemove,
    setError,
  }

  if (!failsValidation && !isPrinting && messageToShow !== '') {
    return (
      <div className={classes.submitMessageRoot}>
        <Paper className={classes.submitMessage}>
          <div>
            <Typography variant="h4">
              {messageToShow === 'updates' ? 'Updates Saved' : 'Success!'}
            </Typography>
            <SuccessIcon />
            <Typography color="textSecondary">
              {messageToShow === 'updates'
                ? 'Your Culture Brief has been updated. Your online profile has been updated and your changes have been published.'
                : 'Your Culture Brief has been completed. You’re now one step closer to becoming Great Place to Work certified.'}
            </Typography>
            {messageToShow === 'completed' && (
              <Button onClick={() => setIsPrinting(true)}>Download Completed Documents</Button>
            )}
            <NavLink to={URLS.CERTIFICATION_HUB.HUB}>Back to Certification Hub</NavLink>
          </div>
        </Paper>
      </div>
    )
  }

  return (
    <>
      <BreadcrumbsItem to={URLS.CERTIFICATION_HUB.CULTURE_BRIEF}>
        {currentUser.organization.name}: Culture Brief
      </BreadcrumbsItem>
      <Page>
        {isCompletedCultureBrief && (
          <Paper elevation={0} className={cn(classes.section, classes.liveProfileSection)}>
            <WarningIcon />
            <Typography color="textSecondary">
              We are only displaying the questions you may edit after your profile is live. The
              hidden questions can no longer be edited. Please select the &quot;Save & Publish&quot;
              button for your changes to be saved to your public profile.
            </Typography>
          </Paper>
        )}
        <ValidatorForm
          instantValidate={false}
          onSubmit={() => setOpenConfirmationDialog(true)}
          onError={() => setFailsValidation(true)}
        >
          <PrintableComponent
            beforePrint={() => {
              setIsPrinting(true)
            }}
            afterPrint={() => setIsPrinting(false)}
          >
            <Paper elevation={0} className={classes.section}>
              <div className={classes.header}>
                <NavLink className={classes.backButton} to={URLS.CERTIFICATION_HUB.HUB}>
                  <BackIcon fontSize="small" />
                  <Typography>Back to Certification Hub</Typography>
                </NavLink>
                <div className={classes.headerRight}>
                  {!isCompletedCultureBrief && (
                    <div className={classes.textAndIcon}>
                      <Typography color="textSecondary">
                        All answers are auto-saved.{' '}
                        <Typography component="span" color="textPrimary">
                          Last saved:{' '}
                          {format(
                            new Date(cultureBrief.updated as string),
                            'MMM d, yyyy hh:mm bbb',
                          )}
                        </Typography>
                      </Typography>
                      <Tooltip
                        title={
                          <div>
                            <Typography color="textSecondary">
                              When you fill out your Culture Brief, your progress is automatically
                              saved for one year. You may pause at any time during this time period,
                              return later, and we’ll save your progress. You must select ‘Submit’
                              at the bottom of the form once you’ve completed all the questions.
                              Selecting ‘Submit’ updates your status from ‘In Progress’ to
                              ‘Complete’ for this certification requirement.
                            </Typography>
                            <br />
                            <Typography color="textSecondary">
                              Note: If you are offline autosave will not work.
                            </Typography>
                          </div>
                        }
                      >
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </div>
                  )}
                  <IconButton
                    className={classes.printIcon}
                    id="print-button"
                    onClick={() => setIsPrinting(true)}
                  >
                    <PrintIcon />
                  </IconButton>
                </div>
              </div>
              <div className={classes.content}>
                <Typography variant="h4">
                  {isCompletedCultureBrief && 'Section 1: '}About
                </Typography>
                <Typography variant="h6">Tell us about your organization:</Typography>
                {shouldShowField('organizationName') && (
                  <TextField
                    id="organizationName"
                    required={isRequiredField('organizationName')}
                    validators={getValidators('organizationName')}
                    errorMessages={getValidatorsMessages('organizationName')}
                    index={1}
                    label="Organization Name:"
                    largeInput
                    value={cultureBrief.organizationName || ''}
                    onUpdate={organizationName => updateCultureBrief({ organizationName })}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('yearFounded') && (
                  <TextField
                    id="yearFounded"
                    required={isRequiredField('yearFounded')}
                    validators={getValidators('yearFounded', ['isValidYear'])}
                    errorMessages={getValidatorsMessages('yearFounded', ['Invalid year'])}
                    index={2}
                    digitsOnly
                    label="Year Founded:"
                    value={
                      cultureBrief.yearFounded
                        ? new Date(cultureBrief.yearFounded).getFullYear().toString()
                        : ''
                    }
                    onUpdate={year => {
                      const yearFounded =
                        year.length === 4
                          ? new Date(parseInt(year), 1, 0, 0, 0, 0, 0).toISOString()
                          : null
                      updateCultureBrief({ yearFounded })
                    }}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('numberOfLocations') && (
                  <TextField
                    id="numberOfLocations"
                    required={isRequiredField('numberOfLocations')}
                    validators={getValidators('numberOfLocations')}
                    errorMessages={getValidatorsMessages('numberOfLocations')}
                    index={3}
                    label="Number of Locations:"
                    digitsOnly
                    value={
                      isNil(cultureBrief.numberOfLocations) ? '' : cultureBrief.numberOfLocations
                    }
                    onUpdate={value =>
                      updateCultureBrief({
                        numberOfLocations: value === '' ? null : Number(value),
                      })
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('isSubsidiary') && (
                  <RadioButtonsField
                    id="isSubsidiary"
                    required={isRequiredField('isSubsidiary')}
                    validators={getValidators('isSubsidiary')}
                    errorMessages={getValidatorsMessages('isSubsidiary')}
                    index={4}
                    infoTooltip="If your company is part of a larger organization, please mark yes."
                    selectedValue={cultureBrief.isSubsidiary}
                    label="Are you a subsidiary?"
                    name="Subsidiary?"
                    includePrivateTooltip
                    choices={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    onChange={value =>
                      updateCultureBrief({
                        isSubsidiary: value as boolean,
                      })
                    }
                    value={cultureBrief.isSubsidiary}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('organizationType') && (
                  <RadioButtonsField
                    id="organizationType"
                    required={isRequiredField('organizationType')}
                    validators={getValidators('organizationType')}
                    errorMessages={getValidatorsMessages('organizationType')}
                    index={5}
                    selectedValue={cultureBrief.organizationType}
                    label="What type of organization are you?"
                    name="OrganizationType"
                    includePrivateTooltip
                    choices={[
                      {
                        label: OrganizationTypeEnum.FOR_PROFIT,
                        value: OrganizationTypeEnum.FOR_PROFIT,
                      },
                      {
                        label: OrganizationTypeEnum.NOT_FOR_PROFIT,
                        value: OrganizationTypeEnum.NOT_FOR_PROFIT,
                      },
                    ]}
                    onChange={value => {
                      updateCultureBrief({
                        organizationType: value as string,
                      })
                    }}
                    value={cultureBrief.organizationType}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('industrySubVertical') && (
                  <RadioButtonsField
                    id="industrySubVertical"
                    required={isRequiredField('industrySubVertical')}
                    validators={getValidators('industrySubVertical')}
                    errorMessages={getValidatorsMessages('industrySubVertical')}
                    name="industrySubVertical"
                    infoTooltip="Choose which category best applies to your organization. This will be used to determine which category you’re in for Fortune’s Aging Services list."
                    index={6}
                    selectedValue={cultureBrief.industrySubVertical}
                    label="Industry Sub Vertical"
                    choices={[
                      {
                        label: IndustrySubVerticalEnum.AT_HOME_CARE,
                        value: IndustrySubVerticalEnum.AT_HOME_CARE,
                      },
                      {
                        label: IndustrySubVerticalEnum.SENIOR_HOUSING,
                        value: IndustrySubVerticalEnum.SENIOR_HOUSING,
                      },
                    ]}
                    onChange={value =>
                      updateCultureBrief({
                        industrySubVertical: value as string,
                      })
                    }
                    value={cultureBrief.industrySubVertical}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('mainTypeOfCare') && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      index={7}
                      required={isRequiredField('mainTypeOfCare')}
                      label="Which type of senior housing or care brings the most revenue to your organization?"
                      infoTooltip="If a service is missing from this list, update the levels of care selected under Org Settings."
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <SelectValidator
                      id="mainTypeOfCare"
                      validators={getValidators('mainTypeOfCare')}
                      errorMessages={getValidatorsMessages('mainTypeOfCare')}
                      value={cultureBrief.mainTypeOfCare || ''}
                      className={classes.mainTypeOfCare}
                      onChange={e =>
                        updateCultureBrief(
                          { mainTypeOfCare: (e.target as HTMLInputElement).value } || '',
                        )
                      }
                      variant="filled"
                      name="mainTypeOfCare"
                    >
                      {currentUser.organization.benchmarkLevelsOfCare
                        .filter(loc => loc.checked)
                        .map(loc => (
                          <MenuItem key={loc.name} value={loc.name}>
                            {loc.name}
                          </MenuItem>
                        ))}
                    </SelectValidator>
                  </div>
                )}
                {[shouldShowField('city'), shouldShowField('state')].every(Boolean) && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      index={8}
                      required={isRequiredField('city') && isRequiredField('state')}
                      label="Headquarters:"
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <Grid
                      container
                      spacing={1}
                      className={cn(classes.headquarters, classes.largeInput)}
                    >
                      {shouldShowField('city') && (
                        <Grid item sm={8}>
                          <TextField
                            id="city"
                            noPadding
                            validators={getValidators('city')}
                            errorMessages={getValidatorsMessages('city')}
                            label="City"
                            value={cultureBrief.city || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                city: value || '',
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('state') && (
                        <Grid item sm={4}>
                          <FormFieldLabel
                            label="State:"
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                          <SelectValidator
                            id="state"
                            value={cultureBrief.state || ''}
                            validators={getValidators('state')}
                            errorMessages={getValidatorsMessages('state')}
                            onChange={e =>
                              updateCultureBrief(
                                { state: (e.target as HTMLInputElement).value } || '',
                              )
                            }
                            variant="filled"
                            name="state"
                          >
                            {US_STATES.map(({ name }) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </SelectValidator>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
                {shouldShowField('website') && (
                  <TextField
                    id="website"
                    required={isRequiredField('website')}
                    validators={getValidators('website', ['isValidUrl'])}
                    errorMessages={getValidatorsMessages('website', [
                      'Please enter a valid URL, beginning with http:// or https://',
                    ])}
                    index={9}
                    onUpdate={url => updateCultureBrief({ website: url })}
                    value={cultureBrief.website ?? ''}
                    label="Website:"
                    largeInput
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                <div className={classes.formField}>
                  <FormFieldLabel
                    index={10}
                    label="Social Media"
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                </div>
                {([
                  'facebookUrl',
                  'twitterUrl',
                  'instagramUrl',
                  'linkedinUrl',
                  'indeedUrl',
                ] as (keyof Pick<
                  CultureBriefType,
                  'facebookUrl' | 'twitterUrl' | 'instagramUrl' | 'linkedinUrl' | 'indeedUrl'
                >)[]).map(fieldName => (
                  <div className={classes.formField} key={fieldName}>
                    {shouldShowField(fieldName) && (
                      <TextField
                        onUpdate={url => updateCultureBrief({ [fieldName]: url })}
                        value={cultureBrief[fieldName] || ''}
                        label={`${capitalize(fieldName).slice(0, -3)}:`}
                        largeInput
                        validators={['isValidUrl']}
                        errorMessages={[
                          'Please enter a valid URL, beginning with http:// or https://',
                        ]}
                        isCompletedCultureBrief={isCompletedCultureBrief}
                      />
                    )}
                  </div>
                ))}
                {shouldShowField('description') && (
                  <TextField
                    id="description"
                    required={isRequiredField('description')}
                    validators={getValidators('description')}
                    errorMessages={getValidatorsMessages('description')}
                    index={11}
                    rows={isPrinting ? 10 : 5}
                    maxCharacters={600}
                    multiline
                    largeInput
                    onUpdate={text => updateCultureBrief({ description: text })}
                    value={cultureBrief.description || ''}
                    label="Describe your company for media or your Certification Profile:"
                    helperTexts={['*A maximum of 600 characters allowed']}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
              </div>
            </Paper>
            {[
              'totalNumberOfEmployees',
              'pastTotalNumberOfEmployees',
              'totalApplicants',
              'totalOpenRoles',
              'recommendationsPercentage',
              'averageNumberOfEmployees',
              'numberOfEmployeesLeft',
            ]
              .map(field => shouldShowField(field))
              .some(Boolean) && (
              <Paper elevation={0} className={classes.section}>
                <div className={classes.content}>
                  <Typography variant="h4">
                    {isCompletedCultureBrief && 'Section 2: '}Demographics
                  </Typography>
                  <div className={classes.textAndIcon}>
                    <Typography variant="h6">
                      Tell us about the demographic breakdown of your employees:&nbsp;&nbsp;
                    </Typography>

                    <Tooltip title="Please use the criteria we’ve outlined to determine your employee counts. This ensures that we can compare organizations fairly, using the same criteria.">
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <Typography color="textSecondary">
                    For employee counts in this section please include part time servers, but do not
                    include per diems. If you cannot find the exact data, do your best to
                    approximate.
                  </Typography>
                  {shouldShowField('totalNumberOfEmployees') && (
                    <TextField
                      id="totalNumberOfEmployees"
                      required={isRequiredField('totalNumberOfEmployees')}
                      validators={getValidators('totalNumberOfEmployees')}
                      errorMessages={getValidatorsMessages('totalNumberOfEmployees')}
                      index={1}
                      label="Total number of employees:"
                      digitsOnly
                      value={
                        isNil(cultureBrief.totalNumberOfEmployees)
                          ? ''
                          : cultureBrief.totalNumberOfEmployees
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          totalNumberOfEmployees: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('pastTotalNumberOfEmployees') && (
                    <TextField
                      id="pastTotalNumberOfEmployees"
                      required={isRequiredField('pastTotalNumberOfEmployees')}
                      validators={getValidators('pastTotalNumberOfEmployees')}
                      errorMessages={getValidatorsMessages('pastTotalNumberOfEmployees')}
                      index={2}
                      label="Total number of employees 12 months ago:"
                      digitsOnly
                      value={
                        isNil(cultureBrief.pastTotalNumberOfEmployees)
                          ? ''
                          : cultureBrief.pastTotalNumberOfEmployees
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          pastTotalNumberOfEmployees: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('totalApplicants') && (
                    <TextField
                      index={3}
                      required={isRequiredField('totalApplicants')}
                      label="How many applicants have you received in the last 12 months?"
                      digitsOnly
                      includePrivateTooltip
                      value={
                        isNil(cultureBrief.totalApplicants) ? '' : cultureBrief.totalApplicants
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          totalApplicants: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('totalOpenRoles') && (
                    <TextField
                      index={4}
                      required={isRequiredField('totalOpenRoles')}
                      label="How many open roles did you fill with internal candidates in the last 12 months?"
                      digitsOnly
                      includePrivateTooltip
                      value={isNil(cultureBrief.totalOpenRoles) ? '' : cultureBrief.totalOpenRoles}
                      onUpdate={value =>
                        updateCultureBrief({
                          totalOpenRoles: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('recommendationsPercentage') && (
                    <TextField
                      id="recommendationsPercentage"
                      required={isRequiredField('recommendationsPercentage')}
                      validators={getValidators('recommendationsPercentage', [
                        'minNumber:0',
                        'maxNumber:100',
                      ])}
                      errorMessages={getValidatorsMessages('recommendationsPercentage', [
                        percentageError,
                        percentageError,
                      ])}
                      index={5}
                      includePrivateTooltip
                      label="What percentage of job candidates were referred by a current employee?"
                      digitsOnly
                      value={
                        isNil(cultureBrief.recommendationsPercentage)
                          ? ''
                          : cultureBrief.recommendationsPercentage
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          recommendationsPercentage: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('averageNumberOfEmployees') && (
                    <TextField
                      id="averageNumberOfEmployees"
                      required={isRequiredField('averageNumberOfEmployees')}
                      validators={getValidators('averageNumberOfEmployees')}
                      errorMessages={getValidatorsMessages('averageNumberOfEmployees')}
                      index={6}
                      includePrivateTooltip
                      label="How many employees did you have on average in the last 12 months?"
                      digitsOnly
                      value={
                        isNil(cultureBrief.averageNumberOfEmployees)
                          ? ''
                          : cultureBrief.averageNumberOfEmployees
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          averageNumberOfEmployees: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                  {shouldShowField('numberOfEmployeesLeft') && (
                    <TextField
                      id="numberOfEmployeesLeft"
                      required={isRequiredField('numberOfEmployeesLeft')}
                      validators={getValidators('numberOfEmployeesLeft')}
                      errorMessages={getValidatorsMessages('numberOfEmployeesLeft')}
                      index={7}
                      includePrivateTooltip
                      label="How many employees left in the last 12 months voluntarily?"
                      digitsOnly
                      value={
                        isNil(cultureBrief.numberOfEmployeesLeft)
                          ? ''
                          : cultureBrief.numberOfEmployeesLeft
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          numberOfEmployeesLeft: value === '' ? null : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                  )}
                </div>
              </Paper>
            )}
            <Paper elevation={0} className={classes.section}>
              <div className={classes.content}>
                <Typography variant="h4">
                  {isCompletedCultureBrief && 'Section 3: '}Environmental, Social, and Corporate
                  Governance
                </Typography>
                <Typography color="textSecondary">
                  Many countries around the world, including the U.S., either have or are
                  considering adopting Environment, Social, and Corporate Governance (ESG) reporting
                  by companies. Please tell us about your programs. It is all right for no programs
                  to exist yet - collecting this data helps us build for the future.
                </Typography>
                <br />
                <br />
                {shouldShowField('carbonEmissionsReductionPercentage') && (
                  <>
                    <Typography variant="h6">Environmental:</Typography>
                    <TextField
                      validators={['minNumber:0', 'maxNumber:100']}
                      errorMessages={[percentageError, percentageError]}
                      index={1}
                      infoTooltip="Enter zero if you do not know or this isn’t something your organization tracks yet."
                      includePrivateTooltip
                      label="% carbon emissions reduced in the last 12 months:"
                      digitsOnly
                      value={
                        isNil(cultureBrief.carbonEmissionsReductionPercentage)
                          ? ''
                          : cultureBrief.carbonEmissionsReductionPercentage
                      }
                      onUpdate={value =>
                        updateCultureBrief({
                          carbonEmissionsReductionPercentage:
                            value === '' ? undefined : Number(value),
                        })
                      }
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <br />
                    <br />
                  </>
                )}
                <Typography variant="h6">Social:</Typography>
                {shouldShowField('numberOfEmployeesWithHealthInsurance') && (
                  <TextField
                    index={2}
                    includePrivateTooltip
                    label="How many employees are eligible for health insurance benefits?"
                    digitsOnly
                    value={
                      isNil(cultureBrief.numberOfEmployeesWithHealthInsurance)
                        ? ''
                        : cultureBrief.numberOfEmployeesWithHealthInsurance
                    }
                    onUpdate={value =>
                      updateCultureBrief({
                        numberOfEmployeesWithHealthInsurance: value === '' ? null : Number(value),
                      })
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('numberOfEmployeesEligibleForHealthInsurance') && (
                  <TextField
                    index={3}
                    includePrivateTooltip
                    label="How many employees are using health insurance benefits?"
                    digitsOnly
                    value={
                      isNil(cultureBrief.numberOfEmployeesEligibleForHealthInsurance)
                        ? ''
                        : cultureBrief.numberOfEmployeesEligibleForHealthInsurance
                    }
                    onUpdate={value =>
                      updateCultureBrief({
                        numberOfEmployeesEligibleForHealthInsurance:
                          value === '' ? null : Number(value),
                      })
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('employeeBenefits') && (
                  <CheckboxesField
                    id="employeeBenefits"
                    required={isRequiredField('employeeBenefits')}
                    validators={getValidators('employeeBenefits')}
                    errorMessages={getValidatorsMessages('employeeBenefits')}
                    index={4}
                    label="In addition to healthcare benefits, which additional benefits are available for a majority of full-time employees? Check all that apply"
                    choices={Object.values(EmployeeBenefitsEnum)}
                    onChange={(value: string) =>
                      updateCultureBrief({
                        employeeBenefits: value,
                      })
                    }
                    name="Employee Benefits"
                    selectedValues={cultureBrief.employeeBenefits}
                    value={cultureBrief.employeeBenefits}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('additionalEmployeeBenefits') && (
                  <TextField
                    maxCharacters={600}
                    index={5}
                    rows={isPrinting ? 10 : 5}
                    multiline
                    largeInput
                    onUpdate={text => updateCultureBrief({ additionalEmployeeBenefits: text })}
                    value={cultureBrief.additionalEmployeeBenefits || ''}
                    label="Are there any additional benefits you provide?"
                    helperTexts={['*A maximum of 600 characters allowed']}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('percentageOfEmployeesWithBigWages') && (
                  <TextField
                    id="percentageOfEmployeesWithBigWages"
                    required={isRequiredField('percentageOfEmployeesWithBigWages')}
                    validators={getValidators('percentageOfEmployeesWithBigWages', [
                      'minNumber:0',
                      'maxNumber:100',
                    ])}
                    errorMessages={getValidatorsMessages('percentageOfEmployeesWithBigWages', [
                      percentageError,
                      percentageError,
                    ])}
                    includePrivateTooltip
                    index={6}
                    label="% of employees whose wages are over $15/hour:"
                    digitsOnly
                    value={
                      isNil(cultureBrief.percentageOfEmployeesWithBigWages)
                        ? ''
                        : cultureBrief.percentageOfEmployeesWithBigWages
                    }
                    onUpdate={value =>
                      updateCultureBrief({
                        percentageOfEmployeesWithBigWages: value === '' ? null : Number(value),
                      })
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('percentageOfImmigrantEmployees') && (
                  <TextField
                    id="percentageOfImmigrantEmployees"
                    required={isRequiredField('percentageOfImmigrantEmployees')}
                    validators={getValidators('percentageOfImmigrantEmployees', [
                      'minNumber:0',
                      'maxNumber:100',
                    ])}
                    errorMessages={getValidatorsMessages('percentageOfImmigrantEmployees', [
                      percentageError,
                      percentageError,
                    ])}
                    includePrivateTooltip
                    infoTooltip="Please use your best estimate since this information isn’t typically formally collected. This data is used for recruiting and retention research to help develop best practices. There is a long-time trend of recent immigrants working in aging services. Some providers are doing a lot to recruit within immigrant populations and provide this population with additional services."
                    index={7}
                    label="% of employees who are first-generation immigrants:"
                    digitsOnly
                    value={
                      isNil(cultureBrief.percentageOfImmigrantEmployees)
                        ? ''
                        : cultureBrief.percentageOfImmigrantEmployees
                    }
                    onUpdate={value =>
                      updateCultureBrief({
                        percentageOfImmigrantEmployees: value === '' ? null : Number(value),
                      })
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                <br />
                <br />
                {[
                  'totalBoardComposition',
                  'totalBoardWomen',
                  'totalBoardMinoritiesRace',
                  'totalBoardMinoritiesLgbt',
                ]
                  .map(field => shouldShowField(field))
                  .every(Boolean) && (
                  <>
                    <Typography variant="h6">Corporate Governance:</Typography>
                    <div className={classes.formField}>
                      <FormFieldLabel
                        required
                        index={8}
                        infoTooltip="This question is about your organization’s board or owners, not management company. If you have multiple owners, please include all of them in your responses."
                        label="Board Composition:"
                        includePrivateTooltip
                        isCompletedCultureBrief={isCompletedCultureBrief}
                      />
                      <Grid container spacing={1} className={classes.largeInput}>
                        {shouldShowField('totalBoardComposition') && (
                          <Grid item sm={6}>
                            <TextField
                              id="totalBoardComposition"
                              validators={getValidators('totalBoardComposition')}
                              errorMessages={getValidatorsMessages('totalBoardComposition')}
                              largeInput
                              label="Total:"
                              digitsOnly
                              value={
                                isNil(cultureBrief.totalBoardComposition)
                                  ? ''
                                  : cultureBrief.totalBoardComposition
                              }
                              onUpdate={value =>
                                updateCultureBrief({
                                  totalBoardComposition: value === '' ? null : Number(value),
                                })
                              }
                              isCompletedCultureBrief={isCompletedCultureBrief}
                            />
                          </Grid>
                        )}
                        {shouldShowField('totalBoardWomen') && (
                          <Grid item sm={6}>
                            <TextField
                              id="totalBoardWomen"
                              validators={getValidators('totalBoardWomen')}
                              errorMessages={getValidatorsMessages('totalBoardWomen')}
                              largeInput
                              label="Women:"
                              digitsOnly
                              value={
                                isNil(cultureBrief.totalBoardWomen)
                                  ? ''
                                  : cultureBrief.totalBoardWomen
                              }
                              onUpdate={value =>
                                updateCultureBrief({
                                  totalBoardWomen: value === '' ? null : Number(value),
                                })
                              }
                              isCompletedCultureBrief={isCompletedCultureBrief}
                            />
                          </Grid>
                        )}
                        {shouldShowField('totalBoardMinoritiesRace') && (
                          <Grid item sm={6}>
                            <TextField
                              id="totalBoardMinoritiesRace"
                              validators={getValidators('totalBoardMinoritiesRace')}
                              errorMessages={getValidatorsMessages('totalBoardMinoritiesRace')}
                              largeInput
                              label="Minorities-Ethnicity/Race:"
                              digitsOnly
                              value={
                                isNil(cultureBrief.totalBoardMinoritiesRace)
                                  ? ''
                                  : cultureBrief.totalBoardMinoritiesRace
                              }
                              onUpdate={value =>
                                updateCultureBrief({
                                  totalBoardMinoritiesRace: value === '' ? null : Number(value),
                                })
                              }
                              isCompletedCultureBrief={isCompletedCultureBrief}
                            />
                          </Grid>
                        )}
                        {shouldShowField('totalBoardMinoritiesLgbt') && (
                          <Grid item sm={6}>
                            <TextField
                              id="totalBoardMinoritiesLgbt"
                              validators={getValidators('totalBoardMinoritiesLgbt')}
                              errorMessages={getValidatorsMessages('totalBoardMinoritiesLgbt')}
                              largeInput
                              label="Minorities-LGBTQIA+:"
                              digitsOnly
                              value={
                                isNil(cultureBrief.totalBoardMinoritiesLgbt)
                                  ? ''
                                  : cultureBrief.totalBoardMinoritiesLgbt
                              }
                              onUpdate={value =>
                                updateCultureBrief({
                                  totalBoardMinoritiesLgbt: value === '' ? null : Number(value),
                                })
                              }
                              isCompletedCultureBrief={isCompletedCultureBrief}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </>
                )}
                {[
                  'totalCeoReportsComposition',
                  'totalCeoReportsWomen',
                  'totalCeoReportsMinoritiesRace',
                  'totalCeoReportsMinoritiesLgbt',
                ]
                  .map(field => shouldShowField(field))
                  .every(Boolean) && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      required
                      index={9}
                      infoTooltip="if someone is in multiple demographics groups, include them in counts for all the groups that apply.  If you don’t know the information, please enter 0. Minorities are split into specific groups to ensure we’re collecting the same information across organizations."
                      label="Number of Direct Reports to the CEO:"
                      includePrivateTooltip
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <Grid container spacing={1} className={classes.largeInput}>
                      {shouldShowField('totalCeoReportsComposition') && (
                        <Grid item sm={6}>
                          <TextField
                            id="totalCeoReportsComposition"
                            validators={getValidators('totalCeoReportsComposition')}
                            errorMessages={getValidatorsMessages('totalCeoReportsComposition')}
                            largeInput
                            label="Total:"
                            digitsOnly
                            value={
                              isNil(cultureBrief.totalCeoReportsComposition)
                                ? ''
                                : cultureBrief.totalCeoReportsComposition
                            }
                            onUpdate={value =>
                              updateCultureBrief({
                                totalCeoReportsComposition: value === '' ? null : Number(value),
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('totalCeoReportsWomen') && (
                        <Grid item sm={6}>
                          <TextField
                            id="totalCeoReportsWomen"
                            validators={getValidators('totalCeoReportsWomen')}
                            errorMessages={getValidatorsMessages('totalCeoReportsWomen')}
                            largeInput
                            label="Women:"
                            digitsOnly
                            value={
                              isNil(cultureBrief.totalCeoReportsWomen)
                                ? ''
                                : cultureBrief.totalCeoReportsWomen
                            }
                            onUpdate={value =>
                              updateCultureBrief({
                                totalCeoReportsWomen: value === '' ? null : Number(value),
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('totalCeoReportsMinoritiesRace') && (
                        <Grid item sm={6}>
                          <TextField
                            id="totalCeoReportsMinoritiesRace"
                            validators={getValidators('totalCeoReportsMinoritiesRace')}
                            errorMessages={getValidatorsMessages('totalCeoReportsMinoritiesRace')}
                            largeInput
                            label="Minorities-Ethnicity/Race:"
                            digitsOnly
                            value={
                              isNil(cultureBrief.totalCeoReportsMinoritiesRace)
                                ? ''
                                : cultureBrief.totalCeoReportsMinoritiesRace
                            }
                            onUpdate={value =>
                              updateCultureBrief({
                                totalCeoReportsMinoritiesRace: value === '' ? null : Number(value),
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('totalCeoReportsMinoritiesLgbt') && (
                        <Grid item sm={6}>
                          <TextField
                            id="totalCeoReportsMinoritiesLgbt"
                            validators={getValidators('totalCeoReportsMinoritiesLgbt')}
                            errorMessages={getValidatorsMessages('totalCeoReportsMinoritiesLgbt')}
                            largeInput
                            label="Minorities-LGBTQIA+:"
                            digitsOnly
                            value={
                              isNil(cultureBrief.totalCeoReportsMinoritiesLgbt)
                                ? ''
                                : cultureBrief.totalCeoReportsMinoritiesLgbt
                            }
                            onUpdate={value =>
                              updateCultureBrief({
                                totalCeoReportsMinoritiesLgbt: value === '' ? null : Number(value),
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
                {shouldShowField('hasDiversityEquity') && (
                  <RadioButtonsField
                    id="hasDiversityEquity"
                    required={isRequiredField('hasDiversityEquity')}
                    validators={getValidators('hasDiversityEquity')}
                    errorMessages={getValidatorsMessages('hasDiversityEquity')}
                    index={10}
                    selectedValue={cultureBrief.hasDiversityEquity}
                    infoTooltip="To select yes, the program should support racially diverse team members."
                    label="Do you have a Diversity Equity & Inclusion program led by either a C-suite leader or a member of the Board that includes either sponsorship or mentorship for participants?"
                    name="diversityEquity"
                    includePrivateTooltip
                    choices={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    onChange={value =>
                      updateCultureBrief({
                        hasDiversityEquity: value as boolean,
                      })
                    }
                    value={cultureBrief.hasDiversityEquity}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('hasDiversityEquity') && (
                  <TextField
                    id="diversityEquityDescription"
                    rows={isPrinting ? 10 : 5}
                    multiline
                    largeInput
                    validators={cultureBrief.hasDiversityEquity ? ['required'] : []}
                    errorMessages={cultureBrief.hasDiversityEquity ? ['Required'] : []}
                    onUpdate={text => updateCultureBrief({ diversityEquityDescription: text })}
                    value={cultureBrief.diversityEquityDescription || ''}
                    label="If yes, please describe:"
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
              </div>
            </Paper>
            <Paper elevation={0} className={classes.section}>
              <div className={classes.content}>
                <Typography variant="h4">
                  {isCompletedCultureBrief && 'Section 4: '}Careers
                </Typography>
                <Typography variant="h6">Inspire others to join your team.</Typography>
                <Typography color="textSecondary">
                  Click the info icon for helpful tips and ideas for what to include in these
                  sections.
                </Typography>
                {shouldShowField('reasonsToWorkForCompany') && (
                  <TextField
                    maxCharacters={250}
                    index={1}
                    rows={isPrinting ? 10 : 5}
                    multiline
                    largeInput
                    onUpdate={text => updateCultureBrief({ reasonsToWorkForCompany: text })}
                    value={cultureBrief.reasonsToWorkForCompany || ''}
                    infoTooltip="Ex. Our associates are the heart of everything we do. When you invest your time in us, we’ll invest in you. Join our team and spend every day making a difference in the lives of seniors in our community."
                    label="Please describe why potential candidates should work for your company?"
                    helperTexts={['*A maximum of 250 characters allowed']}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('companyMission') && (
                  <TextField
                    maxCharacters={250}
                    index={2}
                    rows={isPrinting ? 10 : 5}
                    multiline
                    largeInput
                    infoTooltip="Ex. Creating a fulfilling and joyful community for our team and for the seniors care we serve."
                    onUpdate={text => updateCultureBrief({ companyMission: text })}
                    value={cultureBrief.companyMission || ''}
                    label="Please describe your company mission:"
                    helperTexts={['*A maximum of 250 characters allowed']}
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
                {shouldShowField('careersPageLink') && (
                  <TextField
                    validators={['isValidUrl']}
                    errorMessages={['Please enter a valid URL, beginning with http:// or https://']}
                    index={3}
                    infoTooltip="If you have a careers page for your organization, link to it here. If you don’t have a page that you’d like displayed on your profile, leave this field blank."
                    onUpdate={url => updateCultureBrief({ careersPageLink: url })}
                    value={cultureBrief.careersPageLink || ''}
                    label="Link to your Organization’s Careers page:"
                    largeInput
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                )}
              </div>
            </Paper>
            {!isPrinting && (
              <Paper elevation={0} className={classes.section}>
                <div className={classes.content}>
                  <Typography variant="h4">
                    {isCompletedCultureBrief && 'Section 5: '}Media
                  </Typography>
                  <Typography variant="h6">
                    Upload Images and logo to be displayed on your Certification Profile
                  </Typography>
                  <br />
                  <br />
                  <FormFieldLabel
                    required
                    index={1}
                    label="Upload one logo image:"
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                  <Typography color="textSecondary" variant="subtitle2">
                    *Requirements: JPG, JPEG, PNG file types only, no larger than{' '}
                    {CULTURE_BRIEF_MAX_IMAGE_SIZE} MB. Logo must be on a white or clear background,
                    landscape mode, file must be at least {CULTURE_BRIEF_IMAGE_MIN_WIDTH}px wide.
                  </Typography>
                  <ImagesValidator
                    {...commonImageValidatorProps}
                    imageType={CultureBriefImageTypeEnum.LOGO}
                    images={logoImages}
                    name="logo"
                    value={logoImages}
                    validators={['required']}
                    errorMessages={['Required']}
                  />
                  <br />
                  <br />
                  <FormFieldLabel
                    required
                    index={2}
                    label="Profile images:"
                    infoTooltip={
                      <div>
                        <Typography>Photo instructions</Typography>
                        <Typography color="textSecondary" component="div">
                          Provide at least {CULTURE_BRIEF_MIN_IMAGES} and no more than{' '}
                          {cultureBriefMaxImages} photos to be included in your Certification
                          Profile. Please make sure to include at least:
                          <ul>
                            <li>1 image of your people</li>
                            <li>
                              1 image of your work environment (eg. Your headquarters, office,
                              manufacturing or retail space)
                            </li>
                            <li>1 image of your CEO</li>
                          </ul>
                          If you win placement on a list, our media partner will select a photo to
                          represent your organization. Please ensure all photos are professional
                          images you will be proud to share on Great Place to Work’s website, social
                          media channels, and our media partners’ websites. Our media partners will
                          not permit photo changes, so please ensure your images meet the listed
                          requirements and are correct now.
                        </Typography>
                      </div>
                    }
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                  <Typography color="textSecondary">
                    Upload at least {CULTURE_BRIEF_MIN_IMAGES}, but no more than{' '}
                    {cultureBriefMaxImages} photos that meet the requirements. Enter the required
                    captions. Please do not use stock photos, these photos should reflect your team
                    and what makes your company great! Please click on the info icon for the full
                    list of instructions.
                  </Typography>
                  <br />
                  <Typography color="textSecondary" variant="subtitle2">
                    *Requirements: Upload at least {CULTURE_BRIEF_MIN_IMAGES} images, JPG, JPEG, PNG
                    file types only, no larger than {CULTURE_BRIEF_MAX_IMAGE_SIZE} MB each, and at
                    least {CULTURE_BRIEF_IMAGE_MIN_WIDTH}px wide (or{' '}
                    {CULTURE_BRIEF_IMAGE_MIN_HEIGHT}px high). Captions are required. If certified
                    the first photo you upload will be used on the Browse Certified Companies page,
                    and cannot be edited or deleted once your profile is live.
                  </Typography>
                  <ImagesValidator
                    {...commonImageValidatorProps}
                    imageType={CultureBriefImageTypeEnum.COMPANY}
                    images={companyImages}
                    name="companyImages"
                    value={
                      companyImages.filter(
                        image =>
                          image.imageType === CultureBriefImageTypeEnum.COMPANY &&
                          image.caption !== '',
                      ).length
                    }
                    validators={['required', `minNumber:${CULTURE_BRIEF_MIN_IMAGES}`]}
                    errorMessages={[
                      'Required',
                      `Please upload at least ${CULTURE_BRIEF_MIN_IMAGES} images with captions for each.`,
                    ]}
                  />
                  <br />
                  <br />
                  <FormFieldLabel
                    required
                    index={3}
                    label="CEO image:"
                    isCompletedCultureBrief={isCompletedCultureBrief}
                  />
                  <Typography color="textSecondary">
                    Please upload 1 image of your CEO. Our media partner may use this photo. Please
                    ensure all photos are professional images you will be proud to share on Great
                    Place to Work’s website, social media channels, and our media partners’
                    websites. Our media partners will not permit photo changes, so please ensure
                    your images meet the listed requirements before you submit.
                  </Typography>
                  <br />
                  <Typography color="textSecondary" variant="subtitle2">
                    *Requirements: JPG, JPEG, PNG file types only, no larger than{' '}
                    {CULTURE_BRIEF_MAX_IMAGE_SIZE} MB, high quality, at least{' '}
                    {CULTURE_BRIEF_IMAGE_MIN_WIDTH}px wide (or {CULTURE_BRIEF_IMAGE_MIN_HEIGHT}px
                    high).
                  </Typography>
                  <ImagesValidator
                    {...commonImageValidatorProps}
                    imageType={CultureBriefImageTypeEnum.CEO}
                    images={ceoImages}
                    name="ceo"
                    value={ceoImages}
                    validators={['required']}
                    errorMessages={['Required']}
                  />
                </div>
              </Paper>
            )}
            <Paper elevation={0} className={classes.section}>
              <div className={classes.content}>
                <Typography variant="h4">
                  {isCompletedCultureBrief && 'Section 6: '}Contact Info
                </Typography>
                <Typography color="textSecondary">
                  Please provide contact information for the individuals you would like notified in
                  the event that your company receives recognition on the Best Workplaces in Aging
                  Services list. These individuals will be notified of any finalist status on the
                  list, and contact information will be provided to Fortune for badge licensing
                  contact. Please include your CEO or another C-suite executive, the main contact
                  managing your certification, and a marketing contact if needed.
                </Typography>
                {['ceoFirstName', 'ceoLastName', 'ceoTitle', 'ceoPhoneNumber', 'ceoEmail']
                  .map(field => shouldShowField(field))
                  .every(Boolean) && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      required
                      index={1}
                      label="CEO or C-suite Executive Contact"
                      includePrivateTooltip
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <Grid container className={classes.largeInput}>
                      {shouldShowField('ceoFirstName') && (
                        <Grid item sm={6}>
                          <TextField
                            id="ceoFirstName"
                            validators={getValidators('ceoFirstName')}
                            errorMessages={getValidatorsMessages('ceoFirstName')}
                            largeInput
                            label="First Name:"
                            value={cultureBrief.ceoFirstName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                ceoFirstName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('ceoLastName') && (
                        <Grid item sm={6}>
                          <TextField
                            id="ceoLastName"
                            validators={getValidators('ceoLastName')}
                            errorMessages={getValidatorsMessages('ceoLastName')}
                            largeInput
                            label="Last Name:"
                            value={cultureBrief.ceoLastName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                ceoLastName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('ceoTitle') && (
                        <Grid item sm={6}>
                          <TextField
                            id="ceoTitle"
                            validators={getValidators('ceoTitle')}
                            errorMessages={getValidatorsMessages('ceoTitle')}
                            largeInput
                            label="Title:"
                            value={cultureBrief.ceoTitle || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                ceoTitle: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('ceoPhoneNumber') && (
                        <Grid item sm={6}>
                          <TextField
                            id="ceoPhoneNumber"
                            validators={getValidators('ceoPhoneNumber')}
                            errorMessages={getValidatorsMessages('ceoPhoneNumber')}
                            largeInput
                            digitsOnly
                            label="Phone Number:"
                            value={cultureBrief.ceoPhoneNumber || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                ceoPhoneNumber: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('ceoEmail') && (
                        <Grid item sm={12}>
                          <TextField
                            id="ceoEmail"
                            validators={getValidators('ceoEmail')}
                            errorMessages={getValidatorsMessages('ceoEmail')}
                            largeInput
                            label="Email:"
                            value={cultureBrief.ceoEmail || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                ceoEmail: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
                {[
                  'mainContactFirstName',
                  'mainContactLastName',
                  'mainContactTitle',
                  'mainContactPhoneNumber',
                  'mainContactEmail',
                ]
                  .map(field => shouldShowField(field))
                  .every(Boolean) && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      required
                      index={2}
                      label="Main contact managing your certification"
                      includePrivateTooltip
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <Grid container className={classes.largeInput}>
                      {shouldShowField('mainContactFirstName') && (
                        <Grid item sm={6}>
                          <TextField
                            id="mainContactFirstName"
                            validators={getValidators('mainContactFirstName')}
                            errorMessages={getValidatorsMessages('mainContactFirstName')}
                            largeInput
                            label="First Name:"
                            value={cultureBrief.mainContactFirstName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                mainContactFirstName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('mainContactLastName') && (
                        <Grid item sm={6}>
                          <TextField
                            id="mainContactLastName"
                            validators={getValidators('mainContactLastName')}
                            errorMessages={getValidatorsMessages('mainContactLastName')}
                            largeInput
                            label="Last Name:"
                            value={cultureBrief.mainContactLastName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                mainContactLastName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('mainContactTitle') && (
                        <Grid item sm={6}>
                          <TextField
                            id="mainContactTitle"
                            validators={getValidators('mainContactTitle')}
                            errorMessages={getValidatorsMessages('mainContactTitle')}
                            largeInput
                            label="Title:"
                            value={cultureBrief.mainContactTitle || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                mainContactTitle: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('mainContactPhoneNumber') && (
                        <Grid item sm={6}>
                          <TextField
                            id="mainContactPhoneNumber"
                            validators={getValidators('mainContactPhoneNumber')}
                            errorMessages={getValidatorsMessages('mainContactPhoneNumber')}
                            largeInput
                            digitsOnly
                            label="Phone Number:"
                            value={cultureBrief.mainContactPhoneNumber || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                mainContactPhoneNumber: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('mainContactEmail') && (
                        <Grid item sm={12}>
                          <TextField
                            id="mainContactEmail"
                            validators={getValidators('mainContactEmail')}
                            errorMessages={getValidatorsMessages('mainContactEmail')}
                            largeInput
                            label="Email:"
                            value={cultureBrief.mainContactEmail || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                mainContactEmail: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
                {[
                  'marketingContactFirstName',
                  'marketingContactLastName',
                  'marketingContactTitle',
                  'marketingContactPhoneNumber',
                  'marketingContactEmail',
                ]
                  .map(field => shouldShowField(field))
                  .every(Boolean) && (
                  <div className={classes.formField}>
                    <FormFieldLabel
                      index={3}
                      label="Marketing contact"
                      includePrivateTooltip
                      isCompletedCultureBrief={isCompletedCultureBrief}
                    />
                    <Grid container className={classes.largeInput}>
                      {shouldShowField('marketingContactFirstName') && (
                        <Grid item sm={6}>
                          <TextField
                            largeInput
                            label="First Name:"
                            value={cultureBrief.marketingContactFirstName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                marketingContactFirstName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('marketingContactLastName') && (
                        <Grid item sm={6}>
                          <TextField
                            largeInput
                            label="Last Name:"
                            value={cultureBrief.marketingContactLastName || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                marketingContactLastName: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('marketingContactTitle') && (
                        <Grid item sm={6}>
                          <TextField
                            largeInput
                            label="Title:"
                            value={cultureBrief.marketingContactTitle || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                marketingContactTitle: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('marketingContactPhoneNumber') && (
                        <Grid item sm={6}>
                          <TextField
                            largeInput
                            digitsOnly
                            label="Phone Number:"
                            value={cultureBrief.marketingContactPhoneNumber || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                marketingContactPhoneNumber: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                      {shouldShowField('marketingContactEmail') && (
                        <Grid item sm={12}>
                          <TextField
                            largeInput
                            validators={['isEmail']}
                            errorMessages={['Not a valid email']}
                            label="Email:"
                            value={cultureBrief.marketingContactEmail || ''}
                            onUpdate={value =>
                              updateCultureBrief({
                                marketingContactEmail: value,
                              })
                            }
                            isCompletedCultureBrief={isCompletedCultureBrief}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
              </div>
            </Paper>
            {!isPrinting && (
              <div className={classes.footer}>
                <div>
                  {failsValidation && (
                    <Danger>Please correct the indicated fields before continuing.</Danger>
                  )}
                </div>
                <SubmitButton isSubmitting={isSubmitting} right>
                  {isCompletedCultureBrief ? 'Save & Publish' : 'Submit'}
                </SubmitButton>
              </div>
            )}
            {openConfirmationDialog && (
              <ActionDialog
                title="Are you sure?"
                cancelButtonText="Back to Edit"
                isSubmitting={isSubmitting}
                content={
                  isCompletedCultureBrief
                    ? 'Is all your information correct? Once you save, this information will be published on your public profile.'
                    : 'Is all your information correct? Once you select Submit, this information will be published to your public profile. Some sections will no longer be editable.'
                }
                submitButtonText="Save"
                onClose={() => setOpenConfirmationDialog(false)}
                onSubmit={() => {
                  onSubmit()
                    .catch(caughtError => {
                      setError(caughtError.message)
                    })
                    .finally(() => {
                      setOpenConfirmationDialog(false)
                      setFailsValidation(false)
                      setIsSubmitting(false)
                    })
                }}
              />
            )}
            {error !== '' && (
              <ActionDialog
                title="Warning"
                content={error}
                submitButtonText="Exit"
                onSubmit={() => setError('')}
              />
            )}
          </PrintableComponent>
        </ValidatorForm>
      </Page>
    </>
  )
}

export default CultureBrief
