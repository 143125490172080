import React, { useEffect, useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import EditableTagsText from 'components/Blocks/EditableTagsText'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import Errors from 'components/Blocks/FormHelpers/Errors'
import {
  Notification,
  NotificationInput,
} from 'components/Survey/Wizard/Steps/Notifications/common'
import SendTestEmail from 'components/Survey/Wizard/Steps/Notifications/SendTestEmail'
import SendTestSms from 'components/Survey/Wizard/Steps/Notifications/SendTestSms'
import { SurveysSurveyQuery, SurveyProductTypeEnum } from 'generated/graphql'

const useStyles = makeStyles(({ palette, spacing }) => ({
  tabs: {
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  contentTitle: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  subjectContainer: {
    marginBottom: spacing(3),
    alignItems: 'center',
  },
  textOptional: {
    backgroundColor: palette.common.iceGrey50,
    borderRadius: 3,
  },
  textRequired: {
    paddingLeft: spacing(),
    // lineHeight needs to be "normal", same as the one in EditableTagsText
    lineHeight: 'normal',
  },
  border: {
    border: `1px solid ${palette.common.navy25}`,
    borderRadius: 3,
  },
  messageContentContainer: {
    padding: spacing(),
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(),
  },
}))

export enum DialogTabs {
  EMAIL = 'email',
  SMS = 'sms',
}

const getTagsForSurvey = (survey: SurveysSurveyQuery['survey']) => {
  // These must stay in sync with the backend, see "get_employee_notification_context" and "get_resident_notification_context"
  let tags: string[] = []
  const surveyHasEndDate = Boolean(survey.endDate)
  if (survey.productType === SurveyProductTypeEnum.EMPLOYEE) {
    tags = [
      'location_name',
      'email_survey_link',
      'sms_survey_link',
      'support_email',
      'first_name',
      ...(surveyHasEndDate ? ['survey_end_date'] : []),
      'org_name',
      'org_abbr',
    ]
  } else if (survey.productType === SurveyProductTypeEnum.RESIDENT) {
    tags = [
      'location_name',
      'email_survey_link',
      'sms_survey_link',
      'support_email',
      'first_name',
      ...(surveyHasEndDate ? ['survey_end_date'] : []),
      'org_name',
      'org_abbr',
    ]
  } else {
    return tags
  }
  return tags
}

type NotificationFooterProps = {
  onReset(): void
}

const NotificationFooter: React.FC<NotificationFooterProps> = ({ onReset }) => {
  const classes = useStyles()

  return (
    <div className={classes.helper}>
      <Typography color="textSecondary" variant="body2">
        {'Need help understanding {{tags}}?'}
        <ExternalNavLink
          to="https://activatedinsights.force.com/help/s/article/How-To-Use-Merge-Tags"
          label=" Learn more >"
        />
      </Typography>
      <NavLink to="#" onClick={onReset}>
        Reset to default
      </NavLink>
    </div>
  )
}

type Props = {
  survey: SurveysSurveyQuery['survey']
  notificationToUpdate: Notification
  isSubmitting: boolean
  onClose(): void
  onUpdate(notification: NotificationInput): void
  sendTestEmail(emailAddress: string): void
  sendTestSms(phoneNumber: string): void
  onReset(emailOrSms: 'email' | 'sms'): void
}

const UpdateNotificationDialog: React.FC<Props> = ({
  survey,
  notificationToUpdate,
  isSubmitting,
  onClose,
  onUpdate,
  sendTestEmail,
  sendTestSms,
  onReset,
}) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(DialogTabs.EMAIL)

  const availableTags = getTagsForSurvey(survey)

  const [notification, setNotification] = useState<Notification>(notificationToUpdate)

  useEffect(() => {
    setNotification(notificationToUpdate)
  }, [notificationToUpdate])

  const saveNotification = async () => {
    if (!isValidSms) {
      return
    }
    const notificationInput = {
      emailSubject: notification.emailSubject,
      emailTextOptional1: notification.emailTextOptional1,
      emailTextOptional2: notification.emailTextOptional2,
      smsOptional: notification.smsOptional,
    }

    await onUpdate(notificationInput)
    onClose()
  }

  const SMS_MAX_LENGTH = 150
  // Exclude {{ tags }} from the length limit.
  const mergeTagRegex = /{{(.*?)\}}/g
  const isValidSms = Boolean(
    notification.smsOptional &&
      notification.smsOptional.replace(mergeTagRegex, '').length <= SMS_MAX_LENGTH,
  )

  return (
    <Dialog open fullWidth>
      <DialogTitle id="email-notification-title">Edit Notification Text</DialogTitle>
      <DialogContent>
        <Tabs
          className={classes.tabs}
          value={currentTab}
          onChange={(e, value) => setCurrentTab(value)}
        >
          <Tab label="Email Text" value={DialogTabs.EMAIL} />
          <Tab label="SMS Text" value={DialogTabs.SMS} />
        </Tabs>
        <Typography variant="body2" color="textSecondary" className={classes.contentTitle}>
          {currentTab === DialogTabs.EMAIL && 'EMAIL TEXT'}
          {currentTab === DialogTabs.SMS && 'SMS TEXT'}
        </Typography>
        {currentTab === DialogTabs.EMAIL && (
          <>
            <Grid container className={classes.subjectContainer}>
              <Grid item sm={2}>
                <Typography color="textSecondary">Subject: </Typography>
              </Grid>
              <Grid item sm={10}>
                <EditableTagsText
                  availableTags={availableTags}
                  value={notification.emailSubject}
                  setValue={(emailSubject: string) =>
                    setNotification({ ...notification, emailSubject })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2}>
                <Typography color="textSecondary">Message: </Typography>
              </Grid>
              <Grid item sm={10} className={cn(classes.border, classes.messageContentContainer)}>
                <EditableTagsText
                  availableTags={availableTags}
                  value={notification.emailTextOptional1}
                  setValue={(emailTextOptional1: string) =>
                    setNotification({ ...notification, emailTextOptional1 })
                  }
                />
                <Typography color="textSecondary" className={classes.textRequired}>
                  {notification.emailTextRequired}
                </Typography>
                <EditableTagsText
                  availableTags={availableTags}
                  value={notification.emailTextOptional2}
                  setValue={(emailTextOptional2: string) =>
                    setNotification({ ...notification, emailTextOptional2 })
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} />
              <Grid item sm={10}>
                <NotificationFooter onReset={() => onReset('email')} />
              </Grid>
            </Grid>
          </>
        )}
        {currentTab === DialogTabs.SMS && (
          <>
            <div className={cn(classes.border, classes.messageContentContainer)}>
              <EditableTagsText
                availableTags={availableTags}
                value={notification.smsOptional}
                setValue={(smsOptional: string) =>
                  setNotification({ ...notification, smsOptional })
                }
              />
              <Typography color="textSecondary" className={classes.textRequired}>
                {notification.smsRequired}
              </Typography>
              {!isValidSms && (
                <Errors errors={[`SMS total length must be kept under ${SMS_MAX_LENGTH}.`]} />
              )}
            </div>
            <NotificationFooter onReset={() => onReset('sms')} />
          </>
        )}
        {currentTab === DialogTabs.EMAIL && <SendTestEmail sendTestEmail={sendTestEmail} />}
        {currentTab === DialogTabs.SMS && <SendTestSms sendTestSms={sendTestSms} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondaryNoBackground">
          Cancel
        </Button>
        <SubmitButton
          buttonType="button"
          isSubmitting={isSubmitting}
          color="primary"
          onClick={saveNotification}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateNotificationDialog
