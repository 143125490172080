import React, { useState, useEffect } from 'react'

import { Dialog, DialogTitle, makeStyles } from '@material-ui/core'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'

import ConfirmGroup from 'components/Settings/Team/Groups/ConfirmGroup'
import { Group } from 'components/Settings/Team/Groups/GroupsTypes'
import FilterAccessSettings from 'components/Settings/Team/Users/FilterAccessSettings'
import UserInfoSettings from 'components/Settings/Team/Users/UserInfoSettings'
import { UserData } from 'components/Settings/Team/Users/Users'
import { CurrentUserQuery, GroupNode, SettingsGroupFragment } from 'generated/graphql'
import { SettingsUser } from 'utils/types'

const useStyles = makeStyles(() => ({
  container: {
    width: 500,
  },
}))

// Used so we can show one combined set of permissions on the confirm dialog.
const combineGroupPermissions = (groups: Group[]): Group => ({
  name: '',
  users: [],
  accessToSurveyProduct: groups.some(g => g.accessToSurveyProduct),
  canInviteUsers: groups.some(g => g.canInviteUsers),
  canAccessDataStructure: groups.some(g => g.canAccessDataStructure),
  canAccessGeneralSettings: groups.some(g => g.canAccessGeneralSettings),
  canCreateSurveys: groups.some(g => g.canCreateSurveys),
  canManageActionPlans: groups.some(g => g.canManageActionPlans),
  canUseActionPlans: groups.some(g => g.canUseActionPlans),
  canUseAnalytics: groups.some(g => g.canUseAnalytics),
  canAccessSurveySettings: groups.some(g => g.canAccessSurveySettings),
  canManageCertifications: groups.some(g => g.canManageCertifications),
  canViewCertificationResults: groups.some(g => g.canViewCertificationResults),
  allSurveys: groups.some(g => g.allSurveys),
  surveys: flatten(groups.map(g => g.surveys)),
  insightsModules: uniq(flatten(groups.map(g => g.insightsModules))),
  residentCanAccessSurveySettings: groups.some(g => g.residentCanAccessSurveySettings),
  residentCanCreateSurveys: groups.some(g => g.residentCanCreateSurveys),
  residentAccessToSurveyProduct: groups.some(g => g.residentAccessToSurveyProduct),
  canAccessLonelinessResults: groups.some(g => g.canAccessLonelinessResults),
  residentInsightsModules: uniq(flatten(groups.map(g => g.residentInsightsModules))),
})

const getDefaultUser = (editUser?: UserData): SettingsUser => {
  return editUser
    ? {
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        email: editUser.email,
        title: editUser.title,
        id: editUser.id,
        groups: editUser.groups,
        filterTypesMap: editUser.filterTypesMap,
      }
    : {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        groups: [],
        filterTypesMap: {},
      }
}

type Props = {
  groups: GroupNode[]
  onSubmit(user: SettingsUser, message?: string): Promise<boolean>
  onClose(): void
  editUser?: UserData
  step?: string
  currentUser: CurrentUserQuery['currentUser']
}
/**
 * Dialog which can be opened to
 * - invite a user and take them through multiple steps (user info => filter access)
 * - update a specific step by providing the "step" prop.
 */
const UpdateUserDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  groups,
  editUser,
  step,
  currentUser,
}) => {
  const classes = useStyles()
  const [user, setUser] = useState<SettingsUser>(getDefaultUser(editUser))
  useEffect(() => {
    setUser(getDefaultUser(editUser))
  }, [editUser])
  const [confirmOpen, setConfirm] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentStep, setStep] = useState(step || 'userInfo') // userInfo, filterAccess
  const submit = async () => {
    setIsSubmitting(true)
    const success = await onSubmit(user, editUser ? 'User Updated' : 'User Invited')
    setIsSubmitting(false)
    if (success) {
      onClose()
    }
  }
  const goNext = () => {
    if (step) {
      return submit()
    }
    // Only set filter access for non-admin
    if (currentStep === 'userInfo' && !user.groups.some(g => g.isAdmin)) {
      setStep('filterAccess')
    } else {
      setConfirm(true)
    }
  }
  const commonProps = {
    onSubmit: goNext,
    onClose,
    updateUser: (update: Partial<SettingsUser>) => setUser({ ...user, ...update }),
    isSubmitting,
  }
  let title = 'Set Data Access'
  if (currentStep === 'userInfo') {
    if (user.id) {
      title = 'Update User'
    } else {
      title = 'Add User'
    }
  }
  return (
    <Dialog open onClose={onClose} aria-labelledby="user-dialog-title">
      <div className={classes.container}>
        <DialogTitle id="user-dialog-title">{title}</DialogTitle>
        {currentStep === 'userInfo' ? (
          <UserInfoSettings
            {...commonProps}
            submitLabel={step ? 'Save' : 'Next'}
            groups={groups}
            user={user}
          />
        ) : (
          <FilterAccessSettings
            {...commonProps}
            submitLabel={step ? 'Save' : 'Send Invitation'}
            selectedFilters={user.filterTypesMap}
            currentUser={currentUser}
            updateFilters={(filterTypesMap: SettingsUser['filterTypesMap']) => {
              setUser({ ...user, filterTypesMap })
            }}
          />
        )}
      </div>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirm(false)}
        aria-labelledby="group-dialog-title"
        aria-describedby="group-dialog-description"
      >
        <div /** Dialogs require a child element */>
          {user.groups.length > 0 && (
            <ConfirmGroup
              confirmType="user"
              title={`
                You are about to invite ${user.firstName} ${user.lastName}
                to groups ${user.groups.map(g => g.name).join(', ')}.
                People ${user.groups.length > 1 ? 'with these groups' : 'in this group'}
                 are able to:
              `}
              // The only situation we show the dialog is when we have assigned user groups
              // directly from the groups array -- so technically the type here is `SettingsGroupFragment`
              // but it's a complicated refactor to separate the group types.
              group={combineGroupPermissions(user.groups as SettingsGroupFragment[])}
              filters={Object.values(user.filterTypesMap)}
              onClose={() => setConfirm(false)}
              onConfirm={submit}
            />
          )}
        </div>
      </Dialog>
    </Dialog>
  )
}

export default UpdateUserDialog
