import React, { useState } from 'react'

import { makeStyles, Typography, Tooltip } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import FormPanel from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import WelcomeMessagePanel from 'components/Survey/Blocks/WelcomeMessagePanel'
import NavigationButtons from 'components/Survey/Wizard/NavigationButtons'
import CustomQuestionContainer, {
  getQuestionInputVars,
} from 'components/Survey/Wizard/Steps/Questions/CustomQuestionContainer'
import MarketingAddons from 'components/Survey/Wizard/Steps/Questions/MarketingAddons'
import MissingTranslationsWarning from 'components/Survey/Wizard/Steps/Questions/MissingTranslationsWarning'
import NpsQuestions from 'components/Survey/Wizard/Steps/Questions/NpsQuestions'
import PersonalInfoQuestions from 'components/Survey/Wizard/Steps/Questions/PersonalInfoQuestions'
import SurveyControls from 'components/Survey/Wizard/SurveyControls'
import {
  useSurveysQuestionsQuery,
  useSurveysDeleteQuestionMutation,
  useSurveysUpdateCustomQuestionMutation,
  SurveysQuestionsDocument,
  SurveysSurveyQuery,
  SurveysQuestionsQuery,
  QuestionFragment,
  SurveyTypeEnum,
  QCategory,
  SurveysSurveyDocument,
} from 'generated/graphql'

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    '& svg': {
      height: 18,
      marginLeft: spacing(),
      marginBottom: -4,
      color: palette.common.navy65,
    },
  },
  questions: {
    marginBottom: spacing(),
  },
  bottomBorder: {
    borderBottom: `1px solid ${palette.common.navy15}`,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

type Props = {
  survey: SurveysSurveyQuery['survey']
  goBack(): void
  goNext(uuid?: string): void
}

const CustomQuestions: React.FC<Props> = ({ goBack, goNext, survey }) => {
  const surveyUuid = survey.uuid
  const classes = useStyles()
  const [isOpenTranslationsDialog, setIsOpenTranslationsDialog] = useState(false)

  const result = useSurveysQuestionsQuery({ variables: { surveyUuid } })
  const [deleteQuestion] = useSurveysDeleteQuestionMutation()
  const [updateCustomQuestion] = useSurveysUpdateCustomQuestionMutation()

  const cacheQuestionsQuery = {
    query: SurveysQuestionsDocument,
    variables: {
      surveyUuid,
    },
  }

  const handleAddQuestion = (question?: QuestionFragment) => {
    let questionInput
    if (question) {
      // When question is given, it means that we want to entirely duplicate a question. For this, all we need to do is to
      // clone all attrs but wipe out question's uuid and choices' uuids so they appear as new to the backend
      questionInput = getQuestionInputVars(question)
      questionInput.questionUuid = null
      questionInput.choices = questionInput.choices?.map(choice => ({
        ...choice,
        uuid: null,
      }))
    } else {
      questionInput = getQuestionInputVars()
    }
    updateCustomQuestion({
      variables: {
        surveyUuid,
        question: questionInput,
      },
      update: (cache, { data }) => {
        if (!data?.updateCustomQuestion?.question) return
        const cachedData = cache.readQuery<SurveysQuestionsQuery>(cacheQuestionsQuery)
        if (!cachedData?.questions) return
        cache.writeQuery({
          ...cacheQuestionsQuery,
          data: { questions: cachedData.questions.concat([data.updateCustomQuestion.question]) },
        })
      },
      refetchQueries: [
        {
          // We're refetching the survey only for the purpose of hasMissingTranslations property
          query: SurveysSurveyDocument,
          variables: { uuid: surveyUuid },
        },
      ],
    })
  }

  const handleDeleteQuestion = (questionUuid: string) => {
    deleteQuestion({
      variables: {
        surveyUuid,
        questionUuid,
      },
      update: cache => {
        const cachedData = cache.readQuery<SurveysQuestionsQuery>(cacheQuestionsQuery)
        if (!cachedData?.questions) return
        cache.writeQuery({
          ...cacheQuestionsQuery,
          data: { questions: cachedData.questions.filter(({ uuid }) => uuid !== questionUuid) },
        })
      },
      refetchQueries: [
        {
          // We're refetching the survey only for the purpose of hasMissingTranslations property
          query: SurveysSurveyDocument,
          variables: { uuid: surveyUuid },
        },
      ],
    })
  }
  return (
    <>
      <SurveyControls
        survey={survey}
        showPreview
        showTranslations
        isOpenTranslationsDialog={isOpenTranslationsDialog}
        setIsOpenTranslationsDialog={setIsOpenTranslationsDialog}
      />
      <Typography className={classes.title} variant="subtitle1">
        CUSTOM SURVEY
        <Tooltip
          title={`Custom surveys let you dive deeper into anything you'd like! Close the loop
            after you make changes or get more targeted feedback from your employees to help you
            improve employee experience.`}
        >
          <InfoIcon />
        </Tooltip>
      </Typography>
      <div className={classes.bottomBorder}>
        <WelcomeMessagePanel survey={survey} />
      </div>
      <ResponseHandler {...result}>
        {({ questions }) => {
          // We need to filter by custom questions only, because custom surveys can have some optional pregenerated questions (like testimonials, online review, nps etc)
          const surveyCustomQuestions = questions.filter(q => q.isCustom)
          const surveySystemQuestions = questions.filter(q => !q.isCustom)
          const personalInfoQuestions = questions.filter(
            q => q.category === QCategory.PERSONAL_INFO,
          )
          return (
            <div className={classes.questions}>
              <div className={classes.bottomBorder}>
                {personalInfoQuestions.length > 0 && (
                  <PersonalInfoQuestions
                    questions={personalInfoQuestions}
                    surveyUuid={survey.uuid}
                  />
                )}
              </div>
              <div className={classes.bottomBorder}>
                {survey.type === SurveyTypeEnum.RESIDENT_CUSTOM && (
                  <NpsQuestions survey={survey} surveyQuestions={surveySystemQuestions} />
                )}
              </div>
              <div className={classes.bottomBorder}>
                {surveyCustomQuestions
                  // Personal info questions get their own section above, so we don't display them here as well
                  .filter(question => question.category !== QCategory.PERSONAL_INFO)
                  .map((question, index) => (
                    <CustomQuestionContainer
                      key={question.uuid}
                      surveyUuid={survey.uuid}
                      question={question}
                      index={index}
                      handleAddQuestion={handleAddQuestion}
                      handleDeleteQuestion={handleDeleteQuestion}
                    />
                  ))}
              </div>
              <FormPanel removeDetailsPadding extraClassName={classes.bottomBorder} square>
                <Button onClick={() => handleAddQuestion()} color="secondaryNoBackground">
                  <AddCircleIcon />
                  &nbsp;Add New Question
                </Button>
              </FormPanel>
              {survey.type === SurveyTypeEnum.RESIDENT_CUSTOM && (
                <MarketingAddons survey={survey} surveyQuestions={surveySystemQuestions} />
              )}
            </div>
          )
        }}
      </ResponseHandler>
      <div className={classes.footer}>
        {survey.hasMissingTranslations ? (
          <MissingTranslationsWarning onOpen={() => setIsOpenTranslationsDialog(true)} />
        ) : (
          <div />
        )}
        <NavigationButtons goBack={goBack} goNext={goNext} />
      </div>
    </>
  )
}

export default CustomQuestions
