import React, { useState } from 'react'

import { Tooltip } from '@material-ui/core'
import { NavLink, useHistory } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import Dialog from 'components/Blocks/Dialogs/Dialog'
import ServiceAreasTab from 'components/Settings/General/ServicesAreasTab'
import PersonalInfoQuestionsSection from 'components/Survey/Wizard/Steps/Summary/PersonalInfoQuestionsSection'
import Section, { SectionsEnum } from 'components/Survey/Wizard/Steps/Summary/Section'
import useStyles from 'components/Survey/Wizard/Steps/Summary/styles'
import { WizardStepsEnum } from 'components/Survey/WizardContainer'
import {
  CurrentUserQuery,
  SurveysSurveyQuery,
  SettingsSectionType,
  QuestionsSectionType,
  DataTypeCode,
  SurveyTypeEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { getNpsAbbreviation } from 'utils'
import { filterValuesPage, CUSTOM_SURVEY_TYPES, URLS } from 'utils/constants'

type Props = {
  currentUser: CurrentUserQuery['currentUser']
  survey: SurveysSurveyQuery['survey']
  questions: QuestionsSectionType
  settings?: SettingsSectionType | null
  editable: boolean
  goToStep(stepName: WizardStepsEnum): void
  responseRateByClient: boolean
  onRefetch(): void
  errors: { [key: string]: boolean }
  warnings: { [key: string]: boolean }
}

const QuestionsSection: React.FC<Props> = ({
  currentUser,
  survey,
  questions,
  settings,
  editable,
  goToStep,
  onRefetch,
  errors,
  warnings,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [showServicesModal, setShowServicesModal] = useState(false)
  const addGoogleIds = (
    <Button
      noMargins
      onClick={() =>
        history.push(
          filterValuesPage(
            currentUser.filters.find(filterType => filterType.dtCode === DataTypeCode.AI_LOCATION)
              ?.filterTypeUuid || '',
          ),
        )
      }
      color="secondaryNoBackground"
    >
      Add IDs Here
    </Button>
  )

  return (
    <Section
      editable={editable}
      goToStep={() => goToStep(WizardStepsEnum.QUESTIONS)}
      title={SectionsEnum.QUESTIONS}
      ready={questions.ready}
      warningsNumber={questions.warnings.length}
    >
      <ul>
        {errors.NO_QUESTIONS && (
          <li className={classes.error}>
            You need to add at least one question.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.QUESTIONS)}
              color="secondaryNoBackground"
            >
              Add Them Here
            </Button>
          </li>
        )}
        {CUSTOM_SURVEY_TYPES.includes(survey.type) ? (
          <>
            {questions.shortAnswer
              ? questions.shortAnswer > 0 && (
                  <li>
                    {questions.shortAnswer} <span className={classes.mute}>Short Answer</span>
                  </li>
                )
              : null}
            {questions.longAnswer
              ? questions.longAnswer > 0 && (
                  <li>
                    {questions.longAnswer} <span className={classes.mute}>Long Answer</span>
                  </li>
                )
              : null}
            {questions.multipleChoice
              ? questions.multipleChoice > 0 && (
                  <li>
                    {questions.multipleChoice} <span className={classes.mute}>Multiple Choice</span>
                  </li>
                )
              : null}
            {questions.multiselect
              ? questions.multiselect > 0 && (
                  <li>
                    {questions.multiselect} <span className={classes.mute}>Multiselect</span>
                  </li>
                )
              : null}
            {questions.linearScale
              ? questions.linearScale > 0 && (
                  <li>
                    {questions.linearScale} <span className={classes.mute}>Linear Scale</span>
                  </li>
                )
              : null}
          </>
        ) : (
          <>
            {questions.statements !== null && (
              <li>
                {questions.statements}{' '}
                <span className={classes.mute}>
                  {survey.type === SurveyTypeEnum.TI ? 'Trust Index' : 'Statements'}
                </span>
              </li>
            )}
            <li>
              {questions.demographic} <span className={classes.mute}>Demographic</span>
            </li>
            <li>
              {questions.openEnded} <span className={classes.mute}>Open-Ended</span>
            </li>
            {questions.npsQuestions && questions.npsQuestions > 0 && (
              <li>
                {questions.npsQuestions}{' '}
                <span className={classes.mute}>{getNpsAbbreviation(survey.productType)}</span>
              </li>
            )}
            <li>
              {questions.custom} <span className={classes.mute}>Custom</span>
            </li>
          </>
        )}
        {errors.EMPTY_QUESTIONS && (
          <li className={classes.error}>
            Some questions are missing text.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.QUESTIONS)}
              color="secondaryNoBackground"
            >
              Complete Them Here
            </Button>
          </li>
        )}
        {warnings.DUPLICATE_QUESTIONS && (
          <li className={classes.error}>
            Some questions are duplicates.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.QUESTIONS)}
              color="secondaryNoBackground"
            >
              Fix Them Now
            </Button>
          </li>
        )}

        {warnings.DUPLICATE_QUESTIONS && (
          <li className={classes.error}>
            Some questions are duplicates.{' '}
            <Button
              noMargins
              onClick={() => goToStep(WizardStepsEnum.QUESTIONS)}
              color="secondaryNoBackground"
            >
              Fix Them Now
            </Button>
          </li>
        )}
        {!CUSTOM_SURVEY_TYPES.includes(survey.type) && errors.NO_LOCS_SELECTED && (
          <li className={classes.error}>
            No levels of care have been selected.{' '}
            <NavLink to={URLS.ORG_SETTINGS.GENERAL}>Select them now</NavLink>
          </li>
        )}
        {settings && settings.missingServices.length > 0 && (
          <li className={errors.MISSING_STANDARD_SERVICES ? classes.error : classes.warning}>
            To be eligible for U.S. News, you must enable all standard services. The following
            services are not enabled or are missing a level of care:{' '}
            {settings.missingServices.join(', ')}.&nbsp;
            <Button
              className={classes.servicesModalButton}
              onClick={() => setShowServicesModal(true)}
              color="secondaryNoBackground"
            >
              Update Services Here
            </Button>
          </li>
        )}
        {showServicesModal && (
          <Dialog onClose={() => setShowServicesModal(false)}>
            <ServiceAreasTab
              onSave={async () => {
                await onRefetch()
                setShowServicesModal(false)
              }}
            />
          </Dialog>
        )}
        {survey.productType !== SurveyProductTypeEnum.RESIDENT && (
          <>
            {!CUSTOM_SURVEY_TYPES.includes(survey.type) && settings && (
              <>
                <li>
                  <Tooltip title={settings.locs?.join(', ') || ''}>
                    <div>
                      <span className={classes.mute}>Benchmark Levels of Care:</span>{' '}
                      <span>{settings.locs?.length}</span>
                    </div>
                  </Tooltip>
                </li>
                {errors.NO_DEPTS_SELECTED && (
                  <li className={classes.error}>
                    No depts have been selected.{' '}
                    <NavLink to={URLS.ORG_SETTINGS.GENERAL}>Select them now</NavLink>
                  </li>
                )}
                <li>
                  <Tooltip title={settings.depts?.join(', ') || ''}>
                    <div>
                      <span className={classes.mute}>Benchmark Departments:</span>{' '}
                      <span>{settings.depts?.length}</span>
                    </div>
                  </Tooltip>
                </li>
              </>
            )}
          </>
        )}
        {questions.hasOnlineReviewQuestion && <li>Online Review Prompt Included</li>}
        {questions.locationsCount && (
          <>
            {errors.NO_REVIEW_SITES ? (
              <li className={classes.error}>
                You need to add Google Place IDs to locations. {addGoogleIds}
              </li>
            ) : (
              <>
                <li>
                  {questions.locationsWithOnlineReviewSitesCount} / {questions.locationsCount}{' '}
                  locations have Google Place IDs
                </li>
                {warnings.INCOMPLETE_REVIEW_SITES && (
                  <li className={classes.warning}>
                    Setting up Google Place IDs lets you collect Google Reviews from the survey.{' '}
                    {addGoogleIds}
                  </li>
                )}
              </>
            )}
          </>
        )}
        {questions.personalInfoQuestions && questions.personalInfoQuestions.length > 0 && (
          <PersonalInfoQuestionsSection
            personalInfoQuestions={questions.personalInfoQuestions}
            surveyUuid={survey.uuid}
          />
        )}
      </ul>
    </Section>
  )
}

export default QuestionsSection
