import React, { useContext, useState } from 'react'

import flatten from 'lodash/flatten'

import useStyles from './styles'
import { getCountsSummary } from './utils'

import ActionPlansMgmtTable from 'components/ActionPlansMgmt/ActionPlansMgmtTable'
import {
  CombinedProgressCard,
  ProgressCard,
  UserActivityCard,
} from 'components/ActionPlansMgmt/ProgressCards'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { StoreContext } from 'config/LocalStorage'
import {
  useActionPlansMgmtCountsQuery,
  SurveyProductTypeEnum,
  CurrentUserQuery,
} from 'generated/graphql'
import withErrorHandler from 'HOC/withErrorHandler'
import { AppliedFilters } from 'utils/types'

type Props = { currentUser: CurrentUserQuery['currentUser'] }

const ActionPlansMgmt: React.FC<Props> = ({ currentUser }) => {
  const classes = useStyles()
  const [filters, setFilters] = useState<AppliedFilters>({})
  const {
    store: { selectedFollowFilters, actionPlansSearchQuery },
  } = useContext(StoreContext)
  const variables = {
    filterValueUuids: flatten(Object.values(filters)),
    selectedFollowFilters,
    searchQuery: actionPlansSearchQuery,
  }
  const result = useActionPlansMgmtCountsQuery({ variables })

  if (!currentUser.canManageActionPlans) {
    return <>You don't have access to Manage Action Plans</>
  }
  return (
    <>
      <div className={classes.cards}>
        <UserActivityCard filters={filters} />
        <ResponseHandler {...result}>
          {({ actionPlansCounts }) => {
            const { employee, resident, custom } = actionPlansCounts
            const hasCustomActionItems = Boolean(custom && getCountsSummary(custom).total)
            const hasOneSolutionOnly = !employee || !resident
            if (hasOneSolutionOnly) {
              return (
                <div className={classes.cardsProgress}>
                  <ProgressCard
                    productType={SurveyProductTypeEnum.EMPLOYEE}
                    counts={employee}
                    customStatementCounts={custom}
                    hasCustomActionItems={hasCustomActionItems}
                  />
                  <ProgressCard
                    productType={SurveyProductTypeEnum.RESIDENT}
                    counts={resident}
                    customStatementCounts={custom}
                    hasCustomActionItems={hasCustomActionItems}
                  />
                </div>
              )
            }
            return (
              <CombinedProgressCard
                actionPlansCounts={actionPlansCounts}
                hasCustomActionItems={hasCustomActionItems}
              />
            )
          }}
        </ResponseHandler>
      </div>
      <ActionPlansMgmtTable currentUser={currentUser} filters={filters} setFilters={setFilters} />
    </>
  )
}

export default withErrorHandler(ActionPlansMgmt)
