import React from 'react'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import UpdateUserDialog from 'components/Settings/Team/Users/UpdateUserDialog'
import { UserData } from 'components/Settings/Team/Users/Users'
import { CurrentUserQuery, GroupNode, useSettingsValidGroupsQuery } from 'generated/graphql'
import { SettingsUser } from 'utils/types'

// Populate user groups with the groups from `ValidGroups` query because they contain
// all the nested properties which we avoid querying on the users table.
const transformUserGroups = (groups: GroupNode[], user: UserData): UserData => {
  const userGroupUuids = user.groups.map(g => g.uuid)
  const userGroups = groups.filter(g => userGroupUuids.includes(g.uuid))
  return { ...user, groups: userGroups as GroupNode[] }
}

type Props = {
  onSubmit(user: SettingsUser, message?: string): Promise<boolean>
  onClose(): void
  editUser?: UserData
  step?: string
  currentUser: CurrentUserQuery['currentUser']
}

const UpdateUserContainer: React.FC<Props> = ({
  onSubmit,
  onClose,
  editUser,
  step,
  currentUser,
}) => {
  const settingsValidGroupsDocumentResult = useSettingsValidGroupsQuery()

  return (
    <ResponseHandler {...settingsValidGroupsDocumentResult}>
      {({ validGroups }) => {
        return (
          <UpdateUserDialog
            groups={(validGroups as GroupNode[]) || []}
            onSubmit={onSubmit}
            onClose={onClose}
            editUser={editUser && transformUserGroups(validGroups as GroupNode[], editUser)}
            step={step}
            currentUser={currentUser}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default UpdateUserContainer
