import React from 'react'

import { DialogContent, makeStyles } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import CheckboxDropdown from 'components/Blocks/Dropdowns/CheckboxDropdown'
import { SettingsGroupFragment } from 'generated/graphql'
import { SettingsUser } from 'utils/types'

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    marginBottom: spacing(2),
    display: 'flex',
    '& >div': {
      marginRight: spacing(2),
    },
  },
  dropdown: {
    display: 'block',
  },
}))

type Props = {
  groups: SettingsGroupFragment[]
  onSubmit(user: SettingsUser): void
  updateUser(arg: Partial<SettingsUser>): void
  user: SettingsUser
  submitLabel: string
  onClose(): void
  isSubmitting: boolean
}

const UserInfoSettings: React.FC<Props> = ({
  onSubmit,
  onClose,
  groups,
  user,
  updateUser,
  submitLabel,
  isSubmitting,
}) => {
  const classes = useStyles()
  const submitDisabled = !user.firstName || !user.lastName || !user.email || !user.groups.length
  return (
    <ValidatorForm instantValidate={false} onSubmit={() => onSubmit(user)}>
      <DialogContent dividers>
        <div className={classes.row}>
          <TextValidator
            fullWidth
            id="firstName"
            name="firstName"
            type="text"
            value={user.firstName}
            label="First Name*"
            onChange={e => updateUser({ firstName: (e.target as HTMLInputElement).value })}
            validators={['required']}
            errorMessages={['First Name is required']}
          />
          <TextValidator
            fullWidth
            id="lastName"
            name="lastName"
            type="text"
            value={user.lastName}
            label="Last Name*"
            onChange={e => updateUser({ lastName: (e.target as HTMLInputElement).value })}
            validators={['required']}
            errorMessages={['Last Name is required']}
          />
        </div>
        <div className={classes.row}>
          <TextValidator
            fullWidth
            id="email"
            name="email"
            type="text"
            value={user.email}
            label="Email*"
            onChange={e => updateUser({ email: (e.target as HTMLInputElement).value })}
            validators={['required', 'isEmail']}
            errorMessages={['Email Address is required', `Valid email address required`]}
          />
          <TextValidator
            fullWidth
            id="title"
            name="title"
            type="text"
            value={user.title}
            label="Job Title"
            onChange={e => updateUser({ title: (e.target as HTMLInputElement).value })}
          />
        </div>
        <CheckboxDropdown
          id="selectGroups"
          label="Groups*"
          containerClassName={classes.dropdown}
          width={210}
          menuItems={groups.map(g => ({ value: g.uuid, text: g.name }))}
          selectedItems={user.groups.map(g => g.uuid)}
          onChange={(groupUuids: string[]) => {
            updateUser({ groups: groups.filter(g => groupUuids.includes(g.uuid || '')) })
          }}
        />
      </DialogContent>
      <ActionDialogButtons
        isSubmitting={isSubmitting}
        autoFocusSubmit
        submitDisabled={submitDisabled}
        onClose={onClose}
        submitButtonType="submit"
        submitButtonText={submitLabel}
      />
    </ValidatorForm>
  )
}

export default UserInfoSettings
