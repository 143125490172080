import React, { useContext } from 'react'

import Typography from '@material-ui/core/Typography'
import { NavLink } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsTabProps, InsightsSurvey } from 'components/Insights/InsightsTypes'
import KeyDemographics from 'components/Insights/ResidentSnapshot/KeyDemographicsCard'
import LonelinessCard from 'components/Insights/ResidentSnapshot/LonelinessCard'
import ResidentResponseRateCard from 'components/Insights/ResidentSnapshot/ResidentResponseRateCard'
import DetailedBreakdownCard from 'components/Insights/Snapshot/DetailedBreakdownCard'
import ResponseRateCard from 'components/Insights/Snapshot/ResponseRateCard'
import StatementsContainer from 'components/Insights/Statements/StatementsContainer'
import TTDetailedBreakdownCard from 'components/Insights/TimeTrending/TTDetailedBreakdownCard'
import TTIndexScoreResponseCard from 'components/Insights/TimeTrending/TTIndexScoreResponseCard'
import TTKeyDemographicsCard from 'components/Insights/TimeTrending/TTKeyDemographicsCard'
import TTLonelinessCard from 'components/Insights/TimeTrending/TTLonelinessCard'
import TTScatterAndTableCard from 'components/Insights/TimeTrending/TTScatterAndTableCard'
import TTSnapshotStatementsCard from 'components/Insights/TimeTrending/TTSnapshotStatementsCard'
import { TimeTrendingChartKey, StoreContext, TimeTrendingSurveysKey } from 'config/LocalStorage'
import KeyStatementContainer from 'containers/Survey/Wizard/Steps/Summary/KeyStatementContainer'
import {
  useInsightsResidentSnapshotOverviewQuery,
  useInsightsStatementsQuery,
  InsightsModulesEnum,
  SurveyTypeEnum,
} from 'generated/graphql'
import { RESPONSE_TYPES as RT, SURVEY_TYPE_TO_KEY_STATEMENT } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

export type PulseSurvey = Omit<InsightsSurvey, 'type'> & {
  type: SurveyTypeEnum.PULSE | SurveyTypeEnum.RESIDENT_PULSE
}

const PulseSnapshot: React.FC<Omit<InsightsTabProps, 'survey'> & {
  survey: PulseSurvey
}> = props => {
  const {
    currentUser,
    survey,
    responseRate,
    filters,
    solution,
    benchmark,
    availableSurveys,
    timeTrendingType,
  } = props
  // TimeTrending Keys
  const {
    indexScoreChartKey,
    breakdownChartKey,
    statementsChartKey,
    statementsSurveysKey,
    keyDemographicsChartKey,
  } = {
    [SurveyTypeEnum.PULSE]: {
      indexScoreChartKey: TimeTrendingChartKey.EMPLOYEE_INDEX_SCORE,
      breakdownChartKey: TimeTrendingChartKey.EMPLOYEE_DETAILED_BREAKDOWN,
      statementsChartKey: TimeTrendingChartKey.EMPLOYEE_SNAPSHOT_STATEMENTS,
      statementsSurveysKey: TimeTrendingSurveysKey.EMPLOYEE_SNAPSHOT_STATEMENTS,
      keyDemographicsChartKey: TimeTrendingChartKey.EMPLOYEE_KEY_DEMOGRAPHICS,
    },
    [SurveyTypeEnum.RESIDENT_PULSE]: {
      indexScoreChartKey: TimeTrendingChartKey.RESIDENT_INDEX_SCORE,
      breakdownChartKey: TimeTrendingChartKey.RESIDENT_DETAILED_BREAKDOWN,
      statementsChartKey: TimeTrendingChartKey.RESIDENT_SNAPSHOT_STATEMENTS,
      statementsSurveysKey: TimeTrendingSurveysKey.RESIDENT_SNAPSHOT_STATEMENTS,
      keyDemographicsChartKey: TimeTrendingChartKey.RESIDENT_KEY_DEMOGRAPHICS,
    },
  }[survey.type]
  const {
    store: {
      [indexScoreChartKey]: showIndexScoreTimeTrending,
      [breakdownChartKey]: showDetailedBreakdownTimeTrending,
      [statementsChartKey]: showStatementsTimeTrending,
      [keyDemographicsChartKey]: showKeyDemographicsTimeTrending,
      [TimeTrendingChartKey.RESIDENT_LONELINESS]: showLonelinessTimeTrending,
    },
  } = useContext(StoreContext)
  const classes = useInsightsStyles()
  const keyStatementCode = SURVEY_TYPE_TO_KEY_STATEMENT[survey.type]
  const result = useInsightsStatementsQuery({
    variables: {
      // We know this exists but can't conditionally render a hook
      surveyUuid: String(survey.associatedSurvey?.uuid),
      limit: 1,
      filters,
      benchmark,
      statementCodes: [keyStatementCode],
    },
  })
  const overviewResult = useInsightsResidentSnapshotOverviewQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      benchmark,
      skipNpsScore: !survey.includesNpsQuestion,
    },
  })
  const hasTimeTrending = Boolean(timeTrendingType)
  return (
    <ResponseHandler {...result}>
      {data => {
        if (!survey.associatedSurvey) return <div />
        return (
          <>
            {showIndexScoreTimeTrending ? (
              <div className={classes.fullRow} id="tt-index-score-snap">
                <TTIndexScoreResponseCard {...props} />
              </div>
            ) : (
              <div className={classes.halfRow}>
                <KeyStatementContainer
                  surveyUuid={survey.uuid}
                  associatedSurveyUuid={survey.associatedSurvey.uuid}
                  lessThanMin={responseRate.finished < survey.minShowableResults}
                  filters={filters}
                  keyStatementCode={keyStatementCode}
                  minShowableResults={survey.minShowableResults}
                />
                {survey.type === SurveyTypeEnum.PULSE && (
                  <ResponseRateCard
                    {...props}
                    {...responseRate}
                    hasTimeTrending={hasTimeTrending}
                  />
                )}
                {survey.type === SurveyTypeEnum.RESIDENT_PULSE && (
                  <ResidentResponseRateCard
                    {...props}
                    survey={survey}
                    hasTimeTrending={hasTimeTrending}
                    isDefaultClientBasedResponseRateReport={
                      survey.isDefaultClientBasedResponseRateReport
                    }
                    minShowableResults={survey.minShowableResults}
                  />
                )}
              </div>
            )}
            <>
              {showDetailedBreakdownTimeTrending ? (
                <>
                  <TTDetailedBreakdownCard {...props} />
                  <TTScatterAndTableCard
                    {...props}
                    companyOverallPositive={Math.round(data.statements[0].positive)}
                  />
                </>
              ) : (
                <ResponseHandler {...overviewResult}>
                  {({ insightsOverallIndex }) => {
                    const overallPositive = Math.round(insightsOverallIndex.positive)
                    const benchmarkPositive = Math.round(
                      insightsOverallIndex.benchmarkPositive || 0,
                    )
                    return (
                      <DetailedBreakdownCard
                        hasTimeTrending={hasTimeTrending}
                        {...props}
                        title="Key Statement Scores By"
                        useFilterNameSuffixInTitle
                        description="See how key statement scores varied across your organization."
                        tooltip="This chart shows the key statement score across your organization."
                        overallPositiveScore={overallPositive}
                        benchmarkPositive={benchmarkPositive}
                        statementCode={keyStatementCode}
                      />
                    )
                  }}
                </ResponseHandler>
              )}
              {showStatementsTimeTrending ? (
                <TTSnapshotStatementsCard {...props} />
              ) : (
                <>
                  <StatementsContainer
                    survey={survey}
                    filters={filters}
                    benchmark={benchmark}
                    availableSurveys={availableSurveys}
                    comparisonFilters={[]}
                    selectedResponseTypes={[RT.all]}
                    limit={5}
                    timeTrendingChartKey={statementsChartKey}
                    timeTrendingSurveysKey={statementsSurveysKey}
                    timeTrendingType={timeTrendingType}
                  />
                </>
              )}
            </>
            {solution.insightsComments && (
              <div className={classes.fullRow}>
                <Typography>
                  Go to&nbsp;
                  <NavLink
                    to={getInsightsPage(
                      survey.uuid,
                      InsightsModulesEnum.COMMENTS,
                      survey.productType,
                    )}
                  >
                    &quot;Comments&quot;
                  </NavLink>
                  &nbsp;to see answers to your open ended questions.
                </Typography>
              </div>
            )}
            {props.solution.insightsKeyDemographics &&
              (showKeyDemographicsTimeTrending ? (
                <TTKeyDemographicsCard {...props} />
              ) : (
                <KeyDemographics
                  survey={survey}
                  filters={filters}
                  surveyProductType={survey.productType}
                  benchmark={benchmark}
                  hasTimeTrending={hasTimeTrending}
                />
              ))}
            {currentUser.canAccessLonelinessResults && survey.includesLonelinessQuestions && (
              <>
                {showLonelinessTimeTrending ? (
                  <TTLonelinessCard {...props} />
                ) : (
                  <LonelinessCard
                    surveyUuid={survey.uuid}
                    minShowableResults={survey.minShowableResults}
                    filters={filters}
                    hasTimeTrending={hasTimeTrending}
                  />
                )}
              </>
            )}
          </>
        )
      }}
    </ResponseHandler>
  )
}

export default PulseSnapshot
