import React, { useState } from 'react'

import flatten from 'lodash/flatten'
import snakeCase from 'lodash/snakeCase'
import { v4 as uuidv4 } from 'uuid'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import Users, { Status } from 'components/Settings/Team/Users/Users'
import {
  UserNode,
  useSettingsBulkResetPasswordMutation,
  useSettingsToggleIsActiveMutation,
  useSettingsUpdateUserMutation,
  useSettingsBulkDeleteUsersMutation,
  useSettingsBulkUpdateUserPermissionsMutation,
  useSettingsUsersQuery,
  CurrentUserQuery,
  SettingsUsersDocument,
  FilterTypePermissionInput,
  UserNodeEdge,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'
import { SettingsUser } from 'utils/types'

type Props = {
  currentUser: CurrentUserQuery['currentUser']
}

const PAGE_SIZE = 10

const UsersContainer: React.FC<Props> = ({ currentUser }) => {
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({})
  const [searchQuery, setSearchQuery] = useState('')
  const [statuses, setStatuses] = useState<Status[]>([Status.ACTIVE, Status.INVITED])
  const [sortBy, setSortBy] = useState('')
  const [sortDescending, setSortDescending] = useState(false)
  // Used to refresh the state of the `Users` component.
  const [usersTableKey, updateUsersTableKey] = useState(uuidv4())

  const [toggleIsActive] = useSettingsToggleIsActiveMutation()
  const [updateUser] = useSettingsUpdateUserMutation()
  const [
    bulkResetPassword,
    { loading: bulkResetPasswordIsLoading },
  ] = useSettingsBulkResetPasswordMutation()
  const [bulkUpdateUserPermissions] = useSettingsBulkUpdateUserPermissionsMutation()
  const [bulkDeleteUsers] = useSettingsBulkDeleteUsersMutation()

  const handleToggleIsActive = async (user: UserNode) => {
    const result = await toggleIsActive({
      variables: { uuid: user.id },
    })

    return handleMutationResponse(result.data?.toggleIsActive?.errors, 'Successfully saved group')
  }

  const handleResetPassword = async (uuidList: string[]) => {
    if (!uuidList.length) return
    const result = await bulkResetPassword({
      variables: { uuidList },
    })
    handleMutationResponse(result.data?.bulkResetPassword?.errors, 'Password reset emails sent')
  }

  const handleBulkDeleteUsers = async (userUuids: string[]) => {
    if (!userUuids.length) return

    const result = await bulkDeleteUsers({
      variables: { userUuids },
      async update() {
        await settingsUsersQueryResult.client.resetStore()
        await settingsUsersQueryResult.fetchMore({ variables: getUsersQueryVariables() })
      },
    })
    updateUsersTableKey(uuidv4())
    handleMutationResponse(
      result.data?.bulkDeleteUsers?.errors,
      `Deleted ${userUuids.length} users`,
    )
  }

  const handleBulkUpdateUserPermissions = async (variables: {
    uuidList: string[]
    filterTypes: FilterTypePermissionInput[]
  }): Promise<boolean> => {
    const result = await bulkUpdateUserPermissions({
      variables,
      // Refetch users because the updated filters may affect which users are returned
      refetchQueries: [
        {
          query: SettingsUsersDocument,
          variables: getUsersQueryVariables(),
        },
      ],
    })
    updateUsersTableKey(uuidv4())
    return handleMutationResponse(
      result.data?.bulkUpdateUserPermissions?.errors,
      'Successfully updated users permissions',
    )
  }

  const handleUpdateUser = async (
    variables: SettingsUser,
    successMessage = 'User updated',
  ): Promise<boolean> => {
    const result = await updateUser({
      variables: {
        ...variables,
        uuid: variables.id,
        groups: variables.groups.map((g: SettingsUser['groups'][number]) => g.uuid),
        filterTypes: Object.values<SettingsUser['filterTypesMap'][string]>(
          variables.filterTypesMap,
        ).map((f: SettingsUser['filterTypesMap'][string]) => ({
          uuid: f.filterTypeUuid,
          accessToAll: Boolean(f.accessToAll),
          values: f.filterValues.map(
            (fv: SettingsUser['filterTypesMap'][string]['filterValues'][number]) => ({
              uuid: fv.uuid,
            }),
          ),
        })),
      },
      async update() {
        await settingsUsersQueryResult.client.resetStore()
        await settingsUsersQueryResult.fetchMore({ variables: getUsersQueryVariables() })
      },
    })
    updateUsersTableKey(uuidv4())
    return handleMutationResponse(result.data?.updateUser?.errors, successMessage)
  }

  const updateFilters = (filterTypeUuid: string, filterValues: string[]) => {
    setFilters({ ...filters, [filterTypeUuid]: filterValues })
  }

  const getQueryFilters = () => flatten(Object.values(filters))

  const getUsersQueryVariables = () => ({
    filterValueUuids: getQueryFilters(),
    search: searchQuery,
    statuses,
    pageSize: PAGE_SIZE,
    sortBy,
    sortDescending,
  })

  const settingsUsersQueryResult = useSettingsUsersQuery({
    variables: getUsersQueryVariables(),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <ResponseHandler {...settingsUsersQueryResult}>
      {({ users: userNodes }, { loading: responseLoading }) => {
        let users: UserNode[] = []
        if (userNodes.edges && userNodes.edges.length) {
          users = userNodes.edges.map(e => {
            return (e as UserNodeEdge).node as UserNode
          })
        }

        return (
          <Users
            key={usersTableKey}
            onLoadMore={async () => {
              await settingsUsersQueryResult.fetchMore({
                variables: {
                  cursor: userNodes.pageInfo.endCursor,
                  ...getUsersQueryVariables(),
                },
              })
            }}
            currentUser={currentUser}
            users={users}
            numTotalUsers={userNodes.totalCount}
            hasNextPage={userNodes.pageInfo.hasNextPage}
            pageSize={PAGE_SIZE}
            selectedFilters={filters}
            updateSelectedFilters={updateFilters}
            updateSearchQuery={setSearchQuery}
            statuses={statuses}
            updateStatuses={setStatuses}
            updateSort={sortList => {
              setSortDescending(sortList[0].desc)
              setSortBy(snakeCase(sortList[0].id))
            }}
            searchQuery={searchQuery}
            loading={Boolean(responseLoading) || bulkResetPasswordIsLoading}
            handleToggleIsActive={handleToggleIsActive}
            handleResetPassword={handleResetPassword}
            handleUpdateUser={handleUpdateUser}
            handleBulkUpdateUserPermissions={handleBulkUpdateUserPermissions}
            handleBulkDeleteUsers={handleBulkDeleteUsers}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default UsersContainer
