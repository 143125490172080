import React, { ReactElement } from 'react'

import { Tooltip } from '@material-ui/core'

import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import { getDisabledTabTooltip } from 'components/Insights/insightsTabs'
import {
  SurveyProductTypeEnum,
  TakeActionStepsEnum,
  OrganizationSolutionFragment,
  OrganizationResidentSolutionFragment,
  InsightsModulesEnum,
  SurveyTypeEnum,
} from 'generated/graphql'
import { URLS } from 'utils/constants'
import { getInsightsPage } from 'utils/insightsUtils'

const Tab: React.FC<{
  to: string
  disabledTabTooltip?: string
  name: string
}> = ({ to, disabledTabTooltip, name }) => {
  return (
    <Tooltip title={disabledTabTooltip || ''}>
      <ExternalNavLink to={to} label={name} />
    </Tooltip>
  )
}

export const EMPLOYEE_HEADERS = {
  UNDERSTAND:
    'A great workplace is one where you TRUST the people you work for; have PRIDE in what you do; and ENJOY the people you work with. We measured how your employees feel across the dimensions that build trust.\n\nMake sure that you understand where you are doing well and where your gaps are, which employees are having a worse experience compared to others, and what employees would like for you to work on.',
  COMMUNICATE:
    'You’ll need to discuss results and what to do with them with your leadership team -- they’ll need to make changes if you want your employee experience - and your scores - to improve. You’ll also need to close the loop and follow-up with employees to thank them for their feedback and let them know what you’re going to do with it.',
  IMPROVE:
    'Now comes the real work, making changes to improve your workplace experience! Improving your workplace culture can have a big impact, from reducing turnover and callouts to improving quality of care. Not sure where to get started? We’ll walk you through what we think you should work on based on your employee’s feedback in your survey.',
}
export const RESIDENT_HEADERS = {
  UNDERSTAND:
    'A great community is one where residents and families TRUST the management and staff; perceive QUALITY OF LIFE to be high; and ENJOY the company of other residents. We measured how your customers feel in these areas.\n\nMake sure that you understand where you are doing well and where your gaps are, which residents or families are having a worse experience compared to others, and what they’d like for you to work on.',
  COMMUNICATE:
    'You’ll need to discuss results and what to do with them with your leadership team -- they’ll need to make changes if you want your customer experience - and your scores - to improve. You’ll also need to close the loop and follow-up with your residents, family members, and other contacts to thank them for their feedback and let them know what you’re going to do with it.',
  IMPROVE:
    'Now comes the real work, making changes to improve your resident and customer engagement! Improving your community can have a big impact, from reducing move-outs, increasing referrals and move-ins, and improving quality of care. Not sure where to get started? We’ll walk you through what we think you should work on based on your customer’s feedback.',
}

export const getEmployeeSteps = (
  surveyUuid: string,
  surveyType: SurveyTypeEnum,
  surveyIncludesTestimonials: boolean,
  solution: OrganizationSolutionFragment,
): { [key: string]: [TakeActionStepsEnum, string | ReactElement][] } => {
  const args = {
    solution,
    surveyType,
    includesTestimonials: surveyIncludesTestimonials,
    hasConfidentialResults: false,
  }
  const getTab = (tab: InsightsModulesEnum, name: string) => (
    <Tab
      to={getInsightsPage(surveyUuid, tab, SurveyProductTypeEnum.EMPLOYEE)}
      disabledTabTooltip={getDisabledTabTooltip(tab, args)}
      name={name}
    />
  )

  return {
    UNDERSTAND: [
      [
        TakeActionStepsEnum.UNDERSTAND_1,
        <>
          Look at the {getTab(InsightsModulesEnum.SNAPSHOTS, 'Snapshots Tab')} - look for your top
          statements and areas for opportunity.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_2,
        'Put the results in context with what else has been happening at your organization. Is there anything that’s a surprise? That isn’t?',
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_3,
        <>
          Figure out what may be driving these strengths/opportunities - look at the{' '}
          {getTab(InsightsModulesEnum.COMMENTS, 'Comments Tab')} or{' '}
          {getTab(InsightsModulesEnum.STATEMENTS, 'Statements Tab')} side-by-side{' '}
          {getTab(InsightsModulesEnum.COMPARISONS, 'comparisons')} to see what departments/managers
          or demographic groups are lower.{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/How-to-use-Filters"
            label="Filters"
          />{' '}
          allow you to drill down to slice and dice your data.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_4,
        <>
          Don’t forget - You can use{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/How-To-Use-Change-Benchmarks"
            label="benchmarks"
          />{' '}
          to compare your scores to other parts of your organization or to your industry peers.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_5,
        <>
          Read through your {getTab(InsightsModulesEnum.COMMENTS, 'Comments')} - filter by
          department to understand what’s going on in different parts of your organization.
        </>,
      ],
    ],
    COMMUNICATE: [
      [
        TakeActionStepsEnum.COMMUNICATE_1,
        'Your leadership team sets the tone for your workplace culture. Review the results with this team first and discuss clear goals for the overall organization and each person.',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_2,
        'Create a plan to communicate results to all employees, customers, and other stakeholders (e.g. your advisory council or Board of Directors).',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_3,
        'Try a cascaded approach to dissemination the results: give supervisors and managers the data that pertains to their area. Make sure each person has a coach/mentor to understand how to read the results and build on strengths/create a good action plan.',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_4,
        `Communicate to all employees. The response should include these themes:\n
•  “Thank you for your feedback.”
•  “We heard you.”
•  “These are the themes we heard.”
•  “This is what we are going to do next.
   We will share more details soon.”`,
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_5,
        'Make sure leaders know this is an opportunity, and it’s important to not try to figure out which employees gave low scores or to retaliate in any way.',
      ],
    ],
    IMPROVE: [
      [
        TakeActionStepsEnum.IMPROVE_1,
        <>
          Look at the suggested statements to work on in your{' '}
          <ExternalNavLink to={URLS.ACTION_PLAN} label="action plan" />.
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_2,
        'Don’t over-commit! Focusing on 1-2 statements is more likely to lead to success.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_3,
        'Choose statements that potentially address multiple opportunities. Ensure that it’s realistic to take actions in the areas you choose.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_4,
        <>
          Want some more ideas to get started? Learn more about different approaches{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/Different-Approaches-to-Action-Planning"
            label="here"
          />
          .
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_5,
        'Look to opportunities to build on what you’re currently doing well or other initiatives already in progress.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_6,
        'Think about who should be involved to improve in this area - managers, supervisors, frontline employees, residents, families?',
      ],
      [
        TakeActionStepsEnum.IMPROVE_7,
        'Remember what employees are saying is most important to them.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_8,
        'Define the team that will be involved in working on your action plan. Set regular communication/ meetings to manage progress.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_9,
        <>
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/Adding-Action-Items"
            label="Add action items"
          />{' '}
          under each statement to break out exactly what needs to be done.
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_10,
        'For each statement add figure out what changes you’d like to make to try to improve.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_11,
        'Set due dates and owners for each action item to help track when they need to be done by and who is taking point.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_12,
        'Plan to check in on progress with your action plan team monthly.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_13,
        'Communicate changes you’ve made and your progress back to your employees so they know their feedback led to change.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_14,
        'Update your plan as you work on it to track your progress. When your next survey closes, you’ll be able to see where and how you improved.',
      ],
    ],
  }
}

export const getResidentSteps = (
  surveyUuid: string,
  surveyType: SurveyTypeEnum,
  surveyIncludesTestimonials: boolean,
  solution: OrganizationResidentSolutionFragment,
): { [key: string]: [TakeActionStepsEnum, string | ReactElement][] } => {
  const args = {
    solution,
    surveyType,
    includesTestimonials: surveyIncludesTestimonials,
    hasConfidentialResults: false,
  }
  const getTab = (tab: InsightsModulesEnum, name: string) => (
    <Tab
      to={getInsightsPage(surveyUuid, tab, SurveyProductTypeEnum.RESIDENT)}
      disabledTabTooltip={getDisabledTabTooltip(tab, args)}
      name={name}
    />
  )
  return {
    UNDERSTAND: [
      [
        TakeActionStepsEnum.UNDERSTAND_1,
        <>
          Look at the {getTab(InsightsModulesEnum.SNAPSHOTS, 'Snapshots Tab')} - look for your top
          statements and areas for opportunity.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_2,
        'Put the results in context with what else has been happening at your organization. Is there anything that’s a surprise? That isn’t?',
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_3,
        <>
          Figure out what may be driving these strengths/opportunities - look at the{' '}
          {getTab(InsightsModulesEnum.COMPARISONS, 'Comparison Tab')} or{' '}
          {getTab(InsightsModulesEnum.STATEMENTS, 'Statements Tab')} side-by-side comparisons to see
          what departments/managers or demographic groups are lower.{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/How-to-use-Filters"
            label="Filters"
          />{' '}
          allow you to drill down to slice and dice your data.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_4,
        <>
          Don’t forget - You can use{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/How-To-Use-Change-Benchmarks"
            label="benchmarks"
          />{' '}
          to compare your scores to other parts of your organization or to your industry peers.
        </>,
      ],
      [
        TakeActionStepsEnum.UNDERSTAND_5,
        <>
          Read through your {getTab(InsightsModulesEnum.COMMENTS, 'Comments')} - focus on service
          questions and search by keyword to understand what’s going on in different parts of your
          organization.
        </>,
      ],
    ],
    COMMUNICATE: [
      [
        TakeActionStepsEnum.COMMUNICATE_1,
        'Your leadership team makes things happen. Review the results with this team first and discuss clear goals for the overall organization and each person.',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_2,
        'Create a plan to communicate results to all stakeholders (e.g., employees, customers, your resident advisory council, owners and/or Board of Directors).',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_3,
        'Try a cascaded approach to dissemination the results: give supervisors and managers the data that pertains to their area. Make sure each person has a coach/mentor to understand how to read the results and build on strengths/create a good action plan.',
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_4,
        `Communicate to all stakeholders. The response should include these themes:\n
•  “Thank you for your feedback.”
•  “We heard you.” / “We listen to our customers.”
•  “These are the themes we heard.”
•  “This is what we are going to do next.
   We will share more details soon.”`,
      ],
      [
        TakeActionStepsEnum.COMMUNICATE_5,
        'Make sure leaders know this is an opportunity, and it’s important to not try to figure out which employees gave low scores or to retaliate in any way.',
      ],
    ],
    IMPROVE: [
      [
        TakeActionStepsEnum.IMPROVE_1,
        <>
          Look at the suggested statements to work on in your{' '}
          <ExternalNavLink to={URLS.ACTION_PLAN} label="action plan" />.
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_2,
        'Don’t over-commit! Focusing on 1-2 statements is more likely to lead to success.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_3,
        'Choose statements that potentially address multiple opportunities. Ensure that it’s realistic to take actions in the areas you choose.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_4,
        <>
          Want some more ideas to get started? Learn more about different approaches{' '}
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/Different-Approaches-to-Action-Planning"
            label="here"
          />
          .
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_5,
        'Look to opportunities to build on what you’re currently doing well or other initiatives already in progress.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_6,
        'Think about who should be involved to improve in this area - managers, supervisors, frontline employees, residents, families?',
      ],
      [
        TakeActionStepsEnum.IMPROVE_7,
        'Keep in mind what customers are saying is most important to them (e.g. Dining).',
      ],
      [
        TakeActionStepsEnum.IMPROVE_8,
        'Define the team that will be involved in working on your action plan. Set regular communication/ meetings to manage progress.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_9,
        <>
          <ExternalNavLink
            to="https://activatedinsights.force.com/help/s/article/Adding-Action-Items"
            label="Add action items"
          />{' '}
          under each statement to break out exactly what needs to be done.
        </>,
      ],
      [
        TakeActionStepsEnum.IMPROVE_10,
        'For each statement add figure out what changes you’d like to make to try to improve.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_11,
        'Set due dates and owners for each action item to help track when they need to be done by and who is taking point.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_12,
        'Plan to check in on progress with your action plan team monthly.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_13,
        'Communicate changes you’ve made and your progress back to your employees so they know their feedback led to change.',
      ],
      [
        TakeActionStepsEnum.IMPROVE_14,
        'Update your plan as you work on it to track your progress. When your next survey closes, you’ll be able to see where and how you improved.',
      ],
    ],
  }
}
