import React, { useState, useEffect, ReactElement, memo } from 'react'

import { makeStyles, IconButton } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/ArrowDropDown'
import ArrowUp from '@material-ui/icons/ArrowDropUp'
import { EChartOption } from 'echarts'
import isEqual from 'lodash/isEqual'
import remove from 'lodash/remove'
import times from 'lodash/times'

import DashedBlueLine from 'assets/img/echarts/dashed-blue-line.png'
import SolidBlueLine from 'assets/img/echarts/solid-blue-line.png'
import { chartTextStyle } from 'components/Insights/InsightsStyle'
import PrintableEchart from 'components/Insights/Printable/PrintableEchart'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import { colors } from 'shared/theme'
import { reverse, splitTextByLineLength } from 'utils'
import { MIN_SHOWABLE_RESULTS_CODE, MIN_SHOWABLE_RESULTS } from 'utils/constants'
import { benchmarkLine, formatLongAxisLabel, surveysTooltip } from 'utils/echartsHelpers'
import { scoreToColor } from 'utils/insightsUtils'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  pagination: {
    /** Use absolute positioning so we can fake that the pagination is on the chart */
    position: 'absolute',
    bottom: 18,
    width: '28%',
    borderTop: `1px solid ${theme.palette.common.navy15}`,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& >button': {
      padding: 0,
      color: theme.palette.common.secondary,
    },
  },
}))
export const DARK_PURPLE = 'rgb(67, 57, 125)'
export const CHILD_COLORS = [DARK_PURPLE, colors.purple]
export const BENCHMARK_LINE_TYPES = [DashedBlueLine, SolidBlueLine]
const BENCHMARK_LINE_STYLES = ['dashed', 'solid']

export type Data = {
  score?: number | null
  label?: string | null
  lessThanMin: boolean
  color: string
}
export type TooltipSeries = Array<{
  data: { name: string; value: number; lessThanMin?: boolean }
  axisValue: string
}>
export type ChartDataProps = {
  /**
   * 2D array, where the top level scores are grouped by the child scores.
   * Example:
   * [Dept1: [Location1, Location2], Dept2: [Location1, Location]] represents a graph of
   * yAxis Location1: =>
   *    bar chart for Dept1
   *    bar chart for Dept2
   * yAxis Location2:
   *    bar chart for Dept1
   *    bar chart for Dept2
   *
   * When you only want to show one level, wrap scores in an array.
   */
  chartData: Data[][]
  chartWidth?: string
  chartHeight?: number
  onBarClick?(score: number, label: string): void
  benchmarkData?: Array<{ name: string; score: number }>
  // Legends that match with child scores, displayed after the benchmark legends.
  extraLegends?: Array<{ name: string; color: string; icon?: string }>
  useTopLegends?: boolean
  pageSize?: number
  numXAxisBars?: number
  paginationWidth?: number
  yAxisLabelLength?: number
  axisFontSize?: number
  tooltipFormatter?(series: TooltipSeries): ReactElement | null
  stackBars?: boolean
  barWidth?: number
  showSeriesLabel?: boolean
  xAxisIsPercentage?: boolean
  chartType?: string
  xAxisRange?: number[]
}

// In most cases a bar chart, but can be configured to render a line.
const HorizontalBarChart: React.FC<ChartDataProps> = ({
  chartType = 'bar',
  chartData,
  chartWidth = '72%',
  chartHeight = 400,
  onBarClick,
  benchmarkData,
  extraLegends,
  useTopLegends,
  pageSize: pageSizeInput = 12,
  numXAxisBars = 2,
  paginationWidth = 240,
  yAxisLabelLength = 100,
  axisFontSize = 14,
  barWidth = 16,
  showSeriesLabel = true,
  xAxisIsPercentage = true,
  tooltipFormatter,
  stackBars,
  xAxisRange,
}) => {
  const classes = useStyles()
  const [scrollDataIndex, setScrollDataIndex] = useState(0)
  // When there are multiple levels for each yAxis label, shorten the page size.
  const pageSize = pageSizeInput / chartData.length
  // Reset page to 0 when data changes
  useEffect(() => setScrollDataIndex(0), [])
  const yAxisLabels = chartData[0].map(score => score.label)
  const currentYAxisLabels = yAxisLabels.slice(scrollDataIndex, scrollDataIndex + pageSize)
  const series: Array<EChartOption.Series | null> = []
  // Order in the series is important, because eCharts matches the legend colors by index.
  if (benchmarkData) {
    series.push(
      ...benchmarkData.map((data, idx) => {
        return benchmarkLine({
          ...data,
          width: 2,
          opacity: 0.6,
          value: data.score,
          axis: 'xAxis',
          type: BENCHMARK_LINE_STYLES[idx],
        })
      }),
    )
  }
  series.push(
    ...chartData.map((groupedData, groupIdx) => ({
      name: extraLegends && extraLegends[groupIdx]?.name,
      data: groupedData.slice(scrollDataIndex, scrollDataIndex + pageSize).map(childData => ({
        // Show a mid length bar when rendering a lessThanMin
        value: childData.lessThanMin ? 5 : childData.score,
        name: childData.label,
        itemStyle: { color: childData.color },
        lessThanMin: childData.lessThanMin,
      })),
      stack: stackBars ? 'total' : null,
      type: chartType,
      lineStyle: {
        color: colors.navy,
        type: 'solid',
      },
      barWidth,
      label: {
        show: showSeriesLabel,
        position: 'right',
        formatter: ({ data }: { data: { lessThanMin: Boolean; value: number } }) => {
          return data.lessThanMin ? `<${MIN_SHOWABLE_RESULTS} Responses` : data.value
        },
      },
    })),
  )

  const pageUp = () => {
    // If we would be scrolling below 0, set the index to 0.
    if (scrollDataIndex - pageSize < 0) {
      setScrollDataIndex(0)
    } else {
      setScrollDataIndex(scrollDataIndex - pageSize)
    }
  }
  const pageDown = () => {
    // If the next page would extend beyond the length of data,
    // set the index to the position that shows the last full page of data.
    if (yAxisLabels.length < scrollDataIndex + pageSize * 2) {
      setScrollDataIndex(yAxisLabels.length - pageSize)
    } else {
      setScrollDataIndex(scrollDataIndex + pageSize)
    }
  }
  const sliceSize = Math.min(yAxisLabels.length, scrollDataIndex + pageSize)
  const legendData = []
  const color = []
  if (benchmarkData) {
    legendData.push(
      ...benchmarkData.map((bd, idx) => ({
        name: bd.name,
        icon: `image://${BENCHMARK_LINE_TYPES[idx]}`,
      })),
    )
    // Add navy color for all the legend data, ensuring the extra legends use the appropriate color.
    color.push(...times(legendData.length, () => colors.navy65))
  }
  if (extraLegends) {
    legendData.push(
      ...extraLegends.map(legend => ({ name: legend.name, icon: legend.icon || 'square' })),
    )
    color.push(...extraLegends.map(legend => legend.color))
  }
  const legendPosition = useTopLegends
    ? {
        align: 'right',
        top: 0,
        right: '10%',
      }
    : { bottom: 0 }
  const usePagination = pageSize < chartData[0].length

  return (
    // 27 is the exact height displacement to align the pagination with the bottom of the chart.
    <div
      className={classes.container}
      style={{ height: usePagination ? chartHeight - 27 : chartHeight }}
    >
      <PrintableEchart
        printWidth={600}
        notMerge
        style={{
          width: chartWidth,
          height: chartHeight,
        }}
        opts={{ renderer: 'svg' }} // Useful for selecting DOM elements in UItests
        onEvents={{
          legendscroll: (e: any) => setScrollDataIndex(e.scrollDataIndex),
          click: ({ data: barData }: any) => onBarClick && onBarClick(barData.value, barData.name),
        }}
        option={{
          grid: { top: useTopLegends ? 40 : 0, left: paginationWidth + 10, bottom: 70 },
          title: {
            textStyle: {
              ...chartTextStyle,
              fontWeight: 'normal',
              fontSize: 16,
            },
          },
          textStyle: chartTextStyle,
          xAxis: {
            type: 'value',
            axisTick: { show: false },
            axisLabel: {
              formatter: (value: string) => (xAxisIsPercentage ? `${value}%` : value),
              color: colors.navy65,
            },
            splitNumber: numXAxisBars,
            min: xAxisRange ? xAxisRange[0] : 0,
            max: xAxisRange ? xAxisRange[1] : 100,
          },
          yAxis: {
            type: 'category',
            inverse: true,
            boundaryGap: true,
            // Hide `splitLine` to prevent rendering horizontal grid lines on every split.
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              align: 'left',
              fontSize: axisFontSize,
              color: colors.navy65,
              // up, right, down, left
              padding: [0, 0, 0, -paginationWidth],
              formatter: (label: string) => {
                return formatLongAxisLabel({ label, maxLabelLineLength: yAxisLabelLength })
              },
            },
            data: currentYAxisLabels,
          },
          series,
          legend: [
            {
              data: legendData,
              ...legendPosition,
              textStyle: {
                ...chartTextStyle,
                fontSize: 12,
              },
              itemWidth: 12,
              itemGap: 20,
              width: '100%',
            },
          ],
          color,
          tooltip: tooltipFormatter
            ? {
                confine: true,
                axisPointer: { type: 'none' },
                ...surveysTooltip({}),
                formatter: (tooltipSeries: TooltipSeries) => {
                  return renderEchartTooltip(tooltipFormatter(tooltipSeries))
                },
              }
            : null,
        }}
      />
      {usePagination && (
        <div className={classes.pagination}>
          <IconButton disabled={scrollDataIndex === 0} onClick={pageUp}>
            <ArrowUp />
          </IconButton>
          <span>{`${scrollDataIndex + 1}-${sliceSize} of ${yAxisLabels.length}`}</span>
          <IconButton
            disabled={scrollDataIndex + pageSize >= yAxisLabels.length}
            onClick={pageDown}
          >
            <ArrowDown />
          </IconButton>
        </div>
      )}
    </div>
  )
}

// Prevent rerendering the chart when props haven't changed.
export default memo(HorizontalBarChart, (prevProps: ChartDataProps, nextProps: ChartDataProps) => {
  return (
    isEqual(prevProps.benchmarkData, nextProps.benchmarkData) &&
    isEqual(prevProps.chartData, nextProps.chartData)
  )
})

export enum SortOrder {
  HIGHEST = 'Highest',
  LOWEST = 'Lowest',
}

export const formatChartData = (
  // See chartData definition above.
  chartData: { positive?: number | null; label?: string | null; color?: string }[][],
  sortOrder?: SortOrder,
) => {
  const containsChildScores = chartData.length > 1
  return chartData.map((groupedScores, groupIdx) => {
    let formattedScores = groupedScores.map(score => {
      const lessThanMin = score.positive === MIN_SHOWABLE_RESULTS_CODE
      let color = score.color || ''
      if (!color && score.positive) {
        // Color lessThanMin as grey.
        if (lessThanMin) {
          color = colors.navy65
        } else if (containsChildScores) {
          color = CHILD_COLORS[groupIdx]
        } else {
          color = scoreToColor(score.positive)
        }
      }
      return {
        score: typeof score.positive == 'number' ? Math.round(score.positive) : null,
        label: score.label && splitTextByLineLength(score.label, 30).trim(),
        lessThanMin,
        color,
      }
    })
    if (sortOrder === SortOrder.LOWEST) {
      formattedScores = reverse(formattedScores)
    }
    const lessThanMinScores = remove(formattedScores, (_, childIdx) => {
      // Move lessThanMin scores to the end of the array when every child in the group level is lessThanMin
      return chartData.every(group => group[childIdx].positive === MIN_SHOWABLE_RESULTS_CODE)
    })
    return [...formattedScores, ...lessThanMinScores]
  })
}
