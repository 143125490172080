import React from 'react'

import { useApolloClient } from '@apollo/client'
import { Typography, makeStyles } from '@material-ui/core'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { RouteComponentProps } from 'react-router'

import FormPanel from 'components/Blocks/Accordions/FormPanel'
import RosterUpload from 'components/Blocks/CustomUpload/RosterUpload'
import Page from 'components/Blocks/Layout/Page'
import {
  CurrentUserDocument,
  ImportContactTypesEnum,
  useOrganizationAddContactsUploadMutation,
  useSurveysGenerateUploadUrlMutation,
} from 'generated/graphql'
import emitter from 'shared/authenticated/emitter'
import { URLS } from 'utils/constants'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    paddingBottom: spacing(0),
  },
  description: {
    paddingBottom: spacing(2),
  },
  directions: {
    width: '100%',
    paddingTop: spacing(2),
  },
}))

const ContactsUpload: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()
  const [generateUploadUrl] = useSurveysGenerateUploadUrlMutation()
  const [addContactsUpload] = useOrganizationAddContactsUploadMutation()
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser

  return (
    <Page>
      <BreadcrumbsItem to={URLS.ORG_SETTINGS.CONTACTS_UPLOAD}>Contacts (Upload)</BreadcrumbsItem>
      <>
        {/* Client & Contact Uploads  */}
        {currentUser.canUploadCombinedContacts &&
          currentUser?.organization?.combinedContactsTemplateUrl && (
            <FormPanel
              expanded
              extraClassName={classes.title}
              summary={
                <Typography variant="h5" className={classes.title}>
                  Upload Clients & Contacts
                </Typography>
              }
              gutterBottom
            >
              <Typography color="textSecondary" className={classes.description}>
                Please upload your Client & Contacts (family members or responsible party) here.
                This should be your entire census.
              </Typography>

              <RosterUpload
                templateUrl={currentUser.organization.combinedContactsTemplateUrl}
                templateHeader="Step 1: Download Client Template"
                uploadHeader="Step 2: Upload Your File"
                generateUploadUrl={async (uploadFileName: string, contentType: string) => {
                  const { data: uploadData } = await generateUploadUrl({
                    variables: {
                      fileName: uploadFileName,
                      contentType,
                    },
                  })
                  return uploadData?.generateUploadUrl?.signedUrl
                }}
                onFileUploaded={async (file: string, path: string) => {
                  const result = await addContactsUpload({
                    variables: {
                      importType: ImportContactTypesEnum.COMBINED_CONTACTS,
                      objectPath: path,
                    },
                  })
                  if (result?.data?.addContactsUpload?.errors) {
                    emitter.emit(
                      'ERROR',
                      'Error uploading file. Please try again.',
                      50000, // 50 seconds unless the user exits out
                    )
                  } else {
                    emitter.emit(
                      'SUCCESS',
                      'Your contacts file has been successfully uploaded!',
                      50000, // 50 seconds unless the user exits out
                    )
                  }
                }}
                templateDescription={
                  <div className={classes.directions}>
                    <Typography color="textSecondary">
                      Copy and paste your data into the template, or upload your own excel file,
                      making sure headers and formatting match.
                    </Typography>
                    <br />
                    <Typography color="textSecondary">
                      Each contact will need a Client ID to match them to the right Client.
                    </Typography>
                  </div>
                }
                uploadDescription={
                  <div className={classes.directions}>
                    <Typography color="textSecondary">Upload the excel file.</Typography>
                    <br />
                    <Typography color="textSecondary">
                      Your Program Manager will review your upload and process the file.
                    </Typography>
                  </div>
                }
              />
            </FormPanel>
          )}
        {/* Employee Uploads */}
        {currentUser.canUploadEmployeeContacts &&
          currentUser?.organization?.employeeContactsTemplateUrl && (
            <FormPanel
              expanded
              extraClassName={classes.title}
              summary={
                <Typography variant="h5" className={classes.title}>
                  Upload Employees
                </Typography>
              }
            >
              <Typography color="textSecondary" className={classes.description}>
                Please upload your employee contacts here. This should be all employees at your
                organization.
              </Typography>

              <RosterUpload
                templateUrl="foo"
                templateHeader="Step 1: Download Employee"
                uploadHeader="Step 2: Upload Your File"
                generateUploadUrl={async (uploadFileName: string, contentType: string) => {
                  const { data: uploadData } = await generateUploadUrl({
                    variables: {
                      fileName: uploadFileName,
                      contentType,
                    },
                  })
                  return uploadData?.generateUploadUrl?.signedUrl
                }}
                onFileUploaded={async (file: string, path: string) => {
                  const result = await addContactsUpload({
                    variables: {
                      importType: ImportContactTypesEnum.EMPLOYEES,
                      objectPath: path,
                    },
                  })
                  if (result?.data?.addContactsUpload) {
                    emitter.emit(
                      'SUCCESS',
                      'Your contacts file has been successfully uploaded!',
                      50000, // 50 seconds unless the user exits out
                    )
                  }
                }}
                templateDescription={
                  <div className={classes.directions}>
                    <Typography color="textSecondary">
                      Copy and paste your data into the template, or upload your own excel file,
                      making sure headers and formatting match.
                    </Typography>
                  </div>
                }
                uploadDescription={
                  <div className={classes.directions}>
                    <Typography color="textSecondary">Upload the excel file.</Typography>
                    <br />
                    <Typography color="textSecondary">
                      Your Program Manager will review your upload and process the file.
                    </Typography>
                  </div>
                }
              />
            </FormPanel>
          )}
      </>
    </Page>
  )
}

export default ContactsUpload
