import React, { useState } from 'react'

import {
  makeStyles,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'

import Button from 'components/Blocks/CustomButtons/Button'
import SubmitButton from 'components/Blocks/CustomButtons/SubmitButton'
import { getDisabledSmsNotificationsMessage } from 'components/Survey/Wizard/Steps/Notifications/common'
import { gaEvent } from 'config/ga'
import {
  useSurveysUpdateIntervalNotificationMutation,
  IntervalNotificationInput,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { handleMutationResponse } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  description: {
    marginTop: spacing(3),
  },
  checkboxes: {
    display: 'flex',
    '& div': {
      marginRight: spacing(5),
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  when: {
    marginBottom: spacing(6),
  },
  intervalFormControl: {
    minWidth: 180,
  },
  menuItemHeader: {
    borderBottom: `1px solid ${palette.common.navy25}`,
    color: palette.common.navy,
  },
}))

export const getMenuLabel = (day: '' | number) => {
  if (day === '') {
    return 'Select Interval'
  }
  if (day === 0) {
    return 'Day participant is added'
  }
  return `${day} days after`
}

export const notificationsDays = [0, 3, 7, 14, 30]

type Props = {
  surveyUuid: string
  productType: SurveyProductTypeEnum
  onClose(): void
  existingNotificationDays: number[]
  disabledNewSmsNotifications: boolean
  surveyMaxSmsNotifications: number
}
const AddIntervalNotificationDialog: React.FC<Props> = ({
  surveyUuid,
  productType,
  onClose,
  existingNotificationDays,
  disabledNewSmsNotifications,
  surveyMaxSmsNotifications,
}) => {
  const classes = useStyles()
  const [days, setDays] = useState<null | number>(null)
  const [sms, setSms] = useState(false)
  const [workEmail, setWorkEmail] = useState(false)
  const [personalEmail, setPersonalEmail] = useState(false)

  const [updateIntervalNotification, { loading }] = useSurveysUpdateIntervalNotificationMutation()

  const onAdd = async (notification: IntervalNotificationInput) => {
    gaEvent({
      action: 'addIntervalNotification',
      category: 'Surveys',
    })
    const result = await updateIntervalNotification({
      variables: {
        surveyUuid,
        notificationInput: {
          uuid: null,
          ...notification,
        },
      },
    })
    handleMutationResponse(result.data?.updateIntervalNotification?.errors)
  }

  return (
    <Dialog open>
      <DialogTitle id="email-notification-title">
        Schedule New Notification
        <Typography color="textSecondary" className={classes.description}>
          Schedule when the notification should be sent to participants and choose what type of
          notification to send.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.when}>
          <Typography variant="body2" color="textSecondary">
            WHEN:
          </Typography>
          <FormControl className={classes.intervalFormControl}>
            <Select
              id="daysInterval"
              value={days === null ? '' : days}
              displayEmpty
              renderValue={value => getMenuLabel(value as '' | number)}
              onChange={e => setDays(e.target.value as number)}
              input={<FilledInput />}
            >
              <MenuItem disabled classes={{ root: classes.menuItemHeader }}>
                Schedule reminder for:
              </MenuItem>
              {notificationsDays.map(d => (
                <MenuItem key={d} value={d} disabled={existingNotificationDays.includes(d)}>
                  {getMenuLabel(d)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Typography variant="body2" color="textSecondary">
          NOTIFICATION TYPE:
        </Typography>
        <div className={classes.checkboxes}>
          {productType === SurveyProductTypeEnum.EMPLOYEE && (
            <div className={classes.checkbox}>
              <Typography variant="body2" color="textSecondary">
                WORK EMAIL
              </Typography>{' '}
              <Checkbox checked={workEmail} onClick={() => setWorkEmail(!workEmail)} />
            </div>
          )}
          <div className={classes.checkbox}>
            <Typography variant="body2" color="textSecondary">
              {productType === SurveyProductTypeEnum.RESIDENT ? 'EMAIL' : 'PERSONAL EMAIL'}
            </Typography>
            <Checkbox checked={personalEmail} onClick={() => setPersonalEmail(!personalEmail)} />
          </div>
          <div className={classes.checkbox}>
            <Typography variant="body2" color="textSecondary">
              SMS
            </Typography>{' '}
            <Tooltip
              placement="top"
              title={
                disabledNewSmsNotifications
                  ? getDisabledSmsNotificationsMessage(surveyMaxSmsNotifications)
                  : ''
              }
            >
              <span>
                <Checkbox
                  disabled={disabledNewSmsNotifications}
                  checked={sms}
                  onClick={() => setSms(!sms)}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondaryNoBackground">
          Cancel
        </Button>
        <SubmitButton
          disabled={days === null}
          isSubmitting={loading}
          color="primary"
          onClick={() => {
            days !== null && onAdd({ days, sms, workEmail, personalEmail, active: true })
            onClose()
          }}
        >
          Save
        </SubmitButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddIntervalNotificationDialog
