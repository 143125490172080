import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { format } from 'date-fns'

import InfoCard from 'components/Insights/Blocks/InfoCard'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { numberWithCommas } from 'utils'
import { useTimeframeSettings } from 'utils/customHooks'

const useStyles = makeStyles(({ spacing }) => ({
  details: {
    display: 'flex',
    '& >a': {
      marginRight: spacing(4),
    },
  },
  tooltip: {
    padding: spacing(),
  },
  completionRateBox: {
    backgroundColor: 'rgba(76, 175, 80, 0.15)',
    minWidth: 94,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing(2),
    marginRight: spacing(4),
  },
  info: {
    width: '100%',
  },
}))

type Props = {
  started?: number
  submitted: number
  survey: InsightsSurvey
}
/**
 * Param "started" not required if the desired use of the component is to display submitted total only.
 */
const CompletionRateCard: React.FC<Props> = ({ started, submitted, survey }) => {
  const { total, completionRateRow } =
    started === undefined
      ? { total: submitted, completionRateRow: { label: '', value: '' } }
      : {
          total: started,
          completionRateRow: {
            label: 'Completion rate:',
            value: `${started > 0 ? Math.round((submitted / started) * 100) : 0}%`,
          },
        }

  const classes = useStyles()
  const { startDate, endDate } = useTimeframeSettings(survey)
  return (
    <div>
      <SnapshotChartHeader
        title="Total Responses"
        useBottomPadding={false}
        tooltip={
          <div className={classes.tooltip}>
            <Typography>Completion Rate:</Typography>
            <Typography color="textSecondary">
              {`${started === undefined ? 'Number' : 'Percentage'}
                of participants that started and completed the entire survey within the specified time frame.`}
            </Typography>
          </div>
        }
      />
      <div className={classes.details}>
        <div className={classes.completionRateBox}>
          <Typography variant="h5">{numberWithCommas(total)}</Typography>
        </div>
        <div className={classes.info}>
          <InfoCard
            labelWidth="42%"
            rows={[
              { label: 'Total responses:', value: String(total) },
              completionRateRow,
              {
                label: 'Time frame:',
                value: `${format(new Date(startDate || survey.startDate), 'LLL dd, yyyy')} - ${
                  endDate ? format(new Date(endDate), 'LLL dd, yyyy') : 'present'
                }`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default CompletionRateCard
