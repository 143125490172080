import React, { useContext, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { useParams } from 'react-router-dom'

import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import { toggleFilter, removeFilter, clearFilters } from 'components/Insights/InsightsContainer'
import PasswordOnlyLogin from 'components/Login/PasswordOnlyLogin'
import Monitor from 'components/Survey/Wizard/Steps/Monitor/Monitor'
import {
  getPersistedPassword,
  setPersistedPassword,
} from 'components/Survey/Wizard/Steps/Monitor/utils'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { gaEvent } from 'config/ga'
import { StoreContext, InsightsFilter } from 'config/LocalStorage'
import {
  useSurveysUpdateReportMutation,
  useInsightsResponseRateReportQuery,
  CurrentUserQuery,
  InsightsDownloadDocument,
  SurveysSurveyQuery,
  FilterTypeFragment,
  FilterValueFragment,
  ResponseRateTypeEnum,
  useInsightsResponseRateReportMetadataQuery,
  InsightsResponseRateReportMetadataQuery,
  SurveyDistributionTypeEnum,
  UserDownloadsEnum,
} from 'generated/graphql'
import { reverse, runDownloadQuery } from 'utils'

type WrapperProps = {
  currentUser?: CurrentUserQuery['currentUser']
  surveyUuid: string
  isPublic: boolean
  reportUuid: string
  minShowableResults: number
  distributionType: SurveyDistributionTypeEnum
  password?: string
  isDefaultClientBasedResponseRateReport: boolean
  showPreview?: boolean
  showTranslations?: boolean
  responseRateFilterTypes: NonNullable<
    InsightsResponseRateReportMetadataQuery['responseRateReportMetadata']
  >['filterTypes']
}

type MonitorProps = {
  byClient: boolean
  filterTypes: FilterTypeFragment[]
} & Omit<WrapperProps, 'responseRateFilterTypes'>

const MonitorContainer: React.FC<MonitorProps> = ({
  currentUser,
  surveyUuid,
  byClient,
  filterTypes,
  isPublic,
  reportUuid,
  password,
  minShowableResults,
  distributionType,
  showPreview = false,
  showTranslations = false,
}) => {
  const client = useApolloClient()
  const [selectedFilters, setSelectedFilters] = useState<Array<InsightsFilter>>([])
  const [errors, setErrors] = useState<null | string[]>(null)
  const [updateReport, { loading: updateLoading }] = useSurveysUpdateReportMutation()

  const {
    store: { responseRateGroupByFilterType1Code, responseRateGroupByFilterType2Code },
  } = useContext(StoreContext)

  const groupByFilterType1Code =
    responseRateGroupByFilterType1Code[surveyUuid] || filterTypes[0].dtCode
  const groupByFilterType2Code =
    responseRateGroupByFilterType2Code[surveyUuid] || filterTypes[1].dtCode

  const result = useInsightsResponseRateReportQuery({
    // Fetch the report either from an authenticated Walle survey, or from the report uuid in the url
    variables: {
      surveyUuid,
      reportUuid,
      password,
      filters: selectedFilters.map(f => f.valueUuid),
      groupByFilterType1Code,
      groupByFilterType2Code,
      byClient,
      skipCompletionRate: distributionType === SurveyDistributionTypeEnum.CLOSED,
      skipResponseRate: distributionType === SurveyDistributionTypeEnum.OPEN,
    },
    // Use cache-and-network so we can load cache data while we check for participants
    // who were added or submitted
    fetchPolicy: 'cache-and-network',
  })

  const onUpdateReport = async (newPassword?: string) => {
    if (!surveyUuid) {
      return
    }
    const updateResult = await updateReport({
      variables: {
        surveyUuid,
        password: newPassword,
      },
    })
    if (updateResult.data?.updateReport?.errors) {
      setErrors(updateResult.data?.updateReport?.errors)
    }
  }

  const handleDownload = async () => {
    const variables = {
      surveyUuid,
      filterType1Code: groupByFilterType1Code,
      filterType2Code: groupByFilterType2Code,
      byClient,
      downloadType: UserDownloadsEnum.RESPONSE_RATE_REPORT,
    }
    runDownloadQuery(() =>
      client.query({
        query: InsightsDownloadDocument,
        variables,
        fetchPolicy: 'network-only',
      }),
    )
  }
  return (
    <ResponseHandler {...result}>
      {({ responseRateReport, completionRateReport, survey, responsesByDate }) => {
        return (
          <Monitor
            minShowableResults={minShowableResults}
            currentUser={currentUser}
            visibleFilterTypes={filterTypes}
            reportPassword={password}
            responseRateReport={responseRateReport}
            completionRateReport={completionRateReport}
            survey={survey}
            responsesByDate={responsesByDate}
            byClient={byClient}
            groupByFilterType1Code={groupByFilterType1Code}
            groupByFilterType2Code={groupByFilterType2Code}
            onUpdateReport={onUpdateReport}
            isSubmitting={updateLoading}
            errors={errors}
            handleDownload={handleDownload}
            handleRefresh={() => result.refetch()}
            selectedFilters={selectedFilters}
            toggleFilter={(filterType: FilterTypeFragment, filterValue: FilterValueFragment) => {
              if (!selectedFilters.some(f => f.valueUuid === filterValue.uuid)) {
                gaEvent({
                  action: 'toggleResponseRateFilter',
                  category: 'Surveys',
                })
              }
              toggleFilter(filterType, filterValue, selectedFilters, setSelectedFilters)
            }}
            removeFilter={(filterObj: InsightsFilter) =>
              removeFilter(filterObj, selectedFilters, setSelectedFilters)
            }
            clearFilters={() => clearFilters(setSelectedFilters)}
            isPublic={isPublic}
            showPreview={showPreview}
            showTranslations={showTranslations}
          />
        )
      }}
    </ResponseHandler>
  )
}

const MonitorContainerWrapper: React.FC<WrapperProps> = ({
  surveyUuid,
  isDefaultClientBasedResponseRateReport,
  reportUuid,
  password,
  responseRateFilterTypes,
  ...restProps
}) => {
  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  let showReportBy = ResponseRateTypeEnum.PARTICIPANT
  showReportBy = getShowReportBy(
    responseRateShowReportBy,
    surveyUuid,
    isDefaultClientBasedResponseRateReport,
  )

  const byClient = showReportBy === ResponseRateTypeEnum.CLIENT

  const visibleFilterTypes = reverse(
    responseRateFilterTypes
      .filter(
        // Only include filter types that are valid for `byClient` if `byClient` is selected
        responseRateFilterType => !byClient || responseRateFilterType.isValidByClient,
      )
      .map(responseRateFilerType => responseRateFilerType.filterType),
  )
  return (
    <MonitorContainer
      surveyUuid={surveyUuid}
      byClient={byClient}
      reportUuid={reportUuid}
      password={password}
      isDefaultClientBasedResponseRateReport={isDefaultClientBasedResponseRateReport}
      filterTypes={visibleFilterTypes}
      {...restProps}
    />
  )
}

type ResponseRateReportMetadataContainerProps = {
  currentUser?: CurrentUserQuery['currentUser']
  survey: null | SurveysSurveyQuery['survey']
  isPublic?: boolean
  showPreview?: boolean
  showTranslations?: boolean
}

const ResponseRateReportMetadataContainer: React.FC<ResponseRateReportMetadataContainerProps> = ({
  survey,
  isPublic = false,
  ...restProps
}) => {
  // This component (representing the response rate report for a survey) can be called both by logged in users and
  // publicly by any user outside the product. When used publicly, the report can be protected by a password.
  // In case the component is accessed publicly, we first need to fetch the survey's filters to know which 2
  // filter type groupings we need to use when calling the response rate report.
  const reportUuid = useParams<{ uuid: string }>().uuid

  const [passwordIsDirty, setPasswordIsDirty] = useState(false)
  const password = getPersistedPassword(reportUuid)
  const result = useInsightsResponseRateReportMetadataQuery({
    variables: {
      reportUuid,
      surveyUuid: survey?.uuid,
      password,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ responseRateReportMetadata }, { loading }) => {
        if (!responseRateReportMetadata) {
          return (
            <PasswordOnlyLogin
              onSubmit={newPassword => {
                setPersistedPassword(reportUuid, newPassword)
                setPasswordIsDirty(true)
              }}
              isSubmitting={Boolean(loading)}
              errors={!loading && passwordIsDirty ? ['Wrong password, please try again'] : null}
            />
          )
        }
        return (
          <MonitorContainerWrapper
            surveyUuid={survey?.uuid || responseRateReportMetadata.surveyUuid}
            isDefaultClientBasedResponseRateReport={
              survey?.isDefaultClientBasedResponseRateReport ||
              responseRateReportMetadata.isDefaultClientBasedResponseRateReport
            }
            isPublic={isPublic}
            reportUuid={reportUuid}
            password={password || survey?.reportPassword}
            responseRateFilterTypes={responseRateReportMetadata.filterTypes}
            minShowableResults={responseRateReportMetadata.minShowableResults}
            distributionType={responseRateReportMetadata.distributionType}
            {...restProps}
          />
        )
      }}
    </ResponseHandler>
  )
}

export default ResponseRateReportMetadataContainer
