import React, { useContext } from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import InsightsOverviewRightDetail from 'components/Insights/Blocks/InsightsOverviewRightDetail'
import { ResponseRateTitleAndPie } from 'components/Insights/Blocks/ResponseRateTitleAndPie'
import TimeTrendingIcon from 'components/Insights/Blocks/TimeTrendingIcon'
import InsightsResponseRateContainer from 'components/Insights/InsightsResponseRateContainer'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import { InsightsSurvey } from 'components/Insights/InsightsTypes'
import { getShowReportBy } from 'components/Survey/Wizard/SurveyResponseRateCard'
import { TimeTrendingChartKey, StoreContext } from 'config/LocalStorage'
import { ResponseRateTypeEnum } from 'generated/graphql'
import { colors } from 'shared/theme'

const useStyles = makeStyles(({ spacing }) => ({
  timeTrending: {
    position: 'absolute',
    right: 12,
    top: spacing(),
  },
}))

type CardProps = {
  finished: number
  total: number
  rate: number
  hasTimeTrending: boolean
  surveyUuid: string
  showReportBy: ResponseRateTypeEnum
  minShowableResults: number
  showTitle?: boolean
}
// Just the card component when the data has been prefetched.
export const ResidentResponseRateCard: React.FC<CardProps> = ({
  finished,
  total,
  rate,
  hasTimeTrending,
  surveyUuid,
  showReportBy,
  minShowableResults,
  showTitle = true,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  let title = 'Opportunity to Improve'
  let color = colors.success
  if (rate >= 90) {
    title = 'Incredible!'
  } else if (rate >= 80) {
    title = 'Very Good'
  } else if (rate >= 60) {
    title = 'Good'
  } else if (rate >= 20) {
    title = 'Okay'
  } else {
    color = colors.warning
  }
  if (!showTitle) {
    title = ''
  }

  return (
    <div id="response-rate-snap" className={classes.halfRoot}>
      <ResponseRateTitleAndPie
        surveyUuid={surveyUuid}
        minShowableResults={minShowableResults}
        finished={finished}
        total={total}
        showReportBy={showReportBy}
      />
      <InsightsOverviewRightDetail
        title={title}
        titleColor={color}
        score={`${finished}/${total}`}
        extraDescription={
          <Typography color="textSecondary">
            {Math.round(rate)}% of participants responded to the survey.
          </Typography>
        }
      />
      {hasTimeTrending && (
        <TimeTrendingIcon
          chartKey={TimeTrendingChartKey.RESIDENT_INDEX_SCORE}
          className={classes.timeTrending}
        />
      )}
    </div>
  )
}

type Props = {
  survey: InsightsSurvey
  hasTimeTrending: boolean
  filters: string[]
  isDefaultClientBasedResponseRateReport: boolean
  minShowableResults: number
}
const ResidentResponseRateCardContainer: React.FC<Props> = ({
  survey,
  hasTimeTrending,
  filters,
  isDefaultClientBasedResponseRateReport,
  minShowableResults,
}) => {
  const {
    store: { responseRateShowReportBy },
  } = useContext(StoreContext)

  const showReportBy = getShowReportBy(
    responseRateShowReportBy,
    survey.uuid,
    isDefaultClientBasedResponseRateReport,
  )

  return (
    <InsightsResponseRateContainer
      survey={survey}
      filterValueUuids={filters}
      showReportByClient={showReportBy === ResponseRateTypeEnum.CLIENT}
    >
      {({ responseRate }) => {
        return (
          <ResidentResponseRateCard
            hasTimeTrending={hasTimeTrending}
            surveyUuid={survey.uuid}
            showReportBy={showReportBy}
            minShowableResults={minShowableResults}
            total={responseRate.total}
            finished={responseRate.finished}
            rate={Math.round((responseRate.finished / responseRate.total) * 100)}
          />
        )
      }}
    </InsightsResponseRateContainer>
  )
}

export default ResidentResponseRateCardContainer
