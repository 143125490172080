import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import createPalette from '@material-ui/core/styles/createPalette'
import shadows from '@material-ui/core/styles/shadows'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'

/**
 * Helpful info about default Typography font sizes
 * caption: 1.2rem
 * subtitle1: 1.6rem // These 2 can be adjusted if we need another standard size 1.8rem
 * subtitle2: 1.4rem
 * body1: 1.6rem
 * body2: 1.4rem
 * h6: 2.0rem
 * h5: 2.4rem
 * h4: 3.4rem
 * h3: 4.8rem
 * h2: 6.0rem
 * h1: 9.6rem
 */
const white = '#FFFFFF'
const white75 = 'rgba(255, 255, 255, 0.75)'
const white50 = 'rgba(255, 255, 255, 0.50)'
const white25 = 'rgba(255, 255, 255, 0.25)'
const navy = '#0F3246'
const navy65 = 'rgba(15, 50, 70, 0.65)'
const navy50 = 'rgba(15, 50, 70, 0.50)'
const navy35 = 'rgba(15, 50, 70, 0.35)'
const navy25 = 'rgba(15, 50, 70, 0.25)'
const navy15 = 'rgba(15, 50, 70, 0.15)'
const navy11 = 'rgba(15, 50, 70, 0.11)'
const navy07 = 'rgba(15, 50, 70, 0.07)'
const navy05 = 'rgba(15, 50, 70, 0.05)'
const grey = '#CCCCCC'
const borderGrey = '#C3CCD1'
const lightGrey = '#F7F9F9'
const iceGrey = 'rgb(226, 232, 233)'
const iceGrey50 = 'rgba(226, 232, 233, 0.50)'
const iceGrey65 = 'rgba(226, 232, 233, 0.65)'
const darkGrey = '#555555'
const teal = '#25AAD3'
const peach = '#F2994A'
const darkOrange = '#D98133'
const primary = '#FFE47F'
const secondary = '#50BCB5'
const secondaryHover = 'rgba(80, 188, 181, 0.08)'
const secondary20 = 'rgba(80, 188, 181, 0.2)'
const secondary50 = 'rgba(80, 188, 181, 0.5)'
const secondaryDark = '#3D928B'
const danger = '#EF585A'
const mustardYellow = '#CF9628'
const dangerHover = 'rgba(244, 67, 54, 0.08)'
const warning = '#FF9800'
const secondaryWarning = 'rgba(255, 152, 0, 0.15)'
const success = '#4CAF50'
const secondarySuccess = 'rgba(76, 175, 80, 0.1)'
const tertiarySuccess = '#8DD090'
const purple = '#D2C5D6'
const spacingUnit = 8
const fontFamily =
  '"YogaSansMedium","Lucida Grande","Lucida Sans Unicode","Lucida Sans","Helvetica", "Arial", sans-serif'

/**
 * In order to get types for the custom properties on the theme object (e.g. common.navy65),
 * we need to use "Module Extension". You can implement module extension explicitly, like:
 * declare module '@material-ui/core/colors/common' {
 *  interface CommonColors extends CommonColorsAlias {
 *    navy65
 *  }
 * }
 * or we can generate the types directly from the custom objects by using an alias to the "typeof" operator.
 */
const commonColors = {
  white,
  white75,
  white50,
  white25,
  navy,
  navy65,
  navy50,
  navy35,
  navy25,
  navy15,
  navy11,
  navy07,
  navy05,
  primary,
  secondary,
  secondary20,
  secondary50,
  secondaryDark,
  success,
  warning,
  secondaryWarning,
  secondarySuccess,
  tertiarySuccess,
  danger,
  dangerHover,
  darkGrey,
  grey,
  borderGrey,
  iceGrey,
  iceGrey50,
  iceGrey65,
  lightGrey,
  mustardYellow,
  purple,
  teal,
  peach,
  darkOrange,
}

export type CommonColorsAlias = typeof commonColors

declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors extends CommonColorsAlias {}
}

// Module extension to allow accessing our custom properties on the theme object.
declare module '@material-ui/core/colors/common' {
  interface CommonColors extends CommonColorsAlias {}
}

// Module extension for MuiPicker overrides
// See: https://stackoverflow.com/questions/54815307/how-to-extend-material-ui-theme-typescript-definition-with-the-material-ui-picke
type overridesNameToClassKey = { [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P] }

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const palette = createPalette({
  common: commonColors,
  action: {
    hover: navy05,
  },
  error: {
    main: danger,
  },
  text: {
    primary: navy,
    secondary: navy65,
  },
  background: {
    paper: '#FFF',
    default: iceGrey,
  },
  primary: {
    light: primary,
    main: primary,
    dark: primary,
    contrastText: white,
  },
  secondary: {
    light: secondary,
    main: secondary,
    dark: secondary,
    contrastText: white,
  },
})
export const themeOptions: ThemeOptions = {
  typography: {
    htmlFontSize: 10,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      fontSize: '1.6rem',
      textTransform: 'capitalize',
    },
  },
  palette,
  overrides: {
    // Mui overrides
    MuiAppBar: {
      colorDefault: {
        backgroundColor: white,
      },
    },
    MuiButton: {
      textPrimary: {
        color: navy,
        '&:hover': {
          backgroundColor: secondaryHover,
        },
      },
      containedPrimary: {
        color: navy,
        backgroundColor: primary,
      },
    },
    MuiDialogActions: {
      root: {
        marginBottom: spacingUnit * 3,
        marginRight: spacingUnit * 3,
      },
    },
    MuiAccordion: {
      root: {
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: secondary20,
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1.4rem',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: secondary,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${secondary}`,
        },
      },
      input: {
        color: navy,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: navy15,
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
          backgroundColor: navy25,
        },
        '&$focused': {
          backgroundColor: navy15,
        },
      },
      underline: {
        '&:after, &:before': {
          borderBottom: '0',
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '0',
        },
        '&:before': {},
      },
      input: { paddingTop: 10, paddingRight: 20 },
    },
    MuiListItem: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: secondary20,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: secondary,
      },
    },
    MuiStepIcon: {
      root: {
        color: navy25,
        '&$active': {
          color: secondary,
        },
        '&$completed': {
          color: success,
        },
      },
      text: {
        fill: white,
        fontSize: '1.6rem',
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '1.6rem',
      },
    },
    MuiTab: {
      root: {},
      textColorInherit: {
        opacity: 1,
        color: navy,
      },
      textColorPrimary: {
        color: navy,
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
      },
    },
    MuiTable: {
      root: {
        borderTop: `2px solid ${navy25}`,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: navy05,
        },
      },
    },
    MuiTableCell: {
      head: {
        paddingLeft: 0,
        textTransform: 'uppercase',
        fontSize: '1.4rem',
      },
      body: {
        padding: `0 ${spacingUnit}px`,
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
      tooltip: {
        backgroundColor: white,
        color: navy,
        padding: '4px 8px',
        fontSize: '1.4rem',
        maxWidth: 500,
        boxShadow: shadows[2],
      },
    },
    // Mui Datetime Picker
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: navy,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        color: '#FFF',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: navy,
        '& svg': {
          fill: '#FFF',
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: navy,
      },
      daySelected: {
        backgroundColor: secondary,
        '&:hover': {
          backgroundColor: secondary,
        },
      },
      current: {
        color: secondary,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: secondary,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: secondary,
      },
      thumb: {
        backgroundColor: secondary,
        borderColor: secondary,
      },
      noPoint: {
        backgroundColor: secondary,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover, &:focus:hover': {
          backgroundColor: secondary20,
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        color: navy,
      },
    },
    MuiSvgIcon: {
      root: {
        overflow: 'visible',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: warning,
      },
      colorSecondary: {
        backgroundColor: danger,
      },
      dot: {
        width: 8,
        height: 8,
        padding: 0,
      },
    },
  },
}

const theme = createMuiTheme(themeOptions)

export default theme
