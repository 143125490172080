import React, { useEffect, useState, useContext, SyntheticEvent } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  makeStyles,
  AppBar,
  FilledInput,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import xorBy from 'lodash/xorBy'
import querystring from 'query-string'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useHistory, useLocation, Link, Redirect, Route, Switch } from 'react-router-dom'

import { ReactComponent as CustomIcon } from 'assets/img/custom-survey-icon.svg'
import { ReactComponent as PulseIcon } from 'assets/img/pulse-icon.svg'
import { ReactComponent as TrustIndexIcon } from 'assets/img/trust-index-graph.svg'
import { getFilterKey } from 'components/Blocks/Filters/FilterControls'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import Page from 'components/Blocks/Layout/Page'
import BenchmarkSelect from 'components/Insights/Blocks/BenchmarkSelect'
import LessThanMinShowableResults from 'components/Insights/Blocks/LessThanMinShowableResults'
import TimeFrameDropdown from 'components/Insights/Blocks/TimeFrameDropdown'
import TimeTrendingTimeframeDropdown from 'components/Insights/Blocks/TimeTrendingTimeframeDropdown'
import InsightsControls from 'components/Insights/InsightsControls'
import InsightsResponseRateContainer from 'components/Insights/InsightsResponseRateContainer'
import useInsightsStyles from 'components/Insights/InsightsStyle'
import getInsightsTabs from 'components/Insights/insightsTabs'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import { useTimeTrendingTimeframeSettings } from 'components/Insights/TimeframeTimeTrending/timeframeUtils'
import { gaEvent } from 'config/ga'
import { LocalStore, StoreContext, InsightsFilter } from 'config/LocalStorage'
import {
  SurveyProductTypeEnum,
  FilterTypeFragment,
  FilterValueFragment,
  CurrentUserQuery,
  CurrentUserDocument,
  InsightsModulesEnum,
  BenchmarkNode,
  SurveyTypeEnum,
} from 'generated/graphql'
import { getVisibleFilterTypes } from 'utils'
import { CUSTOM_SURVEY_TYPES, TimeframeDropDownLocation, TimeTrendingType } from 'utils/constants'
import { defaultInsightsPage, getInsightsFieldsForProductType } from 'utils/insightsUtils'
import { SurveyNode } from 'utils/types'

const useStyles = makeStyles(() => ({
  dropdownLabelWithIcon: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& svg': {
      flexShrink: 0,
      marginRight: 4,
    },
  },
}))

const TabLabel: React.FC<{
  tab: {
    disabledTabTooltip?: string
    name: string
  }
}> = ({ tab: { name, disabledTabTooltip } }) => {
  return (
    <Tooltip title={disabledTabTooltip || ''}>
      <span>{name}</span>
    </Tooltip>
  )
}

export const toggleFilter = (
  filterType: FilterTypeFragment,
  filterValue: FilterValueFragment,
  selectedFilters: Array<InsightsFilter>,
  setSelectedFilters: (selectedFilters: Array<InsightsFilter>) => void,
) => {
  const filterObj: InsightsFilter = {
    id: filterValue.id,
    index: filterType.index,
    dtCode: filterType.dtCode,
    name: filterType.name,
    insightsProductType: filterType.insightsProductType,
    valueUuid: filterValue.uuid,
    valueName: filterValue.name,
  }
  setSelectedFilters(xorBy(selectedFilters, [filterObj], getFilterKey))
}

export const removeFilter = (
  filterObj: InsightsFilter,
  selectedFilters: Array<InsightsFilter>,
  setSelectedFilters: (selectedFilters: Array<InsightsFilter>) => void,
) =>
  setSelectedFilters(
    selectedFilters.filter(filter => getFilterKey(filter) !== getFilterKey(filterObj)),
  )

export const clearFilters = (
  setSelectedFilters: (selectedFilters: Array<InsightsFilter>) => void,
) => setSelectedFilters([])

interface Props {
  availableInsightsModules: InsightsModulesEnum[]
  availableSurveys: NonNullable<Array<SurveyNode>>
  survey: InsightsSurvey
  insightsFilters: Array<InsightsFilter>
  insightsBenchmark: BenchmarkNode | null
}
const InsightsContainer: React.FC<Props> = ({
  availableInsightsModules,
  availableSurveys,
  survey,
  insightsFilters: selectedFilters,
  insightsBenchmark,
}) => {
  const classes = { ...useInsightsStyles(), ...useStyles() }
  const client = useApolloClient()
  const { currentUser } = client.readQuery({
    query: CurrentUserDocument,
  }) as NonNullable<CurrentUserQuery>
  const { solution, insightsUrls } = getInsightsFieldsForProductType(
    currentUser,
    survey.productType,
  )
  const isCustomSurvey = CUSTOM_SURVEY_TYPES.includes(survey.type)

  const { updateStore } = useContext(StoreContext)
  const { timeTrendingEnabled: timeTrendingTimeframeEnabled } = useTimeTrendingTimeframeSettings(
    survey,
  )

  const history = useHistory()
  const { search, pathname } = useLocation()
  const [anchorElBenchmarks, setAnchorElBenchmarks] = useState<null | Element>(null)

  const benchmark = insightsBenchmark || survey.defaultBenchmark
  const [searchQuery, setSearchQuery] = useState('')

  const filters = selectedFilters.map(f => f.valueUuid)

  const setBenchmark = (newBenchmark: BenchmarkNode) => {
    const productTypeToBenchmarkKey: { [key in SurveyProductTypeEnum]: keyof LocalStore } = {
      [SurveyProductTypeEnum.EMPLOYEE]: 'employeeInsightsBenchmark',
      [SurveyProductTypeEnum.RESIDENT]: 'residentInsightsBenchmark',
    }
    updateStore({ [productTypeToBenchmarkKey[survey.productType]]: newBenchmark })
  }

  // Save last visited url and filter settings to local cache
  // URL contains information about which survey and which tab is selected
  useEffect(() => {
    if (survey.productType === SurveyProductTypeEnum.EMPLOYEE) {
      updateStore({ lastInsightsPathname: pathname })
    } else {
      updateStore({ lastResidentInsightsPathname: pathname })
    }
  }, [updateStore, survey.productType, pathname])

  const setSelectedFilters = (newSelectedFilters: Array<InsightsFilter>) => {
    const newInsightsFilters = newSelectedFilters.map(filter => ({
      ...filter,
      __typename: 'FilterValue',
    }))
    if (survey.productType === SurveyProductTypeEnum.EMPLOYEE) {
      updateStore({ insightsFilters: newInsightsFilters })
    } else {
      updateStore({ residentInsightsFilters: newInsightsFilters })
    }
  }

  const addFilterFromChart = (filterType: FilterTypeFragment, name: string) => {
    currentUser.filters.forEach(filter => {
      if (filter.filterTypeUuid === filterType.filterTypeUuid) {
        filter.filterValues.forEach(value => {
          if (value.name === name) {
            toggleFilter(filter, value, selectedFilters, setSelectedFilters)
          }
        })
      }
    })
  }

  const handleSurveySelect = (surveyUuid: string) => {
    const tabPath = defaultInsightsPage(surveyUuid, availableInsightsModules, survey.productType)
    history.push(tabPath)
  }

  const tabs = getInsightsTabs({
    survey,
    filters: selectedFilters,
    solution,
    insightsUrls,
    availableInsightsModules,
    availableSurveys,
  })

  const SurveyIcon = {
    [SurveyTypeEnum.TI]: TrustIndexIcon,
    [SurveyTypeEnum.PULSE]: PulseIcon,
    [SurveyTypeEnum.CUSTOM]: CustomIcon,
    [SurveyTypeEnum.MONTHLY]: TrustIndexIcon,
    [SurveyTypeEnum.END_OF_EMPLOYMENT]: TrustIndexIcon,
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT]: TrustIndexIcon,
    [SurveyTypeEnum.RESIDENT_PULSE]: PulseIcon,
    [SurveyTypeEnum.RESIDENT_END_OF_SERVICE]: TrustIndexIcon,
    [SurveyTypeEnum.RESIDENT_DISCHARGE]: TrustIndexIcon,
    [SurveyTypeEnum.RESIDENT_CUSTOM]: CustomIcon,
    [SurveyTypeEnum.RESIDENT_ENGAGEMENT_MONTHLY]: TrustIndexIcon,
    [SurveyTypeEnum.RESIDENT_START_OF_SERVICE]: TrustIndexIcon,
  }[survey.type]

  return (
    <Page>
      <BreadcrumbsItem to={insightsUrls.DASHBOARD}>Insights</BreadcrumbsItem>
      <AppBar className={classes.appBar} position="static" color="default">
        <GridContainer alignItems="center">
          <ItemGrid sm={8}>
            <Tabs
              value={pathname}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_, path) => {
                // Distinguishing from regular React.pageview so we can
                // 1) isolate the tab from the url which will have a survey specific UUID
                // 2) group with other events for easier analysis
                gaEvent({
                  action: `pageView-${path.split('/').pop()}`,
                  category: 'Insights',
                })
              }}
            >
              {tabs.map(
                tab =>
                  pathname.includes(survey.uuid) && (
                    <Tab
                      style={{ pointerEvents: 'auto' }}
                      key={tab.name}
                      classes={{ root: classes.tabOverrideRoot }}
                      label={<TabLabel tab={tab} />}
                      disabled={Boolean(tab.disabledTabTooltip)}
                      value={tab.path(survey.uuid)}
                      component={Link}
                      onClick={e => tab.disabledTabTooltip && e.preventDefault()}
                      to={tab.path(survey.uuid)}
                    />
                  ),
              )}
            </Tabs>
          </ItemGrid>
          <ItemGrid className={classes.wrapperControls} sm={4}>
            <div className={classes.selectWithLabel}>
              <Typography variant="body2" color="textSecondary">
                Survey:
              </Typography>
              <FormControl
                variant="filled"
                className={classes.selectWithLabelFC}
                style={{ maxWidth: 150 }}
              >
                <Select
                  id="select-survey"
                  value={survey.uuid}
                  onChange={e => handleSurveySelect((e.target as HTMLInputElement).value)}
                  displayEmpty={false}
                  variant="filled"
                  input={<FilledInput />}
                  name="survey"
                  renderValue={selectedUuid =>
                    availableSurveys.find(s => s.uuid === selectedUuid)?.name
                  }
                >
                  {availableSurveys.map(s => (
                    <MenuItem key={s.uuid} value={s.uuid} id={s.name.replace(/\s/g, '-')}>
                      <span className={classes.dropdownLabelWithIcon}>
                        <SurveyIcon />
                        {s.name}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {!isCustomSurvey && benchmark && (
              <>
                <IconButton
                  id="benchmarks"
                  aria-haspopup="true"
                  aria-owns={anchorElBenchmarks ? 'benchmarksMenu' : undefined}
                  aria-label="Benchmarks"
                  onClick={(e: SyntheticEvent) => setAnchorElBenchmarks(e.currentTarget)}
                >
                  <ShowChartIcon className={classes.benchmarkIcon} />
                </IconButton>
                {anchorElBenchmarks !== null && (
                  <BenchmarkSelect
                    benchmarkInternal={solution.benchmarkInternal}
                    selectedSurveyUuid={survey.uuid}
                    selectedBenchmark={benchmark}
                    setBenchmark={bm => {
                      gaEvent({
                        action: `selectBenchmark-${bm.benchmarkType}`,
                        category: 'Insights',
                      })
                      setBenchmark(bm)
                    }}
                    anchorEl={anchorElBenchmarks}
                    onClose={() => setAnchorElBenchmarks(null)}
                  />
                )}
              </>
            )}
          </ItemGrid>
        </GridContainer>
      </AppBar>
      <Paper className={classes.content}>
        <Switch>
          <>
            {tabs.map(tab =>
              tab.disabledTabTooltip && tab.path(survey.uuid) === pathname ? (
                <Redirect
                  key={tab.name}
                  to={defaultInsightsPage(
                    survey.uuid,
                    currentUser.insightsModules,
                    survey.productType,
                  )}
                />
              ) : (
                <Route
                  key={tab.name}
                  exact
                  path={tab.url}
                  render={props => (
                    <>
                      {tab.showControls && (
                        <InsightsControls
                          survey={survey}
                          screenshotId={tab.name}
                          selectedFilters={selectedFilters}
                          toggleFilter={(
                            filterType: FilterTypeFragment,
                            filterValue: FilterValueFragment,
                          ) => {
                            if (!selectedFilters.some(f => f.valueUuid === filterValue.uuid)) {
                              gaEvent({
                                action: 'toggleInsightsFilter',
                                category: 'Insights',
                              })
                            }
                            toggleFilter(
                              filterType,
                              filterValue,
                              selectedFilters,
                              setSelectedFilters,
                            )
                          }}
                          removeFilter={(filterObj: InsightsFilter) =>
                            removeFilter(filterObj, selectedFilters, setSelectedFilters)
                          }
                          clearFilters={() => clearFilters(setSelectedFilters)}
                          searchQuery={searchQuery}
                          handleStatementSearch={(query: string) => setSearchQuery(query)}
                          {...tab}
                          benchmark={
                            benchmark && {
                              benchmarkType: benchmark.benchmarkType,
                              benchmarkUuid: benchmark.benchmarkUuid,
                              name: benchmark.name,
                            }
                          }
                        />
                      )}
                      <InsightsResponseRateContainer
                        survey={survey}
                        filterValueUuids={selectedFilters.map(f => f.valueUuid)}
                      >
                        {({ responseRate, isUsingTimeFilters }) => {
                          const lessThanMinShowableResults =
                            responseRate.finished < survey.minShowableResults
                          // For some tabs, time-filters are shown within the component.
                          // When use of the time-filters leads to the "less than min showable" empty state,
                          // we need to make sure the time filters are available on the top level controls
                          //  so that the user can continue adjusting them to get out of the empty state.
                          const showTimeframeDropdown =
                            tab.timeframeDropdownLocation === TimeframeDropDownLocation.CONTROLS ||
                            (tab.timeframeDropdownLocation ===
                              TimeframeDropDownLocation.COMPONENT &&
                              lessThanMinShowableResults)
                          return (
                            <>
                              {showTimeframeDropdown && (
                                <div className={classes.surveyDropdownControlsRow}>
                                  {timeTrendingTimeframeEnabled &&
                                  tab.timeTrendingType === TimeTrendingType.TIMEFRAME ? (
                                    <TimeTrendingTimeframeDropdown surveyUuid={survey.uuid} />
                                  ) : (
                                    <TimeFrameDropdown survey={survey} />
                                  )}
                                </div>
                              )}
                              {lessThanMinShowableResults ? (
                                <LessThanMinShowableResults
                                  numParticipants={responseRate.total}
                                  productType={survey.productType}
                                  isUsingFilters={selectedFilters.length > 0 || isUsingTimeFilters}
                                  minShowableResults={survey.minShowableResults}
                                  hasConfidentialResults={survey.hasConfidentialResults}
                                />
                              ) : (
                                <tab.component
                                  searchQuery={searchQuery}
                                  survey={survey}
                                  responseRate={responseRate}
                                  filters={filters}
                                  visibleFilterTypes={getVisibleFilterTypes(
                                    currentUser.filters,
                                    survey.filterTypeUuids,
                                  )}
                                  /** Benchmark can technically be null here but we make sure
                                   * not to render components that expect it when there is no benchmark
                                   * (specifically, custom survey insights.). The alternative would be
                                   * to handle a null benchmark in every tab even though we don't expect it.
                                   */
                                  benchmark={
                                    (benchmark && {
                                      benchmarkType: benchmark.benchmarkType,
                                      benchmarkUuid: benchmark.benchmarkUuid,
                                      name: benchmark.name,
                                    }) as InsightsBenchmark
                                  }
                                  searchParams={querystring.parse(search)}
                                  solution={solution}
                                  addFilterFromChart={addFilterFromChart}
                                  availableSurveys={availableSurveys}
                                  currentUser={currentUser}
                                  {...tab}
                                  {...props}
                                />
                              )}
                            </>
                          )
                        }}
                      </InsightsResponseRateContainer>
                    </>
                  )}
                />
              ),
            )}
          </>
          <Redirect
            to={defaultInsightsPage(survey.uuid, currentUser.insightsModules, survey.productType)}
          />
        </Switch>
      </Paper>
    </Page>
  )
}

export default InsightsContainer
