import React, { useState, SyntheticEvent } from 'react'

import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popover,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory } from 'react-router-dom'

import Button from 'components/Blocks/CustomButtons/Button'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import { gaEvent } from 'config/ga'
import { SurveyProductTypeEnum, SurveyTypeEnum } from 'generated/graphql'
import { getSurveyTypeLabel } from 'utils'
import {
  CUSTOM_SURVEY_TYPES,
  PRODUCT_TYPE_LABELS,
  SURVEY_TYPES_TO_PRODUCT_TYPE,
  URLS,
} from 'utils/constants'

const useStyles = makeStyles(({ palette, spacing }) => ({
  radioGroup: {
    padding: spacing(2),
    borderBottom: `1px solid ${palette.common.navy25}`,
  },
  createButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: spacing(),
  },
}))

type OptionsProps = {
  surveyTypes: SurveyTypeEnum[]
  selectedType: SurveyTypeEnum
  setSelectedType(surveyType: SurveyTypeEnum): void
}

const SurveyTypeOptions: React.FC<OptionsProps> = ({
  surveyTypes,
  selectedType,
  setSelectedType,
}) => {
  return (
    <RadioGroup>
      {surveyTypes.map(surveyType => (
        <FormControlLabel
          value={surveyType}
          key={surveyType}
          control={
            <Radio
              checked={surveyType === selectedType}
              onChange={() => setSelectedType(surveyType)}
            />
          }
          // In this component, we want custom survey type label to be
          // always "Custom" regardless of the product type.
          label={getSurveyTypeLabel(surveyType, !CUSTOM_SURVEY_TYPES.includes(surveyType))}
        />
      ))}
    </RadioGroup>
  )
}

type Props = {
  surveyTypesAllowedToAdd: Array<SurveyTypeEnum>
}

const NewSurveyButton: React.FC<Props> = ({ surveyTypesAllowedToAdd }) => {
  /**
   * This component renders the new survey button based on the number/kind of surveys available to the user
   * 1) One kind of survey (resident or employee) => redirect on click
   * 2) Multiple employee surveys => dropdown with radio group for employee surveys
   * 3) Multiple resident surveys => dropdown with radio group for res surveys
   * 4) Both employee/resident surveys => expansion panel with separate dropdown radio groups
   */
  const history = useHistory()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const [selectedType, setSelectedType] = useState()

  if (surveyTypesAllowedToAdd.length === 1) {
    return (
      <Button
        onClick={() => history.push(URLS.SURVEYS.CREATE, { type: surveyTypesAllowedToAdd[0] })}
        endIcon={<AddIcon />}
      >
        New Survey
      </Button>
    )
  }

  const availableSurveyTypes: {
    [productType in SurveyProductTypeEnum]: SurveyTypeEnum[]
  } = {
    [SurveyProductTypeEnum.EMPLOYEE]: [],
    [SurveyProductTypeEnum.RESIDENT]: [],
  }

  surveyTypesAllowedToAdd.forEach(surveyType => {
    const productType = SURVEY_TYPES_TO_PRODUCT_TYPE[surveyType]
    availableSurveyTypes[productType].push(surveyType)
  })

  const availableProductTypes = Object.entries(availableSurveyTypes)
    .filter(([_, surveyTypes]) => surveyTypes.length > 0)
    .map(([productType, _]) => productType as SurveyProductTypeEnum)

  if (availableProductTypes.length === 0) return <></>

  let component
  if (availableProductTypes.length > 1) {
    component = (
      <>
        {Object.entries(availableSurveyTypes).map(([productType, surveyTypes]) => {
          const label = PRODUCT_TYPE_LABELS[productType as SurveyProductTypeEnum]
          return (
            <Accordion key={productType}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${label}Survey`}>
                {label} Survey
              </AccordionSummary>
              <AccordionDetails>
                <SurveyTypeOptions
                  surveyTypes={surveyTypes}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                />
              </AccordionDetails>
            </Accordion>
          )
        })}
      </>
    )
  } else {
    component = (
      <div className={classes.radioGroup}>
        <SurveyTypeOptions
          surveyTypes={availableSurveyTypes[availableProductTypes[0]]}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      </div>
    )
  }
  return (
    <>
      <Button
        id="newSurvey"
        onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget)}
        startIcon={<AddIcon />}
      >
        New Survey
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <>
          {component}
          <div className={classes.createButton}>
            <Button
              id="createSurvey"
              autoFocus
              disabled={!selectedType}
              onClick={() => {
                gaEvent({
                  action: `createSurveyType-${selectedType}`,
                  category: 'Surveys',
                })
                history.push(URLS.SURVEYS.CREATE, { type: selectedType })
              }}
            >
              Create
            </Button>
          </div>
        </>
      </Popover>
    </>
  )
}

export default NewSurveyButton
