import React from 'react'

import {
  makeStyles,
  Checkbox,
  FilledInput,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  Typography,
  TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FilterNone'
import cn from 'classnames'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import { ReactComponent as MultiselectIcon } from 'assets/img/custom_questions/checkboxes.svg'
import { ReactComponent as LinearScaleIcon } from 'assets/img/custom_questions/linear-scale.svg'
import { ReactComponent as LongAnswerIcon } from 'assets/img/custom_questions/long-answer.svg'
import { ReactComponent as MultipleChoiceIcon } from 'assets/img/custom_questions/radio-button.svg'
import { ReactComponent as ShortAnswerIcon } from 'assets/img/custom_questions/short-answer.svg'
import FormPanel from 'components/Blocks/Accordions/FormPanel'
import Button from 'components/Blocks/CustomButtons/Button'
import IconButton from 'components/Blocks/CustomButtons/IconButton'
import DebouncedTextInput from 'components/Blocks/FormHelpers/DebouncedTextInput'
import { QKind, QuestionChoiceInput, SurveysQuestionsQuery } from 'generated/graphql'
import { LINEAR_QUESTION_CHOICE_OPTIONS } from 'utils/constants'

export type CustomQuestionType = SurveysQuestionsQuery['questions'][0]
export type CustomLinearQuestionChoiceType = NonNullable<
  CustomQuestionType['choices'][0] & { value: number }
>

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(2),
  },
  questionRoot: {
    margin: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    '& >div:first-child': {
      width: '50%',
    },
  },
  linearChoiceRoot: {
    marginLeft: spacing(2),
    minHeight: 120,
  },
  selectWithLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& >*': {
      marginLeft: spacing(2),
    },
  },
  selectWithLabelFC: {
    height: 32,
  },
  selectSeparator: {
    marginLeft: spacing(),
    marginRight: spacing(),
  },
  selectQuestionKind: {
    minWidth: 150,
  },
  dropdownLabelWithIcon: {
    minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& svg': {
      flexShrink: 0,
      marginRight: 4,
    },
  },
  linearInputWrapper: {
    display: 'flex',
    marginBottom: spacing(2),
  },
  linearInputSelect: {
    width: 50,
    height: 30,
  },
  choiceInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      width: 20,
      marginRight: spacing(),
    },
  },
  addChoiceWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyIcon: {
    width: 30,
  },
}))

const delayTime = 700 // 0.7 seconds

type LinearChoiceProps = {
  choices: CustomLinearQuestionChoiceType[]
  handleUpdateQuestionChoices(choices: QuestionChoiceInput[]): void
}

const CustomQuestionLinearChoices: React.FC<LinearChoiceProps> = ({
  choices,
  handleUpdateQuestionChoices,
}) => {
  const classes = useStyles()
  // The start of the linear scale can be 0 or 1
  const scaleStartOptions = [0, 1]
  // The end of the linear scale must be greater than the start of the scale */
  const scaleEndOptions = LINEAR_QUESTION_CHOICE_OPTIONS.filter(
    value => value > LINEAR_QUESTION_CHOICE_OPTIONS[0],
  )

  const scaleStart = choices[0].value
  const scaleEnd = choices[choices.length - 1].value

  const onChangeScaleStart = (newScaleStart: number) => {
    if (newScaleStart > scaleStart) {
      handleUpdateQuestionChoices(choices.filter(c => c.value >= newScaleStart))
    } else {
      const newChoices: CustomLinearQuestionChoiceType[] = []
      let index = newScaleStart
      while (index < scaleStart) {
        newChoices.push({ text: '', value: index, uuid: '' })
        index += 1
      }
      handleUpdateQuestionChoices([...newChoices, ...choices])
    }
  }

  const onChangeScaleEnd = (newScaleEnd: number) => {
    if (newScaleEnd < scaleEnd) {
      handleUpdateQuestionChoices(choices.filter(c => c.value <= newScaleEnd))
    } else {
      let index = scaleEnd
      const newChoices: CustomLinearQuestionChoiceType[] = []
      while (index < newScaleEnd) {
        index += 1
        newChoices.push({ text: '', value: index, uuid: '' })
      }
      handleUpdateQuestionChoices([...choices, ...newChoices])
    }
  }

  return (
    <div className={classes.linearChoiceRoot}>
      <Typography color="textSecondary">Scale</Typography>
      <div className={classes.linearInputWrapper}>
        <FormControl variant="filled" className={classes.selectWithLabelFC}>
          <Select
            className={classes.linearInputSelect}
            value={scaleStart}
            onChange={e => onChangeScaleStart(parseInt((e.target as HTMLInputElement).value))}
            displayEmpty={false}
            variant="filled"
            input={<FilledInput />}
            name="linear-choice-1"
          >
            {scaleStartOptions.map(value => (
              <MenuItem key={value} value={value}>
                <span className={classes.dropdownLabelWithIcon}>{value}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography color="textSecondary" className={classes.selectSeparator}>
          to
        </Typography>
        <FormControl variant="filled" className={classes.selectWithLabelFC}>
          <Select
            className={classes.linearInputSelect}
            value={scaleEnd}
            onChange={e => onChangeScaleEnd(parseInt((e.target as HTMLInputElement).value))}
            displayEmpty={false}
            variant="filled"
            input={<FilledInput />}
            name="linear-choice-2"
          >
            {scaleEndOptions.map(value => (
              <MenuItem key={value} value={value}>
                <span className={classes.dropdownLabelWithIcon}>{value}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {choices.map((choice, index) => {
        return (
          <div key={choice.value} className={classes.choiceInputWrapper}>
            <Typography color="textSecondary">{choice.value}</Typography>
            <DebouncedTextInput
              InputComponent={TextField}
              value={choice.text}
              onUpdate={value =>
                handleUpdateQuestionChoices(
                  choices.map(c => (c.uuid === choice.uuid ? { ...c, text: value } : c)),
                )
              }
              name={`linearLabel${index + 1}`}
              type="text"
              fullWidth
              placeholder="Label (Optional)"
              delayTime={delayTime}
            />
          </div>
        )
      })}
    </div>
  )
}

type ChoiceProps = {
  questionKind: QKind
  choicesLength: number
  choice: CustomQuestionType['choices'][0]
  handleAddNewQuestionChoice(choiceText: string): void
  handleUpdateQuestionChoice(choices: QuestionChoiceInput): void
  handleDeleteQuestionChoice(choiceUuid: string): void
}

const CustomQuestionChoice: React.FC<ChoiceProps> = ({
  questionKind,
  choicesLength,
  choice,
  handleUpdateQuestionChoice,
  handleDeleteQuestionChoice,
}) => {
  const classes = useStyles()
  let icon = null
  if (questionKind === QKind.MULTIPLE_CHOICE) {
    icon = <Radio checked={false} disabled />
  } else {
    icon = <Checkbox checked={false} disabled />
  }
  return (
    <div className={classes.choiceInputWrapper}>
      {icon}
      <DebouncedTextInput
        InputComponent={TextValidator}
        value={choice.text}
        onUpdate={value => {
          handleUpdateQuestionChoice({ ...choice, text: value })
        }}
        name={choice.uuid}
        type="text"
        fullWidth
        validators={['required']}
        errorMessages={['Option text is required']}
        delayTime={delayTime}
      />
      {choicesLength > 1 && (
        <IconButton color="dangerHover" onClick={() => handleDeleteQuestionChoice(choice.uuid)}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
}

type Props = {
  index: number
  question: CustomQuestionType
  handleAddQuestion(question: CustomQuestionType): void
  handleDeleteQuestion(questionUuid: string): void
  handleQuestionTextChange(text: string): void
  handleQuestionKindChange(kind: QKind): void
  handleAddNewQuestionChoice(choiceText: string): void
  handleUpdateQuestionChoices(choices: QuestionChoiceInput[]): void
  handleDeleteQuestionChoice(choiceUuid: string): void
}

const CustomQuestion: React.FC<Props> = ({
  index,
  question,
  handleAddNewQuestionChoice,
  handleDeleteQuestionChoice,
  handleQuestionTextChange,
  handleQuestionKindChange,
  handleUpdateQuestionChoices,
  handleAddQuestion,
  handleDeleteQuestion,
}) => {
  const classes = useStyles()
  const kinds = [
    {
      kind: QKind.SHORT_ANSWER,
      label: 'Short Answer',
      icon: <ShortAnswerIcon />,
    },
    {
      kind: QKind.OPEN_ENDED,
      label: 'Long Answer',
      icon: <LongAnswerIcon />,
    },
    {
      kind: QKind.MULTIPLE_CHOICE,
      label: 'Multiple Choice',
      icon: <MultipleChoiceIcon />,
    },
    {
      kind: QKind.MULTISELECT,
      label: 'Checkboxes',
      icon: <MultiselectIcon />,
    },
    {
      kind: QKind.LINEAR,
      label: 'Linear Scale',
      icon: <LinearScaleIcon />,
    },
  ]
  return (
    <FormPanel extraClassName={classes.root} square>
      <ValidatorForm instantValidate onSubmit={() => {}}>
        <div className={classes.questionRoot}>
          <DebouncedTextInput
            InputComponent={TextValidator}
            value={question.text}
            onUpdate={handleQuestionTextChange}
            name={`questionText-${index}`}
            type="text"
            fullWidth
            placeholder="Enter your survey question here"
            validators={['required']}
            errorMessages={['Question text is required']}
            delayTime={delayTime}
          />
          <div className={classes.selectWithLabel}>
            <Typography variant="body1" color="textSecondary">
              Type:
            </Typography>
            <FormControl
              variant="filled"
              className={cn(classes.selectWithLabelFC, classes.selectQuestionKind)}
            >
              <Select
                value={question.kind}
                onChange={e =>
                  handleQuestionKindChange((e.target as HTMLInputElement).value as QKind)
                }
                displayEmpty={false}
                variant="filled"
                input={<FilledInput />}
                name={`kind-${index}`}
              >
                {kinds.map(({ kind, icon, label }) => (
                  <MenuItem key={kind} value={kind}>
                    <span className={classes.dropdownLabelWithIcon}>
                      {icon}
                      {label}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {question.choices &&
          question.choices.length > 0 &&
          (question.kind === QKind.LINEAR ? (
            <CustomQuestionLinearChoices
              choices={question.choices as CustomLinearQuestionChoiceType[]}
              handleUpdateQuestionChoices={handleUpdateQuestionChoices}
            />
          ) : (
            <>
              <List component="ol">
                {question.choices.map(choice => (
                  <ListItem key={choice.uuid}>
                    {question.choices && (
                      <CustomQuestionChoice
                        questionKind={question.kind}
                        choicesLength={question.choices.length}
                        choice={choice}
                        handleUpdateQuestionChoice={newChoice =>
                          handleUpdateQuestionChoices(
                            question.choices.map(c => (c.uuid === newChoice.uuid ? newChoice : c)),
                          )
                        }
                        handleDeleteQuestionChoice={handleDeleteQuestionChoice}
                        handleAddNewQuestionChoice={handleAddNewQuestionChoice}
                      />
                    )}
                  </ListItem>
                ))}
              </List>
              <div className={classes.addChoiceWrapper}>
                <Button
                  onClick={() => handleAddNewQuestionChoice('')}
                  color="secondaryNoBackground"
                >
                  <Typography color="secondary">Add option</Typography>
                </Button>
                <Button
                  onClick={() => handleAddNewQuestionChoice('Other')}
                  color="secondaryNoBackground"
                >
                  <Typography color="secondary">Add &quot;Other&quot;</Typography>
                </Button>
              </div>
            </>
          ))}
        <div className={classes.actionsWrapper}>
          <Tooltip title="Duplicate">
            <IconButton
              className={classes.copyIcon}
              onClick={() => handleAddQuestion(question)}
              color="secondaryHover"
            >
              <CopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteQuestion(question.uuid)} color="dangerHover">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ValidatorForm>
    </FormPanel>
  )
}

export default CustomQuestion
