import React, { useContext, useEffect, useState } from 'react'

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core'
import FileCopy from '@material-ui/icons/FileCopy'
import cn from 'classnames'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

import { container } from 'assets/jss/main'
import TablePanel from 'components/Blocks/Accordions/TablePanel'
import CopyTextInput from 'components/Blocks/CopyTextInput'
import ActionDialogButtons from 'components/Blocks/Dialogs/ActionDialogButtons'
import FilterGroupDropdowns from 'components/Blocks/Filters/FilterGroupDropdowns'
import Errors from 'components/Blocks/FormHelpers/Errors'
import FormControlLabel from 'components/Blocks/FormHelpers/FormControlLabel'
import GridContainer from 'components/Blocks/Grid/GridContainer'
import ItemGrid from 'components/Blocks/Grid/ItemGrid'
import LockedSurveyControls from 'components/Survey/Wizard/LockedSurveyControls'
import ResponsesOverTimeChart from 'components/Survey/Wizard/ResponsesOverTimeChart'
import MonitorResponseHighlights from 'components/Survey/Wizard/Steps/Monitor/MonitorResponseHighlights'
import SurveyCompletionRateCard from 'components/Survey/Wizard/SurveyCompletionRateCard'
import SurveyResponseRateCard from 'components/Survey/Wizard/SurveyResponseRateCard'
import { gaEvent } from 'config/ga'
import { StoreContext, InsightsFilter } from 'config/LocalStorage'
import {
  CurrentUserQuery,
  InsightsResponseRateReportQuery,
  SurveyStatusEnum,
  FilterTypeFragment,
  FilterValueFragment,
  SurveyDistributionTypeEnum,
  SurveyProductTypeEnum,
} from 'generated/graphql'
import { SURVEY_TYPES_TO_PRODUCT_TYPE } from 'utils/constants'

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    minHeight: 'calc(100vh - 80px)',
    padding: spacing(4),
    [breakpoints.down('sm')]: {
      padding: spacing(),
    },
    ...container,
  },
  right: {
    textAlign: 'right',
  },
  controls: {
    backgroundColor: '#fff',
  },
  responsesOverTimeContainer: {
    borderLeft: `1px solid ${palette.common.navy25}`,
  },
  dropdownRow: {
    borderTop: `1px solid ${palette.common.navy25}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(),
    paddingLeft: spacing(3),
    paddingBottom: spacing(),
    '& >div': {
      display: 'flex',
    },
  },
  table: {
    border: 0,
    borderTop: `1px solid ${palette.common.navy25}`,
  },
}))

const addValues = (vals: number[]) => vals.reduce((t, n) => t + n, 0)

const computeRate = (
  vals: any,
  rows: { total: number; finished: number }[],
  minShowableResults: number,
) => {
  const total = addValues(rows.map(r => r.total))
  if (total === 0) return 0 // don't divide by 0
  const finished = addValues(rows.map(r => r.finished))
  if (finished > 0 && finished < minShowableResults) return `<${minShowableResults}`
  return (finished / total) * 100
}
const hideLowValues = (v: number, minShowableResults: number) => {
  if (v > 0 && v < minShowableResults) return `<${minShowableResults}`
  if (v === 0) return '-'
  return v
}

const getColumns = (
  classes: ReturnType<typeof useStyles>,
  level1FilterType: FilterTypeFragment,
  level2FilterType: FilterTypeFragment,
  minShowableResults: number,
  isCompletionReport: boolean,
) => {
  const columns = [
    {
      Header: level1FilterType.name,
      accessor: 'filterValue1',
    },
    {
      Header: level2FilterType.name,
      accessor: 'filterValue2',
      aggregate: () => '',
    },
    {
      Header: isCompletionReport ? 'Completed' : 'Submitted',
      className: classes.right,
      accessor: isCompletionReport ? 'complete' : 'finished',
      maxWidth: 120,
      aggregate: addValues,
      Cell: (row: { value: number; row: { finished: number } }) =>
        hideLowValues(row.value, minShowableResults),
    },
    {
      Header: isCompletionReport ? 'Incomplete' : 'Invited',
      className: classes.right,
      accessor: isCompletionReport ? 'incomplete' : 'total',
      maxWidth: 120,
      aggregate: addValues,
      Cell: (row: { value: number; row: { finished: number } }) =>
        hideLowValues(row.value, minShowableResults),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      // Only doing the aggregate for response rate report
      aggregate: (vals: any, rows: { total: number; finished: number }[]) =>
        computeRate(vals, rows, minShowableResults),
      maxWidth: 120,
      className: classes.right,
      Cell: (row: { value: string; row: { finished: number } }) => {
        const s = row.row.finished
        if (s > 0 && s < minShowableResults) return `<${minShowableResults}`
        return `${Math.round(parseFloat(row.value))}%`
      },
    },
  ]
  if (isCompletionReport) {
    return columns.slice(0, -1)
  }
  return columns
}

type Props = {
  currentUser?: CurrentUserQuery['currentUser']
  reportPassword?: string
  responseRateReport?: NonNullable<InsightsResponseRateReportQuery['responseRateReport']> | null
  completionRateReport?: NonNullable<InsightsResponseRateReportQuery['completionRateReport']> | null
  responsesByDate: NonNullable<InsightsResponseRateReportQuery['responsesByDate']>
  survey: NonNullable<InsightsResponseRateReportQuery['survey']>
  byClient: boolean
  minShowableResults: number
  visibleFilterTypes: FilterTypeFragment[]
  groupByFilterType1Code: string
  groupByFilterType2Code: string
  onUpdateReport(password?: null | string): void
  isSubmitting: boolean
  isPublic: boolean
  showPreview: boolean
  showTranslations: boolean
  errors: null | string[]
  handleDownload(): void
  handleRefresh(): void
  selectedFilters: Array<InsightsFilter>
  toggleFilter(filterType: FilterTypeFragment, filterValue: FilterValueFragment): void
  removeFilter(filterObj: InsightsFilter): void
  clearFilters(): void
}

const Monitor: React.FC<Props> = ({
  currentUser,
  reportPassword: initialReportPassword,
  responseRateReport,
  completionRateReport,
  responsesByDate,
  survey,
  byClient,
  minShowableResults,
  visibleFilterTypes,
  groupByFilterType1Code,
  groupByFilterType2Code,
  onUpdateReport,
  isSubmitting,
  isPublic,
  handleRefresh,
  selectedFilters,
  errors,
  ...tabProps
}) => {
  const classes = useStyles()
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [reportPassword, setReportPassword] = useState(initialReportPassword)
  const [reportProtect, setReportProtect] = useState(Boolean(reportPassword))

  const { updateStore } = useContext(StoreContext)
  const level1FilterType =
    visibleFilterTypes.find(({ dtCode }) => dtCode === groupByFilterType1Code) ||
    visibleFilterTypes[0]
  const level2FilterType =
    visibleFilterTypes.find(({ dtCode }) => dtCode === groupByFilterType2Code) ||
    visibleFilterTypes[1]

  const {
    store: { responseRateGroupByFilterType1Code, responseRateGroupByFilterType2Code },
  } = useContext(StoreContext)

  useEffect(() => {
    if (!reportProtect) {
      setReportPassword(undefined)
    }
  }, [reportProtect])

  let defaultPageSize = 50
  if (responseRateReport) {
    defaultPageSize = Math.min(responseRateReport.responseRate.totalFilter1, defaultPageSize)
  } else if (completionRateReport) {
    defaultPageSize = Math.min(completionRateReport.completionRate.totalFilter1, defaultPageSize)
  }

  const downloads = [
    {
      show: true,
      text: 'Download to Excel',
      disabledTitle: isPublic ? 'You must login to download reports.' : '',
    },
  ]

  const rowsData =
    responseRateReport?.rows ||
    completionRateReport?.rows.map(row => ({
      ...row,
      complete: row.submitted,
      incomplete: row.started - row.submitted,
    }))

  return (
    <div className={cn({ [classes.root]: isPublic })}>
      <div className={cn({ [classes.controls]: isPublic })}>
        <LockedSurveyControls
          isPublic={isPublic}
          surveyUuid={survey.uuid}
          surveyProductType={SURVEY_TYPES_TO_PRODUCT_TYPE[survey.type]}
          showRefresh={survey.status === SurveyStatusEnum.LIVE}
          handleRefresh={handleRefresh}
          downloads={downloads}
          openShareDialog={() => {
            gaEvent({
              action: 'shareResponseRate',
              category: 'Surveys',
            })
            setOpenShareDialog(true)
          }}
          filters={visibleFilterTypes}
          selectedFilters={selectedFilters}
          {...tabProps}
        />
      </div>
      <Paper square elevation={0}>
        <GridContainer>
          <ItemGrid sm={6}>
            {completionRateReport && (
              <SurveyCompletionRateCard
                survey={survey}
                {...completionRateReport.completionRate}
                minShowableResults={minShowableResults}
              />
            )}
            {responseRateReport && (
              <SurveyResponseRateCard
                surveyUuid={survey.uuid}
                surveyType={survey.type}
                minShowableResults={minShowableResults}
                isResidentSurvey={
                  SURVEY_TYPES_TO_PRODUCT_TYPE[survey.type] === SurveyProductTypeEnum.RESIDENT
                }
                isDefaultClientBasedResponseRateReport={
                  survey.isDefaultClientBasedResponseRateReport
                }
                finished={responseRateReport.responseRate.finished}
                total={responseRateReport.responseRate.total}
                startDate={survey.startDate}
                endDate={survey.endDate}
                isOpenLinkSurvey={survey.distributionType === SurveyDistributionTypeEnum.OPEN}
              />
            )}
          </ItemGrid>
          <ItemGrid className={classes.responsesOverTimeContainer} sm={6}>
            <ResponsesOverTimeChart
              goal={survey.targetResponsesNo}
              responseData={responsesByDate}
              surveyStatus={survey.status}
            />
          </ItemGrid>
        </GridContainer>
      </Paper>
      {responseRateReport && (
        <MonitorResponseHighlights
          highlights={responseRateReport.highlights}
          byClient={byClient}
          eligibilityRate={Math.round(survey.certificationEligibilityRate)}
          surveyName={survey.name}
          surveyUuid={survey.uuid}
          filters={visibleFilterTypes}
          selectedFilters={selectedFilters}
          // Only show the the see more button / chart if user is logged in
          showSeeMore={Boolean(currentUser)}
        />
      )}
      <Paper className={classes.dropdownRow} square elevation={0}>
        <Typography>Details</Typography>
        <div>
          <FilterGroupDropdowns
            level1FilterType={level1FilterType}
            level2FilterType={level2FilterType}
            setLevel1FilterType={ft =>
              updateStore({
                publicMonitorSurveyUuid: survey.uuid,
                responseRateGroupByFilterType1Code: {
                  ...responseRateGroupByFilterType1Code,
                  [survey.uuid]: ft ? ft.dtCode : null,
                },
              })
            }
            setLevel2FilterType={ft =>
              updateStore({
                publicMonitorSurveyUuid: survey.uuid,
                responseRateGroupByFilterType2Code: {
                  ...responseRateGroupByFilterType2Code,
                  [survey.uuid]: ft ? ft.dtCode : null,
                },
              })
            }
            visibleFilterTypes={visibleFilterTypes}
            allowEmptyLevel1Filter={false}
          />
        </div>
      </Paper>
      <TablePanel
        gutterBottom
        interactive={false}
        reactTableProps={{
          columns: getColumns(
            classes,
            level1FilterType,
            level2FilterType,
            minShowableResults,
            Boolean(completionRateReport),
          ),
          data: rowsData,
          defaultPageSize,
          getTdProps: () => ({
            style: {
              textAlign: 'left',
            },
          }),
          showPagination: true,
          filterable: false,
          pivotBy: ['filterValue1'],
          pageSizeOptions: [20, 50, 100, 500],
          extraClassname: classes.table,
        }}
      />
      <Dialog open={openShareDialog} fullWidth>
        <DialogTitle>Share this report</DialogTitle>
        <DialogContent>
          <CopyTextInput
            CopyIconElement={
              <IconButton aria-label="Copy your share url to your clipboard.">
                <FileCopy />
              </IconButton>
            }
            value={survey.reportShareUrl}
          />
          <FormControlLabel
            control={
              <Switch
                name="protect"
                checked={reportProtect}
                onChange={(e, checked) => setReportProtect(checked)}
                color="secondary"
              />
            }
            label="Password protect your report"
          />
          <ValidatorForm instantValidate onSubmit={() => {}}>
            {reportProtect && (
              <TextValidator
                className=""
                name="reportPassword"
                type="text"
                autoComplete="off"
                fullWidth
                value={reportPassword || ''}
                label="Report Password"
                placeholder="Add a password to protect the report"
                onChange={e => setReportPassword((e.target as HTMLInputElement).value)}
                validators={['required']}
                errorMessages={['Please provide a password']}
              />
            )}
            <Errors errors={errors} />
          </ValidatorForm>
        </DialogContent>
        <ActionDialogButtons
          submitButtonText="Save"
          onClose={() => {
            setOpenShareDialog(false)
            setReportPassword(initialReportPassword)
          }}
          onSubmit={() => {
            setOpenShareDialog(false)
            onUpdateReport(reportPassword)
          }}
          isSubmitting={isSubmitting}
          submitDisabled={reportProtect && !reportPassword}
        />
      </Dialog>
    </div>
  )
}

export default Monitor
