import React, { useState } from 'react'

import { makeStyles, MenuItem, Typography } from '@material-ui/core'
import cn from 'classnames'
import ReactEcharts from 'echarts-for-react'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'

import TextDropdown from 'components/Blocks/Dropdowns/TextDropdown'
import ExternalNavLink from 'components/Blocks/ExternalNavLink'
import ResponseHandler from 'components/Blocks/Layout/ResponseHandler'
import useInsightsStyles, { chartTextStyle } from 'components/Insights/InsightsStyle'
import { InsightsBenchmark, InsightsSurvey } from 'components/Insights/InsightsTypes'
import KeyDemographicsModalChart from 'components/Insights/Snapshot/KeyDemographicsModalChart'
import SnapshotChartHeader from 'components/Insights/Snapshot/SnapshotChartHeader'
import { renderEchartTooltip } from 'components/Insights/TimeTrending/Blocks/echartTooltipBuilder'
import StandardTooltip, {
  TooltipRow,
} from 'components/Insights/TimeTrending/Blocks/StandardTooltip'
import {
  BenchmarkCodeType,
  DataTypeCode,
  useInsightsReasonsForLeavingQuery,
  GroupCountsType,
  VoluntaryDischargeGroupCountsType,
  PublicConstants,
} from 'generated/graphql'
import { colors } from 'shared/theme'
import { formatTooltipScore, splitTextByLineLength } from 'utils'
import { usePublicConstants } from 'utils/customHooks'
import { surveysTooltip } from 'utils/echartsHelpers'
import { ReasonsForLeavingEnum } from 'utils/generatedEnums'

export enum ChartType {
  ALL_REASONS = 'ALL_REASONS',
  VOLUNTARY_INVOLUNTARY = 'VOLUNTARY_INVOLUNTARY',
}

export const CHART_TYPE_TO_LABEL = {
  [ChartType.ALL_REASONS]: 'All Reasons',
  [ChartType.VOLUNTARY_INVOLUNTARY]: 'Voluntary vs. Involuntary',
}

export const ALL_REASONS_COLOR_MAPPING = {
  [ReasonsForLeavingEnum.NEEDED_MORE_CARE]: '#FE4D5C',
  [ReasonsForLeavingEnum.CLOSER_TO_LOVED_ONES]: '#FFC949',
  [ReasonsForLeavingEnum.RAN_OUT_OF_MONEY]: '#5FC2E1',
  [ReasonsForLeavingEnum.DISSATISFIED_WITH_CARE]: '#4EA087',
  [ReasonsForLeavingEnum.DISSATISFIED_WITH_PRICE]: '#7BD574',
  [ReasonsForLeavingEnum.OTHER_DISSATISFACTION]: '#7466C1',
  [ReasonsForLeavingEnum.REQUESTED_BY_FACILITY_OR_UNKNOWN]: '#A59CD7',
  [ReasonsForLeavingEnum.DEATH]: '#CFCFCF',
}

// Note: Intentional ordering, results should be sorted by largest slice.
export const VOLUNTARY_COLORS = ['#4D62B2', '#7181C1', '#D3D8EC', '#A6B0D8', '#8B99CD']
export const INVOLUNTARY_COLORS = ['#5FC157', '#9DD999']

const useStyles = makeStyles(({ spacing }) => ({
  allReasonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  voluntaryInvoluntaryContainer: {
    display: 'flex',
    '& >div:first-child': {
      marginLeft: '10%',
      marginRight: '10%',
    },
  },
  voluntaryInvoluntaryLegend: {
    display: 'flex',
    marginLeft: '10%',
    '& >p': {
      marginRight: spacing(2),
    },
  },
  legendRow: {
    display: 'flex',
  },
  legendIcon: {
    width: 10,
    height: 10,
    marginRight: 10,
    borderRadius: 10,
    alignSelf: 'center',
    display: 'inline-block', // Important for tooltip design
  },
  highlightsContainer: {
    marginTop: spacing(4),
  },
  highlightDetail: {
    marginTop: spacing(),
    '& >span': {
      textTransform: 'none',
    },
  },
}))

const ReasonsForLeavingHighlights: React.FC<{
  locationGroupCounts: VoluntaryDischargeGroupCountsType[]
}> = ({ locationGroupCounts }) => {
  const classes = { ...useStyles(), ...useInsightsStyles() }
  const sortToLabel = {
    lowest: 'Lowest % of Voluntary Move Outs',
    highest: 'Highest % of Voluntary Move Outs',
  }
  const [sortHighlightsBy, setSortHighlightsBy] = useState('lowest')
  const locationHighlights = orderBy(
    locationGroupCounts
      .filter(group => group.voluntaryCount || group.involuntaryCount)
      .map(group => ({
        label: group.label,
        rate: Math.round(
          (group.voluntaryCount / (group.involuntaryCount + group.voluntaryCount)) * 100,
        ),
      })),
    'rate',
    sortHighlightsBy === 'lowest' ? 'asc' : 'desc',
  ).slice(0, 5)
  let description = `These locations have the highest percentage of voluntary move outs
    compared to all your other locations.`
  if (sortHighlightsBy === 'lowest') {
    description = `These locations have the lowest percentage of voluntary
      move outs compared to all your other locations. These locations are doing great,
      and should be recognized for their achievements.`
  }
  return (
    <div className={classes.highlightsContainer}>
      <SnapshotChartHeader
        title="Locations to Focus On"
        description={description}
        useBottomPadding={false}
        extraControls={
          <>
            <TextDropdown
              value={sortHighlightsBy}
              renderValue={sort => {
                return `Show Locations with: ${sortToLabel[sort as 'lowest' | 'highest']}`
              }}
              onChange={e => {
                setSortHighlightsBy(e.target.value as string)
              }}
            >
              <MenuItem value="lowest">{sortToLabel.lowest}</MenuItem>
              <MenuItem value="highest">{sortToLabel.highest}</MenuItem>
            </TextDropdown>
          </>
        }
      />
      <div className={classes.highlights}>
        {locationHighlights.slice(0, 5).map(({ label, rate }) => {
          if (!label) return <div />
          return (
            <div key={label}>
              <Typography variant="h5">{Math.round(rate)}%</Typography>
              <Typography variant="body2" color="textSecondary">
                {label}
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.highlightDetail}>
                <span>Of the people leaving, {Math.round(rate)}% left voluntarily.</span>
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

type LegendProps = {
  reasonsForLeaving: PublicConstants['reasonsForLeaving']
}
const AllReasonsLegend: React.FC<LegendProps> = ({ reasonsForLeaving }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography>All Reasons</Typography>
      {reasonsForLeaving.map(reasonForLeaving => {
        return (
          <div className={classes.legendRow} key={reasonForLeaving.value}>
            <div
              className={classes.legendIcon}
              style={{
                backgroundColor:
                  ALL_REASONS_COLOR_MAPPING[reasonForLeaving.value as ReasonsForLeavingEnum],
              }}
            />
            <Typography color="textSecondary">{reasonForLeaving.value}</Typography>
          </div>
        )
      })}
    </div>
  )
}

type InfoProps = {
  groups: GroupCountsType[]
  reasonsForLeaving: PublicConstants['reasonsForLeaving']
}
export const getVoluntaryCountsFromReasonsForLeaving = ({
  groups,
  reasonsForLeaving,
}: InfoProps) => {
  let total = 0
  let voluntaryCount = 0
  let involuntaryCount = 0
  reasonsForLeaving.forEach(reason => {
    const reasonGroup = groups.find(group => group.label === reason.value)
    if (!reasonGroup) return
    if (reason.isVoluntary) {
      voluntaryCount += reasonGroup.count
    } else if (reason.isVoluntary === false) {
      involuntaryCount += reasonGroup.count
    }
    total += reasonGroup.count
  })
  return { total, voluntaryCount, involuntaryCount }
}

const VoluntaryInvoluntaryLegend: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.voluntaryInvoluntaryLegend}>
      <div className={classes.legendIcon} style={{ backgroundColor: INVOLUNTARY_COLORS[0] }} />
      <Typography>Involuntary</Typography>
      <div className={classes.legendIcon} style={{ backgroundColor: VOLUNTARY_COLORS[0] }} />
      <Typography>Voluntary</Typography>
    </div>
  )
}

const VoluntaryInvoluntaryInfo: React.FC<InfoProps> = ({ reasonsForLeaving, groups }) => {
  const { total, voluntaryCount, involuntaryCount } = getVoluntaryCountsFromReasonsForLeaving({
    reasonsForLeaving,
    groups,
  })
  return (
    <div>
      <Typography>
        {Math.round((voluntaryCount / total) * 100)}% of the residents left voluntarily
      </Typography>
      <Typography color="textSecondary">
        Of all the reasons residents decide to leave, many are preventable. Focus on addressing the
        main reasons residents voluntarily move out to increase retention.&nbsp;
        <ExternalNavLink
          to="https://activatedinsights.force.com/help/s/article/voluntary-move-out-reasons"
          label="Learn more"
        />
      </Typography>
      <br />
      <Typography>
        {Math.round((involuntaryCount / total) * 100)}% of the residents left for involuntary
        reasons
      </Typography>
      <Typography color="textSecondary">
        These residents left for reasons that cannot be prevented. Ideally, over time you’ll reduce
        your number of voluntary move-outs so that your only move-outs are involuntary.
      </Typography>
    </div>
  )
}

type PieChartProps = {
  chartType: ChartType
  groups: GroupCountsType[]
  reasonsForLeaving: PublicConstants['reasonsForLeaving']
}
const getReasonCount = (reason: string, groups: GroupCountsType[]): { value: number } => {
  const reasonGroup = groups.find(group => group.label === reason)
  if (!reasonGroup) {
    return { value: 0 }
  }
  return { value: reasonGroup.count }
}

export const getPieChartData = (
  chartType: ChartType,
  groups: GroupCountsType[],
  reasonsForLeaving: PublicConstants['reasonsForLeaving'],
) => {
  if (chartType === ChartType.VOLUNTARY_INVOLUNTARY) {
    // Order by largest, only include reasons that map to a voluntary/involuntary reason,
    // and keep track of the "voluntary" type so we can supply the appropriate colors.
    const data = orderBy(
      reasonsForLeaving
        .map(({ value, isVoluntary }) => {
          const reasonGroup = groups.find(group => group.label === value)
          if (!reasonGroup || isNil(isVoluntary)) {
            return null
          }
          return { value: reasonGroup.count, isVoluntary }
        })
        .filter(Boolean),
      ['isVoluntary', 'value'],
      ['desc', 'desc'],
    )
    const numVoluntary = data.filter(val => val?.isVoluntary).length
    const numInvoluntary = data.filter(val => !val?.isVoluntary).length
    return {
      title: 'Voluntary vs. Involuntary',
      data,
      chartColors: [
        ...VOLUNTARY_COLORS.slice(0, numVoluntary),
        ...INVOLUNTARY_COLORS.slice(0, numInvoluntary),
      ],
    }
  }
  // All reasons: Map over each reason for leaving, assign 0 if the group doesn't exist.
  const data = reasonsForLeaving.map(({ value }) => getReasonCount(value, groups))
  return {
    chartColors: Object.values(ALL_REASONS_COLOR_MAPPING),
    title: 'All Reasons for Leaving',
    data,
  }
}

const ChartTooltip: React.FC<PieChartProps & { isVoluntary?: boolean; title: string }> = ({
  chartType,
  groups,
  reasonsForLeaving,
  title,
  isVoluntary,
}) => {
  let reasons = reasonsForLeaving
  let tooltipTitle = title
  let chartColors = Object.values(ALL_REASONS_COLOR_MAPPING)
  if (chartType === ChartType.VOLUNTARY_INVOLUNTARY) {
    if (isVoluntary) {
      tooltipTitle = 'Voluntary Reasons'
      reasons = reasons.filter(r => r.isVoluntary)
      chartColors = VOLUNTARY_COLORS
    } else {
      tooltipTitle = 'Involuntary Reasons'
      reasons = reasons.filter(r => r.isVoluntary === false)
      chartColors = INVOLUNTARY_COLORS
    }
  }
  const classes = useStyles()
  const total = groups.reduce((acc, group) => acc + group.count, 0)
  const rows: Array<TooltipRow | null> = reasons.map((reasonForLeaving, idx) => {
    const { value } = getReasonCount(reasonForLeaving.value, groups)
    if (chartType === ChartType.VOLUNTARY_INVOLUNTARY && value === 0) return null
    return {
      preDetail: (
        <div className={classes.legendIcon} style={{ backgroundColor: chartColors[idx] }} />
      ),
      label: reasonForLeaving.value,
      value: formatTooltipScore((value / total) * 100, 0),
      color: colors.navy,
    }
  })
  return (
    <StandardTooltip
      title={tooltipTitle}
      rows={rows.filter((row): row is TooltipRow => Boolean(row))}
    />
  )
}

const ReasonsForLeavingPieChart: React.FC<PieChartProps> = ({
  chartType,
  groups,
  reasonsForLeaving,
}) => {
  const { title, chartColors, data } = getPieChartData(chartType, groups, reasonsForLeaving)
  return (
    <ReactEcharts
      notMerge
      style={{
        height: 230,
        width: 230,
      }}
      option={{
        textStyle: chartTextStyle,
        title: [
          {
            text: splitTextByLineLength(title, 10),
            target: 'self',
            x: 'center',
            y: 'center',
            textStyle: {
              color: colors.navy,
              fontSize: 16,
              fontWeight: 'normal',
              fontFamily: 'YogaSansMedium',
            },
          },
        ],
        color: chartColors,
        series: [
          {
            type: 'pie',
            radius: ['70%', '100%'],
            label: {
              show: false,
            },
            // Disable hover emphasis
            emphasis: {
              scale: false,
            },
            data,
          },
        ],
        tooltip: {
          ...surveysTooltip({ trigger: 'item' }),
          formatter: ({ data: { isVoluntary } }: { data: { isVoluntary?: boolean } }) => {
            return renderEchartTooltip(
              <ChartTooltip
                title={title}
                isVoluntary={isVoluntary}
                groups={groups}
                reasonsForLeaving={reasonsForLeaving}
                chartType={chartType}
              />,
            )
          },
        },
      }}
    />
  )
}

type Props = {
  survey: InsightsSurvey
  filters: string[]
  startDate?: string
  endDate?: string
  benchmark: InsightsBenchmark
}
const ReasonsForLeavingCard: React.FC<Props> = ({
  survey,
  filters,
  benchmark,
  startDate,
  endDate,
}) => {
  const classes = useStyles()
  const { reasonsForLeaving } = usePublicConstants()
  const [chartType, setChartType] = useState(ChartType.ALL_REASONS)
  const [showModalChart, setShowModalChart] = useState(false)
  const result = useInsightsReasonsForLeavingQuery({
    variables: {
      surveyUuid: survey.uuid,
      filters,
      startDate,
      endDate,
      multiselectCode: BenchmarkCodeType.LEFT_REASON,
      dtCode: DataTypeCode.AI_LOCATION,
    },
  })
  return (
    <ResponseHandler {...result}>
      {({ insightsMultiselectGroupCounts, insightsVoluntaryDischargeCountsBy }) => {
        if (insightsMultiselectGroupCounts.length === 0) {
          return <div />
        }
        return (
          <div id="reasons-for-leaving-chart">
            <SnapshotChartHeader
              title="Why Residents are Leaving"
              description="Comparing Voluntary vs Involuntary reasons for leaving helps you
                understand how to reduce move-outs. Whereas involuntary departures are often
                necessary, voluntary reasons for leaving can be addressed and improved."
              tooltip="Comparing Voluntary vs Involuntary reasons for leaving helps you understand how to reduce move-outs. Whereas involuntary departures are often necessary, voluntary reasons for leaving can be addressed and improved."
              extraControls={
                <TextDropdown
                  id="reasons-for-leaving-dropdown"
                  value={chartType}
                  renderValue={type => `Show: ${CHART_TYPE_TO_LABEL[type as ChartType]}`}
                  onChange={e => {
                    setChartType(e.target.value as ChartType)
                  }}
                >
                  <MenuItem value={ChartType.ALL_REASONS}>
                    {CHART_TYPE_TO_LABEL[ChartType.ALL_REASONS]}
                  </MenuItem>
                  <MenuItem value={ChartType.VOLUNTARY_INVOLUNTARY}>
                    {CHART_TYPE_TO_LABEL[ChartType.VOLUNTARY_INVOLUNTARY]}
                  </MenuItem>
                </TextDropdown>
              }
              onSeeMore={() => setShowModalChart(true)}
              snapId="reasons-for-leaving-chart"
              screenshotStrategy="svg"
            />
            <div
              className={cn({
                [classes.allReasonsContainer]: chartType === ChartType.ALL_REASONS,
                [classes.voluntaryInvoluntaryContainer]:
                  chartType === ChartType.VOLUNTARY_INVOLUNTARY,
              })}
            >
              <div id="reasons-for-leaving-pie-chart">
                <ReasonsForLeavingPieChart
                  reasonsForLeaving={reasonsForLeaving}
                  chartType={chartType}
                  groups={insightsMultiselectGroupCounts}
                />
              </div>
              {chartType === ChartType.ALL_REASONS ? (
                <AllReasonsLegend reasonsForLeaving={reasonsForLeaving} />
              ) : (
                <VoluntaryInvoluntaryInfo
                  groups={insightsMultiselectGroupCounts}
                  reasonsForLeaving={reasonsForLeaving}
                />
              )}
            </div>
            {chartType === ChartType.VOLUNTARY_INVOLUNTARY && <VoluntaryInvoluntaryLegend />}
            <ReasonsForLeavingHighlights locationGroupCounts={insightsVoluntaryDischargeCountsBy} />
            {showModalChart && (
              <KeyDemographicsModalChart
                survey={survey}
                onClose={() => setShowModalChart(false)}
                filters={filters}
                dtCode={DataTypeCode.LEFT_REASON}
                isMultiselectCode
                benchmark={benchmark}
                dropdownTexts={['by participants who selected:', 'as their reason for leaving']}
              />
            )}
          </div>
        )
      }}
    </ResponseHandler>
  )
}

export default ReasonsForLeavingCard
